export enum TargetType {
  UNDEFINED = 'undefined',
  DRONE = 'drone',
  HELICOPTER = 'helicopter',
  AIRPLANE = 'airplane',
  CRUISE_MISSILE = 'cruise_missile',
  GUIDED_BOMB = ' guided_bomb',
  LOITERING_MUNITION = 'loitering_munition',
  TRANSPORT_AIRCRAFT = 'transport_aircraft',
  UAV_IMPACT = 'uav_impact',
  UAV_TACTICAL = 'uav_tactical',
}

export enum TargetsAsyncActions {
  ADD_TARGET = 'addTarget',
}

export interface TrackData {
  d: number;
  a: number;
  h: number;
}

export interface TargetData {
  rcs: number;
  radSpeed: number;
  altitude: number;
  altitude_sl: number;
  altitude_gl: number;
  range: number;
  dist_horizon: number;
  az: number;
  el: number;
  lat: number;
  lon: number;
  object_x?: number;
  object_y?: number;
  id: number;
  track?: TrackData[];
  speed?: number;
  type?: TargetType;
  point_id?: string;
  scan_id?: string;
}

export interface TargetsData {
  targets: TargetData[];
  is_show_all_info: boolean;
  selectedObjects: (number | string)[];
}

export interface TargetsSliceState {
  data: TargetsData;
}
