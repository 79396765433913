import { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Radio } from 'react-loader-spinner';
import { RadarStatuses } from '../types';
import translate from '../../../translations/translate';
import { StatusProps } from './radar-status.styled';
import palette from '../../../app/theme/palette';

export default function RadarStatusDetails({
  is_connected,
  status,
}: StatusProps) {
  const [loading, setLoading] = useState(true);
  const disabled = !is_connected || status === RadarStatuses.DISCONNECTED;

  const serverTextStatus = is_connected ? 'info.enabled' : 'info.disabled';
  const radarTextStatus = disabled ? 'info.disabled' : 'info.enabled';

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, [setLoading]);

  return (
    <Box minHeight={80} position="relative">
      {loading ? (
        <Radio
          colors={[
            palette.primary.light,
            palette.primary.main,
            palette.primary.dark,
          ]}
          height="80"
          visible
          width="80"
          wrapperStyle={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : (
        <Stack gap={1}>
          <Typography
            color="primary.main"
            fontWeight="bold"
            mb={1}
            variant="body1"
          >
            {translate('info.result')}:
          </Typography>
          <Stack alignItems="center" direction="row" gap={0.5}>
            <Typography color="primary.contrastText" variant="body1">
              {translate('info.server')}:
            </Typography>
            <Typography
              color={is_connected ? 'primary.light' : 'warning.main'}
              fontWeight="bold"
              variant="body1"
            >
              {translate(serverTextStatus)}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row" gap={1}>
            <Typography color="primary.contrastText" variant="body1">
              {translate('info.radar')}:
            </Typography>
            <Typography
              color={disabled ? 'warning.main' : 'primary.light'}
              fontWeight="bold"
              variant="body1"
            >
              {translate(radarTextStatus)}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  );
}
