import { SvgIcon, SvgIconProps } from '@mui/material';

export default function AirplaneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="currentColor"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
          M 63.36 0.00
          L 64.00 0.00
          L 64.00 0.73
          C 63.28 2.35 63.19 4.58 62.02 5.90
          Q 61.57 6.41 61.51 5.73
          Q 61.26 2.78 58.32 2.46
          Q 57.68 2.39 58.10 1.90
          Q 58.45 1.49 58.90 1.36
          Q 61.13 0.71 63.36 0.00
          Z"
        />
        <ellipse
          cx="0.00"
          cy="0.00"
          rx="9.48"
          ry="3.25"
          transform="translate(52.39,11.59) rotate(-45.0)"
        />
        <path
          d="
          M 3.75 10.96
          L 30.23 18.06
          A 0.31 0.31 0.0 0 1 30.38 18.57
          L 13.88 36.88
          A 0.31 0.31 0.0 0 1 13.38 36.83
          L 0.16 14.67
          A 0.31 0.31 0.0 0 1 0.21 14.29
          L 3.45 11.04
          A 0.31 0.31 0.0 0 1 3.75 10.96
          Z"
        />
        <path
          d="
          M 32.93 22.69
          Q 32.93 23.38 33.36 23.62
          Q 34.11 24.05 34.75 23.47
          L 42.77 16.22
          Q 43.35 15.70 43.31 16.47
          Q 43.08 20.95 47.62 20.67
          Q 48.29 20.63 47.84 21.13
          L 40.40 29.34
          Q 39.87 29.92 40.28 30.59
          Q 40.53 31.00 41.11 31.04
          A 1.12 1.12 0.0 0 0 42.00 30.68
          L 48.18 23.91
          Q 49.13 22.87 49.02 24.27
          L 48.87 26.22
          Q 48.75 27.75 47.62 28.78
          Q 35.04 40.29 25.11 48.83
          Q 22.22 51.31 17.70 51.21
          Q 17.00 51.19 17.25 50.53
          C 18.39 47.43 16.34 46.20 13.60 46.72
          A 0.92 0.92 0.0 0 1 12.51 45.73
          Q 12.87 41.95 15.23 39.10
          Q 25.09 27.22 35.47 16.17
          A 3.48 3.47 20.4 0 1 37.89 15.07
          L 39.74 15.00
          Q 41.02 14.95 40.07 15.81
          L 33.20 22.06
          A 0.82 0.81 68.7 0 0 32.93 22.69
          Z
          M 22.6253 37.3505
          A 1.02 1.02 0.0 0 0 22.5021 35.9133
          L 22.4562 35.8747
          A 1.02 1.02 0.0 0 0 21.0190 35.9979
          L 14.4747 43.7695
          A 1.02 1.02 0.0 0 0 14.5979 45.2067
          L 14.6438 45.2453
          A 1.02 1.02 0.0 0 0 16.0810 45.1221
          L 22.6253 37.3505
          Z
          M 28.6377 42.0372
          A 1.02 1.02 0.0 0 0 27.2007 41.9115
          L 19.4023 48.4551
          A 1.02 1.02 0.0 0 0 19.2766 49.8921
          L 19.3023 49.9228
          A 1.02 1.02 0.0 0 0 20.7393 50.0485
          L 28.5377 43.5049
          A 1.02 1.02 0.0 0 0 28.6634 42.0679
          L 28.6377 42.0372
          Z"
        />
        <path
          d="
  M 49.34 64.00
  Q 49.37 64.00 49.40 64.00
  L 27.63 50.94
  A 0.73 0.73 0.0 0 1 27.51 49.77
  L 45.46 33.59
  A 0.28 0.27 -28.2 0 1 45.91 33.72
  L 52.93 59.84
  Q 53.06 60.34 52.69 60.71
  L 49.34 64.00
  Z"
        />
        <path
          d="
  M 5.33 47.55
  L 2.77 42.09
  Q 2.56 41.64 2.91 41.29
  L 4.77 39.47
  Q 5.20 39.04 5.80 39.15
  L 11.27 40.14
  Q 11.87 40.25 11.65 40.82
  Q 10.43 43.99 10.70 47.09
  A 1.29 1.28 1.1 0 1 9.26 48.47
  L 6.06 48.08
  Q 5.54 48.02 5.33 47.55
  Z"
        />
        <path
          d="
  M 21.77 61.17
  L 16.47 58.66
  Q 15.95 58.41 15.89 57.84
  L 15.53 54.73
  A 1.33 1.33 0.0 0 1 16.98 53.26
  Q 20.06 53.56 23.13 52.36
  Q 23.76 52.12 23.87 52.78
  L 24.84 58.20
  A 1.28 1.26 63.0 0 1 24.49 59.31
  L 22.79 61.00
  Q 22.34 61.44 21.77 61.17
  Z"
        />
      </svg>
    </SvgIcon>
  );
}
