import { IconButtonProps, IconButton } from '@mui/material';
import { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';

export interface SettingsIconButtonProps extends IconButtonProps {
  enterDelay?: number;
  leaveDelay?: number;
  tooltipTitle: JSX.Element | string;
  children: ReactElement;
}
export default function SettingsIconButton({
  children,
  enterDelay = 0,
  leaveDelay = 0,
  tooltipTitle,
  ...otherProps
}: SettingsIconButtonProps) {
  return (
    <Tooltip
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      title={tooltipTitle}
    >
      <span>
        <IconButton {...otherProps}>{children}</IconButton>
      </span>
    </Tooltip>
  );
}
