import { styled } from '@mui/material/styles';

export const SettingsRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  width: 'inherit',
  height: 'inherit',
  flexDirection: 'column',
  padding: '23px 33px',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

export const SettingsTitle = styled('h2')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '21px',
  color: theme.palette.primary.light,
  margin: '0 0 25px',
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    lineHeight: '18px',
    margin: '0 0 12px',
  },
}));

export const SettingsForm = styled('form')(({ theme }) => ({
  width: 'inherit',
  height: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('xl')]: {},
}));

export const SettingsRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',

  gap: 24,
  '& + &': {
    marginTop: 22,
  },
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    gap: 15,
    '& + &': {
      marginTop: 12,
    },
  },
}));

export const SettingsInputWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 230,
  [theme.breakpoints.down('xl')]: {
    width: 200,
  },
}));

export const SettingsInputGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 11,
  [theme.breakpoints.down('xl')]: {},
}));

export const SettingsFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 'auto',
  [theme.breakpoints.down('xl')]: {
    marginTop: 'auto',
  },
}));
