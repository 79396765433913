/**
 * Filters out fields with NaN (Not a Number) or null values from an object.
 *
 * @param obj - The input object from which NaN and null fields will be excluded.
 * @returns The input object with NaN and null fields excluded.
 *
 * @example
 * const obj = {
 *   name: 'John',
 *   age: 25,
 *   height: NaN,
 *   weight: null,
 * };
 *
 * const filteredObj = excludeNaNFields(obj);
 * console.log(filteredObj);
 * // Output: { name: 'John', age: 25 }
 */
export default function excludeNaNFields(obj: Record<string, unknown>) {
  const filteredObj = Object.entries(obj).filter(
    ([, value]) => !Number.isNaN(value) && value !== null,
  );

  return Object.fromEntries(filteredObj);
}
