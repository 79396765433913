import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import { MotionProps, useDragControls } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import {
  DraggableContainer,
  DraggableBar,
  DraggableWrapperStyled,
} from './draggable-wrapper.styled';
import { ReactFCProps } from '../../../app/common-types';

export interface TargetsTable extends ReactFCProps {
  isFullWidth: boolean;
  isOpen: boolean;
  onClose: () => void;
}
const START_Y_POSITION = 48;

function getAnimationParams(
  is_full_width: boolean,
  isOpen: boolean,
  isDownMd: boolean,
): MotionProps {
  return {
    variants: {
      visible: {
        y: isDownMd ? 15 : START_Y_POSITION,
        x: 0,
        scale: 1,
        resize: 'none',
        position: 'static',
      },
      hide: {
        scale: 0,
        position: 'absolute',
        resize: 'none',
      },
      draggable: {
        scale: 1,
        position: 'absolute',
        resize: 'both',
      },
    },
    // eslint-disable-next-line
    animate: isOpen ? 'draggable' : is_full_width ? 'hide' : 'visible',
    // eslint-disable-next-line
    initial: isOpen ? 'draggable' : is_full_width ? 'hide' : 'visible',
    transition: {
      type: 'linear',
    },
  };
}

function DraggableWrapper({
  onClose,
  isOpen,
  isFullWidth,
  children,
}: TargetsTable) {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const paperRef = useRef<HTMLDivElement | null>(null);
  const [animationParams, setAnimationParams] = useState<MotionProps>(
    getAnimationParams(isFullWidth, isOpen, isDownMd),
  );

  const dragControls = useDragControls();

  const startDrag = useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent) => {
      dragControls.start(event);
    },
    [dragControls],
  );

  useEffect(() => {
    setAnimationParams(getAnimationParams(isFullWidth, isOpen, isDownMd));
  }, [isOpen, isFullWidth, isDownMd]);

  useEffect(() => {
    if (paperRef.current && isFullWidth) {
      paperRef.current.style.width = '400px';
      paperRef.current.style.height = '400px';
    }
  }, [isFullWidth, isDownMd]);

  useEffect(() => {
    if (paperRef.current && !isOpen && !isFullWidth) {
      paperRef.current.style.width = isDownMd ? '100%' : 'calc(47% - 15px)';
      paperRef.current.style.height = isDownMd
        ? '400px'
        : `calc(100% - ${START_Y_POSITION}px)`;
    }
  }, [isOpen, isFullWidth, isDownMd]);

  return (
    <DraggableWrapperStyled
      ref={paperRef}
      drag={isOpen}
      dragControls={dragControls}
      dragListener={false}
      dragMomentum={false}
      whileDrag={{ scale: 1.01 }}
      {...animationParams}
    >
      {isOpen && (
        <DraggableBar onTapStart={(ev) => startDrag(ev)}>
          <IconButton color="error" onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </DraggableBar>
      )}

      <DraggableContainer isDraggable={isOpen}>{children}</DraggableContainer>
    </DraggableWrapperStyled>
  );
}

export default memo(DraggableWrapper);
