import Button from '@mui/material/Button';
import { isNil } from 'lodash';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { InputField } from '../../../app/components';
import { Label } from '../../../app/components/input-field.styled';
import translate from '../../../translations/translate';
import {
  RadarDataField,
  RadarStatuses,
  ScanRange,
  SettingsRadarProps,
  VisualizationTypes,
  selectRadarSettingsData,
} from '../../radar';

import {
  SettingsForm,
  SettingsRoot,
  SettingsRow,
  SettingsTitle,
  SettingsFooter,
  SettingsInputWrapper,
  SettingsInputGroup,
} from './settings-tab-content.styled';
import { SocketData, SocketDataFields } from '../../socket/types';
import { useAppSelector } from '../../../app/store/store';
import { FormatTypes } from '../../../app/components/input-field';
import { SelectArrowIcon } from '../../../app/icons';
import { selectVirazhData } from '../../virazh';
import { selectWebSocketConnected } from '../../socket/selectors';

export interface SettingsRadarModalProps {
  onClose: () => void;
  control: Control<SettingsRadarProps & SocketData, undefined>;
  register: UseFormRegister<SettingsRadarProps & SocketData>;
  handleSubmit: UseFormHandleSubmit<SettingsRadarProps & SocketData>;
  onSubmit: (data: SettingsRadarProps & SocketData) => void;
  errors: FieldErrors<SettingsRadarProps & SocketData>;
}

export default function SettingsRadar({
  register,
  errors,
  control,
  handleSubmit,
  onSubmit,
  onClose,
}: SettingsRadarModalProps) {
  const intl = useIntl();
  const {
    scan_range,
    status,
    azimuth_scan_max,
    azimuth_scan_min,
    elevation_scan_max,
    elevation_scan_min,
    scan_step,
  } = useAppSelector(selectRadarSettingsData);
  const isConnected = useAppSelector(selectWebSocketConnected);
  const { status: virazhStatus } = useAppSelector(selectVirazhData);
  const disabled = status === RadarStatuses.DISCONNECTED || !isConnected;

  function generateArray(minVal: number, maxVal: number, step: number) {
    if (isNil(maxVal) || isNil(minVal) || isNil(step)) {
      return [];
    }

    const result = [];
    let currentVal = minVal;
    while (currentVal <= maxVal) {
      result.push(currentVal);
      currentVal += step;
    }
    return result;
  }

  const getErrorProps = <
    T extends keyof FieldErrors<SettingsRadarProps & SocketData>,
  >(
    fieldName: T,
  ) => ({
    error: !!errors[fieldName],
    errorText:
      errors[fieldName]?.message &&
      intl.formatMessage({
        id: errors[fieldName]?.message,
      }),
  });

  const {
    elevation_scan_begin,
    elevation_scan_end,
    azimuth_scan_begin,
    azimuth_scan_end,
    visualization_type,
  } = useWatch({
    control,
  });

  return (
    <SettingsRoot>
      <SettingsForm onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <SettingsTitle>
            {translate('settings.radar.title.server_settings')}
          </SettingsTitle>
          <SettingsRow>
            <SettingsInputWrapper>
              <InputField
                label={intl.formatMessage({
                  id: 'settings.radar.label.host',
                })}
                placeholder="123.123.244.22"
                {...register(SocketDataFields.HOST)}
                {...getErrorProps(SocketDataFields.HOST)}
              />
            </SettingsInputWrapper>
            <SettingsInputWrapper>
              <InputField
                formatType={FormatTypes.NUMBER}
                label={intl.formatMessage({
                  id: 'settings.radar.label.port',
                })}
                placeholder="80"
                {...register(SocketDataFields.PORT)}
                {...getErrorProps(SocketDataFields.PORT)}
              />
            </SettingsInputWrapper>
          </SettingsRow>
        </Box>

        <Box mt={5}>
          <SettingsTitle>
            {translate('settings.radar.title.radar_settings')}
          </SettingsTitle>
          <SettingsRow>
            <SettingsInputWrapper>
              <InputField
                label={intl.formatMessage({
                  id: 'settings.radar.label.serial_number',
                })}
                placeholder="SN1234SD"
                {...register(SocketDataFields.SERIAL_NUMBER)}
                {...getErrorProps(SocketDataFields.SERIAL_NUMBER)}
              />
            </SettingsInputWrapper>
          </SettingsRow>
          <SettingsRow>
            <SettingsInputWrapper>
              <Label disabled={disabled}>
                {translate('settings.radar.label.azimuth')}
              </Label>
              <SettingsInputGroup>
                <Controller
                  control={control}
                  name={RadarDataField.AZIMUTH_SCAN_BEGIN}
                  render={({ field: { onChange, ...otherField } }) => (
                    <Autocomplete
                      disableClearable
                      disabled={disabled}
                      getOptionDisabled={(option) =>
                        !isNil(azimuth_scan_end) && option >= azimuth_scan_end
                      }
                      getOptionLabel={(option) => String(option)}
                      onChange={(_, data) => {
                        onChange(data);
                      }}
                      options={generateArray(
                        azimuth_scan_min,
                        azimuth_scan_max,
                        scan_step,
                      )}
                      {...otherField}
                      popupIcon={<SelectArrowIcon color="primary" />}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={RadarDataField.AZIMUTH_SCAN_END}
                  render={({ field: { onChange, ...otherField } }) => (
                    <Autocomplete
                      disableClearable
                      disabled={disabled}
                      getOptionDisabled={(option) =>
                        !isNil(azimuth_scan_begin) &&
                        option <= azimuth_scan_begin
                      }
                      getOptionLabel={(option) => String(option)}
                      onChange={(_, data) => {
                        onChange(data);
                      }}
                      options={generateArray(
                        azimuth_scan_min,
                        azimuth_scan_max,
                        scan_step,
                      )}
                      {...otherField}
                      popupIcon={<SelectArrowIcon color="primary" />}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                    />
                  )}
                />
              </SettingsInputGroup>
            </SettingsInputWrapper>
            <SettingsInputWrapper>
              <Label disabled={disabled}>
                {translate('settings.radar.label.angle')}
              </Label>
              <SettingsInputGroup>
                <Controller
                  control={control}
                  name={RadarDataField.ELEVATION_SCAN_BEGIN}
                  render={({ field: { onChange, ...otherField } }) => (
                    <Autocomplete
                      disableClearable
                      disabled={disabled}
                      getOptionDisabled={(option) =>
                        !!elevation_scan_end && option >= elevation_scan_end
                      }
                      getOptionLabel={(option) => String(option)}
                      onChange={(_, data) => {
                        onChange(data);
                      }}
                      options={generateArray(
                        elevation_scan_min,
                        elevation_scan_max,
                        scan_step,
                      )}
                      popupIcon={<SelectArrowIcon color="primary" />}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                      {...otherField}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={RadarDataField.ELEVATION_SCAN_END}
                  render={({ field: { onChange, ...otherField } }) => (
                    <Autocomplete
                      disableClearable
                      disabled={disabled}
                      getOptionDisabled={(option) =>
                        !!elevation_scan_begin && option <= elevation_scan_begin
                      }
                      getOptionLabel={(option) => String(option)}
                      onChange={(_, data) => {
                        onChange(data);
                      }}
                      options={generateArray(
                        elevation_scan_min,
                        elevation_scan_max,
                        scan_step,
                      )}
                      popupIcon={<SelectArrowIcon color="primary" />}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                      {...otherField}
                    />
                  )}
                />
              </SettingsInputGroup>
            </SettingsInputWrapper>
          </SettingsRow>
          <SettingsRow>
            <SettingsInputWrapper>
              <Controller
                control={control}
                name={RadarDataField.SCAN_TYPE}
                render={({ field: { onChange, ...otherField } }) => (
                  <Autocomplete
                    disableClearable
                    disabled={disabled}
                    getOptionLabel={(option) =>
                      intl.formatMessage(
                        {
                          id: option,
                        },
                        { value: option },
                      )
                    }
                    onChange={(_, data) => {
                      onChange(data);
                    }}
                    options={[ScanRange.GRID, ScanRange.INSTRUMENTAL]}
                    popupIcon={<SelectArrowIcon color="primary" />}
                    renderInput={(params) => (
                      <TextField
                        label={intl.formatMessage({
                          id: 'settings.common.scan_range',
                        })}
                        variant="standard"
                        {...params}
                      />
                    )}
                    {...otherField}
                  />
                )}
              />
              <Typography
                color="primary.contrastText"
                fontStyle="italic"
                mt={1}
                variant="subtitle1"
              >
                {scan_range} м
              </Typography>
            </SettingsInputWrapper>
          </SettingsRow>
        </Box>

        <Box mt={5}>
          <SettingsTitle>{translate('settings.common.visual')}</SettingsTitle>
          <SettingsRow>
            <SettingsInputWrapper>
              <Controller
                control={control}
                name={RadarDataField.VISUALIZATION_TYPE}
                render={({ field: { onChange, ...otherField } }) => (
                  <Autocomplete
                    disableClearable
                    disabled={disabled}
                    getOptionLabel={(option) =>
                      intl.formatMessage(
                        {
                          id: option,
                        },
                        { value: option },
                      )
                    }
                    onChange={(_, data) => {
                      onChange(data);
                    }}
                    options={[
                      VisualizationTypes.TRACKS,
                      VisualizationTypes.OBJECTS,
                    ]}
                    popupIcon={<SelectArrowIcon color="primary" />}
                    renderInput={(params) => (
                      <TextField
                        label={intl.formatMessage({
                          id: 'settings.common.visualization_type',
                        })}
                        variant="standard"
                        {...params}
                      />
                    )}
                    {...otherField}
                  />
                )}
              />
            </SettingsInputWrapper>
            {visualization_type === VisualizationTypes.TRACKS && (
              <SettingsInputWrapper>
                <Controller
                  control={control}
                  name={RadarDataField.TRACK_LENGTH}
                  render={({ field: { onChange, ...otherField } }) => (
                    <Autocomplete
                      disableClearable
                      disabled={disabled}
                      getOptionLabel={(option) =>
                        intl.formatMessage(
                          {
                            id: 'settings.radar.minute',
                          },
                          { value: option },
                        )
                      }
                      onChange={(_, data) => {
                        onChange(data);
                      }}
                      options={[3, 5, 7, 10]}
                      popupIcon={<SelectArrowIcon color="primary" />}
                      renderInput={(params) => (
                        <TextField
                          label={intl.formatMessage({
                            id: 'settings.common.track_length',
                          })}
                          variant="standard"
                          {...params}
                        />
                      )}
                      {...otherField}
                    />
                  )}
                />
              </SettingsInputWrapper>
            )}
          </SettingsRow>
          <SettingsRow>
            {virazhStatus &&
              visualization_type === VisualizationTypes.OBJECTS && (
                <Typography
                  color="primary.dark"
                  fontStyle="italic"
                  variant="caption"
                >
                  {translate('settings.radar.error')}
                </Typography>
              )}
          </SettingsRow>
        </Box>

        <SettingsFooter>
          <Button
            color="warning"
            onClick={onClose}
            sx={{ marginRight: '10px' }}
            variant="outlined"
          >
            {translate('button.cancel')}
          </Button>
          <Button color="primary" type="submit" variant="contained">
            {translate('button.save')}
          </Button>
        </SettingsFooter>
      </SettingsForm>
    </SettingsRoot>
  );
}
