import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { RadarSliceState, VirazhDataField, VirazhStatus } from './types';

const initialState: RadarSliceState = {
  data: {
    [VirazhDataField.STATUS]: VirazhStatus.OFF,
    [VirazhDataField.HOST]: '',
    [VirazhDataField.PORT]: '',
    [VirazhDataField.SIC]: 0,
  },
};

const virazhSlice = createSlice({
  name: Features.virazh,
  initialState,
  reducers: {
    setVirazhData(state, data) {
      state.data = { ...state.data, ...data.payload };
    },
  },
});

export const { setVirazhData } = virazhSlice.actions;

export default virazhSlice.reducer;
