/* eslint-disable max-len */
import UbuntuLight from '../../assets/fonts/Ubuntu-Light.woff';
import Ubuntu from '../../assets/fonts/Ubuntu-Regular.woff';
import UbuntuMedium from '../../assets/fonts/Ubuntu-Medium.woff';
import UbuntuBold from '../../assets/fonts/Ubuntu-Bold.ttf';

const typography = {
  MuiCssBaseline: {
    styleOverrides: `
        @font-face {
          font-family: 'Ubuntu';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Ubuntu'), local('Ubuntu-Light'), url(${UbuntuLight}) format('woff');
        }
  			@font-face {
  			  font-family: 'Ubuntu';
  			  font-style: normal;
  			  font-display: swap;
  			  font-weight: 400;
  			  src: local('Ubuntu'), local('Ubuntu-Regular'), url(${Ubuntu}) format('woff');
  			}
  			@font-face {
  			  font-family: 'Ubuntu';
  			  font-style: normal;
  			  font-display: swap;
  			  font-weight: 500;
  			  src: local('Ubuntu'), local('Ubuntu-Medium'), url(${UbuntuMedium}) format('woff');
  			}
  			@font-face {
  				font-family: 'Ubuntu';
  			  font-style: normal;
  			  font-display: swap;
  			  font-weight: 700;
  			  src: local('Ubuntu'), local('Ubuntu-Bold'), url(${UbuntuBold}) format('woff');
  			}
  		`,
  },
};

export default typography;
