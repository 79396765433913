import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import AlbumRoundedIcon from '@mui/icons-material/AlbumRounded';
import { memo, useCallback } from 'react';
import RecordIcon from '../icons/record-icon';
import { useAppSelector } from '../store';
import {
  selectSerialNumber,
  selectWebSocket,
  selectWebSocketConnected,
} from '../../features/socket';
import { selectRecordData } from '../../features/radar';

function Record() {
  const { palette } = useTheme();
  const serial_number = useAppSelector(selectSerialNumber);
  const socket = useAppSelector(selectWebSocket);
  const isConnected = useAppSelector(selectWebSocketConnected);
  const { status, file } = useAppSelector(selectRecordData);

  const handleToggleRecord = useCallback(async () => {
    if (socket) {
      socket.send(
        JSON.stringify({
          serial_number,
          module: 'logging',
          method: status ? 'stop' : 'start',
        }),
      );
    }
  }, [socket, status, serial_number]);

  return (
    <Box
      sx={{
        padding: '10px',
        background: palette.secondary.main,
        borderRadius: '12px 12px 12px 0',
      }}
    >
      <Tooltip title={file}>
        <IconButton
          color={status ? 'warning' : 'success'}
          disabled={!isConnected}
          onClick={handleToggleRecord}
          size="small"
        >
          {status ? <RecordIcon /> : <AlbumRoundedIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default memo(Record);
