import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CruiseMissileIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="currentColor"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
  M 47.91 10.76
  L 56.89 6.72
  A 0.30 0.30 0.0 0 1 57.29 7.12
  L 53.32 16.13
  A 0.30 0.30 0.0 0 1 52.83 16.22
  L 47.82 11.24
  A 0.30 0.30 0.0 0 1 47.91 10.76
  Z"
        />
        <path
          d="
  M 28.76 31.26
  Q 28.85 30.76 28.66 30.27
  Q 28.47 29.76 28.86 29.38
  L 45.75 12.55
  Q 46.10 12.20 46.45 12.55
  L 51.55 17.67
  A 0.44 0.43 45.5 0 1 51.55 18.28
  L 34.65 35.17
  Q 34.26 35.55 33.77 35.35
  Q 33.44 35.22 33.08 35.22
  Q 32.52 35.22 32.13 35.62
  L 17.36 50.40
  Q 17.19 50.57 17.03 50.41
  L 13.66 47.08
  A 0.30 0.29 -45.7 0 1 13.66 46.67
  L 28.43 31.90
  A 1.19 1.15 -18.6 0 0 28.76 31.26
  Z"
        />
        <path
          d="
  M 25.65 31.97
  L 11.84 45.79
  A 0.49 0.49 0.0 0 1 11.14 45.79
  L 9.48 44.11
  A 0.49 0.49 0.0 0 1 9.48 43.42
  L 21.20 31.71
  A 0.49 0.49 0.0 0 1 21.49 31.57
  L 25.25 31.13
  A 0.49 0.49 0.0 0 1 25.65 31.97
  Z"
        />
        <path
          d="
  M 32.32 42.82
  L 20.60 54.54
  A 0.51 0.51 0.0 0 1 19.87 54.53
  L 18.25 52.87
  A 0.51 0.51 0.0 0 1 18.26 52.15
  L 32.01 38.40
  A 0.51 0.51 0.0 0 1 32.88 38.81
  L 32.47 42.51
  A 0.51 0.51 0.0 0 1 32.32 42.82
  Z"
        />
        <path
          d="
  M 7.02 40.88
  Q 7.74 40.61 8.78 40.69
  Q 9.46 40.74 9.03 41.27
  Q 8.67 41.70 8.23 42.05
  Q 7.77 42.42 7.28 42.11
  Q 7.01 41.94 6.81 41.67
  Q 6.37 41.13 7.02 40.88
  Z"
        />
        <path
          d="
  M 8.60 46.34
  Q 9.05 46.09 8.90 46.58
  Q 8.68 47.27 7.97 47.57
  Q 7.41 47.80 7.69 47.27
  Q 8.00 46.66 8.60 46.34
  Z"
        />
        <ellipse
          cx="0.00"
          cy="0.00"
          rx="1.35"
          ry="0.34"
          transform="translate(13.02,51.02) rotate(-44.4)"
        />
        <path
          d="
  M 22.66 55.08
  Q 23.30 54.63 23.28 55.41
  L 23.24 56.68
  Q 23.20 57.94 22.28 57.09
  Q 21.22 56.11 22.66 55.08
  Z"
        />
        <path
          d="
  M 16.51 56.15
  Q 16.67 55.42 17.28 55.04
  A 0.31 0.31 0.0 0 1 17.70 55.48
  Q 17.32 56.04 16.77 56.35
  Q 16.42 56.54 16.51 56.15
  Z"
        />
      </svg>
    </SvgIcon>
  );
}
