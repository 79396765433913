import { SvgIcon, SvgIconProps } from '@mui/material';

export default function TransportAircraftIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="currentColor"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
  M 56.20 0.00
  L 58.96 0.00
  Q 62.88 1.20 64.00 5.15
  L 64.00 7.83
  Q 57.30 15.84 48.80 23.34
  Q 48.40 23.69 48.57 24.19
  L 58.82 55.24
  Q 58.99 55.76 58.61 56.14
  L 52.61 62.15
  A 0.31 0.31 0.0 0 1 52.13 62.09
  L 36.03 35.96
  A 0.20 0.20 0.0 0 0 35.72 35.92
  L 22.16 48.93
  Q 21.80 49.27 21.88 49.77
  L 23.19 57.74
  Q 23.22 57.92 23.09 58.05
  L 17.35 63.77
  Q 17.18 63.94 17.10 63.71
  L 12.93 51.75
  Q 12.75 51.25 12.25 51.07
  L 0.73 47.01
  Q 0.09 46.79 0.57 46.30
  L 5.76 41.12
  Q 6.11 40.76 6.60 40.84
  L 14.23 42.08
  Q 14.78 42.17 15.16 41.77
  L 27.90 28.49
  Q 28.28 28.09 27.81 27.81
  L 1.92 11.86
  A 0.31 0.31 0.0 0 1 1.86 11.38
  L 7.88 5.38
  Q 8.25 5.01 8.75 5.18
  L 39.78 15.41
  Q 40.26 15.57 40.61 15.19
  Q 48.26 6.69 56.20 0.00
  Z"
        />
      </svg>
    </SvgIcon>
  );
}
