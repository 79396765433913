import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

export const Root = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    marginBottom: 15,
    right: 0,
    position: 'relative',
    width: '100%',
  },
}));

export const RadarControlStart = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'flex-end',
  gap: 15,
}));

export const RadarControlEnd = styled('div')(() => ({
  display: 'flex',
  marginBottom: 'auto',
  gap: 11,
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  padding: 6,
  '& svg': {
    width: 42,
    height: 42,
    [theme.breakpoints.down('xl')]: {
      width: 32,
      height: 32,
    },
  },
}));
