import { alpha, styled } from '@mui/material/styles';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import Cross from '../../assets/images/close-rounded.svg';
import Success from '../../assets/images/checkmark.svg';
import Error from '../../assets/images/error.svg';
import Info from '../../assets/images/info.svg';
import Warning from '../../assets/images/info-warning.svg';
import palette from '../theme/palette';

export const StyledToastContainer = styled(ToastContainer)<ToastContainerProps>(
  () => ({
    '&&&.Toastify__toast-container': {
      width: 'auto',
      padding: 0,
      margin: 0,
      left: 'initial',
      top: 63,
      right: 24,
      '.Toastify__toast': {
        position: 'relative',
        width: 327,
        minHeight: 60,
        padding: '16px 40px 20px 18px',
        borderRadius: 16,
        willChange: 'transform',
        boxShadow: '0px 8px 20px -7px rgba(9, 26, 47, 0.17)',
        marginBottom: 15,
      },
      '.Toastify__toast-body': {
        padding: 0,
        margin: 0,
      },
      '.Toastify__close-button': {
        position: 'absolute',
        top: '50%',
        right: 16,
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
        opacity: 1,
        backgroundImage: `url(${Cross})`,
        backgroundSize: 'contain',
        '& svg': {
          display: 'none',
        },
      },
      '.Toastify__toast-icon': {
        width: 24,
        height: 24,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        '& svg': {
          display: 'none',
        },
      },
      '.Toastify__progress-bar': {
        height: 4,
        borderRadius: '0px 8px 0px 0px',
        background: palette.info.main,
      },
      '.Toastify__toast--default': {
        background: alpha(palette.info.main, 0.5),
        border: `1px solid ${palette.info.main}`,
        '.Toastify__toast-icon': {
          backgroundImage: `url(${Info})`,
        },
      },

      '.Toastify__toast--info': {
        background: alpha(palette.primary.main, 0.5),
        border: `1px solid ${palette.primary.main}`,
        '.Toastify__toast-icon': {
          backgroundImage: `url(${Info})`,
        },
        '.Toastify__progress-bar': {
          backgroundColor: palette.primary.main,
        },
      },
      '.Toastify__toast--success': {
        background: alpha(palette.primary.light, 0.5),
        border: `1px solid ${palette.primary.light}`,
        '.Toastify__toast-icon': {
          backgroundImage: `url(${Success})`,
        },
        '.Toastify__progress-bar': {
          backgroundColor: palette.primary.light,
        },
      },
      '.Toastify__toast--warning': {
        background: alpha(palette.primary.dark, 0.5),
        border: `1px solid ${palette.primary.dark}`,
        '.Toastify__toast-icon': {
          backgroundImage: `url(${Warning})`,
        },
        '.Toastify__progress-bar': {
          backgroundColor: palette.primary.dark,
        },
      },
      '.Toastify__toast--error': {
        background: alpha(palette.warning.main, 0.5),
        border: `1px solid ${palette.warning.main}`,
        '.Toastify__toast-icon': {
          backgroundImage: `url(${Error})`,
        },
        '.Toastify__progress-bar': {
          backgroundColor: palette.warning.main,
        },
      },
    },
  }),
);
