import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import {
  MapFields,
  PositionFields,
  RadarDataField,
  RadarSliceState,
  RadarStatuses,
  ScanRange,
  SystemList,
  VisualizationTypes,
} from './types';

export const initialErrors = {
  [SystemList.GPS]: true,
  [SystemList.GYROSCOPE]: true,
  [SystemList.HORIZONTIZE]: true,
  [SystemList.NETWORK]: true,
  [SystemList.POWER]: true,
  [SystemList.RADAR_STATUS]: true,
  [SystemList.TEMPERATURE]: true,
};

const initialState: RadarSliceState = {
  data: {
    settings: {
      [RadarDataField.ALTITUDE]: 0,
      [RadarDataField.AZIMUTH_SCAN_BEGIN]: -45,
      [RadarDataField.AZIMUTH_SCAN_END]: 45,
      [RadarDataField.AZIMUTH_SCAN_MAX]: 45,
      [RadarDataField.AZIMUTH_SCAN_MIN]: -45,
      [RadarDataField.BLIND_AREA]: 0,
      [RadarDataField.DIRECTION]: 0,
      [RadarDataField.ELEVATION_SCAN_BEGIN]: -45,
      [RadarDataField.ELEVATION_SCAN_END]: 45,
      [RadarDataField.ELEVATION_SCAN_MAX]: 45,
      [RadarDataField.ELEVATION_SCAN_MIN]: -45,
      [RadarDataField.ELEVATION]: 0,
      [RadarDataField.GPS_AUTO_UPDATE]: false,
      [RadarDataField.GPS_ERROR]: false,
      [RadarDataField.GRID_ANGLE]: 0,
      [RadarDataField.GRID_RANGE]: 40000,
      [RadarDataField.INSTRUMENTAL_RANGE]: 40000,
      [RadarDataField.LATITUDE]: 48.442232,
      [RadarDataField.LONGITUDE]: 35.067032,
      [RadarDataField.MAP]: MapFields.ONLINE,
      [RadarDataField.POSITION]: PositionFields.MANUAL,
      [RadarDataField.SCAN_RANGE]: 40000,
      [RadarDataField.SCAN_STEP]: 15,
      [RadarDataField.SCAN_TYPE]: ScanRange.INSTRUMENTAL,
      [RadarDataField.STATUS]: RadarStatuses.OFF,
      [RadarDataField.TRACK_LENGTH]: 3,
      [RadarDataField.VISUALIZATION_TYPE]: VisualizationTypes.OBJECTS,
    },
    errors: initialErrors,
    record: {
      status: false,
      file: '',
    },
  },
};

const radarSlice = createSlice({
  name: Features.radar,
  initialState,
  reducers: {
    setSettingsData(state, data) {
      state.data.settings = { ...state.data.settings, ...data.payload };
    },
    setRadarStatus(state, data) {
      state.data.settings[RadarDataField.STATUS] = data.payload;
    },
    setSystemStatus(state, data) {
      state.data.errors = {
        ...state.data.errors,
        ...data.payload,
      };
    },

    setPosition(state, data) {
      state.data.settings.position = data.payload;
    },

    setRecord(state, data) {
      state.data.record = data.payload;
    },
  },
});

export const {
  setRadarStatus,
  setSettingsData,
  setSystemStatus,
  setPosition,
  setRecord,
} = radarSlice.actions;

export default radarSlice.reducer;
