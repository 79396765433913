import { memo } from 'react';
import RadarIcon from '@mui/icons-material/Radar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import { Stack, Typography } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useIntl } from 'react-intl';
import { RadarIconWrapper } from './radar-info.styled';
import RadarSettingsModal from './radar-settings-modal';
import translate from '../../../translations/translate';
import { RadarStatuses } from '../types';
import { theme } from '../../../app';
import SettingsIconButton from '../../settings/components/settings-icon-button';
import { useAppSelector } from '../../../app/store/store';
import { selectRadarSettingsData } from '../selectors';
import { selectWebSocketConnected } from '../../socket';

function RadarInfo() {
  const { showModal } = useModal();
  const radarSettingsData = useAppSelector(selectRadarSettingsData);
  const connected = useAppSelector(selectWebSocketConnected);
  const {
    gps_error,
    status,
    grid_angle,
    grid_range,
    altitude,
    direction,
    latitude,
    longitude,
    elevation,
  } = radarSettingsData;
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const intl = useIntl();

  const handleClick = () => {
    showModal(RadarSettingsModal, radarSettingsData, {
      destroyOnClose: true,
    });
  };

  return (
    <Stack gap={0.5}>
      {isUpSm && (
        <RadarIconWrapper>
          <SettingsIconButton
            color={gps_error ? 'error' : 'primary'}
            disabled={!connected || status === RadarStatuses.DISCONNECTED}
            onClick={handleClick}
            tooltipTitle={intl.formatMessage({ id: 'info.helper' })}
          >
            <RadarIcon />
          </SettingsIconButton>
        </RadarIconWrapper>
      )}

      {isUpSm && (
        <>
          <Stack alignItems="flex-end" gap={0.5}>
            <Typography color="primary.contrastText" variant="subtitle2">
              {translate('info.angle')}
            </Typography>
            <Typography color="primary.contrastText" variant="caption">
              {grid_angle}°
            </Typography>
          </Stack>
          <Stack alignItems="flex-end" gap={0.5}>
            <Typography color="primary.contrastText" variant="subtitle2">
              {translate('info.radius')}
            </Typography>
            <Typography color="primary.contrastText" variant="caption">
              {grid_range / 1000} км
            </Typography>
          </Stack>
          <Divider />
        </>
      )}

      <Stack alignItems="flex-end" gap={0.5}>
        <Typography color="primary.contrastText" variant="subtitle2">
          {translate('info.height')}
        </Typography>
        <Typography color="primary.contrastText" variant="caption">
          {altitude} м
        </Typography>
      </Stack>
      <Stack alignItems="flex-end" gap={0.5}>
        <Typography color="primary.contrastText" variant="subtitle2">
          {translate('info.elevation')}
        </Typography>
        <Typography color="primary.contrastText" variant="caption">
          {elevation}°
        </Typography>
      </Stack>
      <Stack alignItems="flex-end" gap={0.5}>
        <Typography color="primary.contrastText" variant="subtitle2">
          {translate('info.azimuth')}
        </Typography>
        <Typography color="primary.contrastText" variant="caption">
          {direction}°
        </Typography>
      </Stack>
      <Stack alignItems="flex-end" gap={0.5}>
        <Typography color="primary.contrastText" variant="subtitle2">
          {translate('info.coords')}
        </Typography>
        <Typography
          color="primary.contrastText"
          variant="caption"
        >{`${latitude}, ${longitude}`}</Typography>
      </Stack>
    </Stack>
  );
}

export default memo(RadarInfo);
