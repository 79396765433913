import { Component, ErrorInfo } from 'react';
import logger from '../services/logger-service';

interface Props {
  children: JSX.Element;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // log to greylog
    if (process.env.NODE_ENV?.trim() === 'development') {
      logger.error('Uncaught error log: ', error, info);
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return null;
    }

    return children;
  }
}

export default ErrorBoundary;
