import _ from 'lodash';
import { useTheme } from '@mui/material';
import { TargetData } from '../../targets';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { getObjectCoordinateOnRadar } from '../functions/get-target-coords';
import { Coords, RadarSettingsData } from '../types';
import { getColorByTargetElevation } from '../functions';

const { radarCenter } = RADAR_IMAGE_PROPERTIES;

export interface RadarTargetTailProps {
  targetCoords: Coords;
  pxInMeter: number;
  maxDisplayRadius: number;
  targetData: TargetData;
  radarSettings: RadarSettingsData;
  fullWidthRadar: boolean;
}

function RadarTargetTail({
  targetCoords,
  pxInMeter,
  maxDisplayRadius,
  targetData,
  radarSettings,
  fullWidthRadar,
}: RadarTargetTailProps) {
  const { palette } = useTheme();
  const { track, az, dist_horizon } = targetData;
  const { direction, grid_angle } = radarSettings;
  const strokeWidth = 1.5;
  const gradientId = `tail${_.random()}`;

  if (!track) {
    return null;
  }

  const lastValidIndex = _.findLast(track, (data) => {
    const isSameData = data.a === az && data.d === dist_horizon;
    return !isSameData;
  });

  if (!lastValidIndex) {
    return null;
  }

  const cords: string[] = [];

  _.forEachRight(track, (data) => {
    const radius = data.d * pxInMeter;
    if (radius > maxDisplayRadius) {
      return false;
    }

    const angle = fullWidthRadar ? 0 : grid_angle;

    const { x, y } = getObjectCoordinateOnRadar({
      distance: radius,
      angle: direction + data.a - 90 + angle,
      radarCenter,
    });

    cords.push(`${x},${y}`);
    return true;
  });

  if (cords.length === 0) {
    return null;
  }

  const [firstHistoryXCord, firstHistoryYCord] =
    cords[cords.length - 1].split(',');

  return (
    <g>
      <g>
        <polyline
          fill="transparent"
          points={`${cords.join(' ')}`}
          stroke={`url(#${gradientId})`}
          strokeDasharray="6 5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={gradientId}
          x1={firstHistoryXCord}
          x2={targetCoords.x}
          y1={firstHistoryYCord}
          y2={targetCoords.y}
        >
          {track.map((point, index) => (
            <stop
              key={index}
              offset={index / (track.length - 1)}
              stopColor={getColorByTargetElevation(point.h, palette)}
              stopOpacity="1"
            />
          ))}
        </linearGradient>
      </defs>
    </g>
  );
}

export default RadarTargetTail;
