import * as yup from 'yup';
import { RadarDataField } from '../radar/types';
import { SocketDataFields } from '../socket/types';

export const validationRadarSchema = yup.object().shape({
  [SocketDataFields.HOST]: yup.string().required('validate.enter_host'),
  [SocketDataFields.PORT]: yup.string(),
  [RadarDataField.TRACK_LENGTH]: yup
    .string()
    .required('validate.track_length')
    .test('is number', 'validate.enter_number', (value) => {
      if (!Number(value)) {
        return false;
      }
      return true;
    }),
});
