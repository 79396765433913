import _ from 'lodash';
import { TargetData } from '../../targets';
import { getDirectionAngle } from '../functions';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { getObjectCoordinateOnRadar } from '../functions/get-target-coords';
import { Coords, RadarSettingsData } from '../types';

export interface RadarTargetTrack {
  targetCords: Coords;
  color: string;
  pxInMeter: number;
  targetData: TargetData;
  radarSettings: RadarSettingsData;
  fullWidthRadar: boolean;
}

const { radarCenter } = RADAR_IMAGE_PROPERTIES;

export default function RadarTargetTrack({
  targetCords,
  color,
  pxInMeter,
  targetData,
  radarSettings,
  fullWidthRadar,
}: RadarTargetTrack) {
  const { track, dist_horizon, az } = targetData;
  const { direction, grid_angle } = radarSettings;
  const points = [
    `${targetCords.x - 5},${targetCords.y + 5}`,
    `${targetCords.x},${targetCords.y - 5}`,
    `${targetCords.x + 5},${targetCords.y + 5}`,
  ];

  const lastTrack = _.findLast(track, (data) => {
    const isSameData = data.a === az && data.d === dist_horizon;
    return !isSameData;
  });

  if (!lastTrack) {
    return null;
  }

  const lastPointRadius = lastTrack.d * pxInMeter;
  const angle = fullWidthRadar ? 0 : grid_angle;

  const { x: lastPointXCord, y: lastPointYCord } = getObjectCoordinateOnRadar({
    angle: direction + lastTrack.a - 90 + angle,
    distance: lastPointRadius,
    radarCenter,
  });

  const angleDirection = getDirectionAngle(
    {
      ...targetCords,
    },
    {
      x: lastPointXCord,
      y: lastPointYCord,
    },
  );

  return (
    <polygon
      fill="none"
      points={points.join(' ')}
      stroke={color}
      strokeWidth={1}
      style={{
        transform: `rotate(${90 + angleDirection}deg)`,
        transformOrigin: `${targetCords.x}px ${targetCords.y}px`,
      }}
    />
  );
}
