import { ForwardedRef, forwardRef, memo } from 'react';
import { useTheme } from '@mui/material';
import { RadarSVG } from './radar.styled';
import { RadarStatuses } from '../types';
import { useAppSelector } from '../../../app/store/store';
import { selectWebSocketData } from '../../socket/selectors';
import RadarCircles from './radar-circles';
import RadarDegreeAxes from './radar-degree-axes';
import RadarViewRadius from './radar-view-radius';
import RadarCircleStepValue from './radar-circle-step-value';
import RadarCenter from './radar-center';
import RadarXYAxes from './radar-xy-axes';
import RadarDegreeValues from './radar-degree-values';
import RadarDeadZone from './radar-dead-zone';
import RadarTargetList from './radar-target-list';
import { selectCommonData } from '../../common';

export interface RadarProps {
  status: RadarStatuses;
  viewBox: string;
  zoomRatio: number;
  containerSize: number;
}

function Radar(
  { status, viewBox, containerSize, zoomRatio }: RadarProps,
  ref: ForwardedRef<SVGSVGElement | null>,
) {
  const { is_connection } = useAppSelector(selectWebSocketData);
  const { is_full_width } = useAppSelector(selectCommonData);
  const { palette } = useTheme();
  const isVisibleSector = status === RadarStatuses.ON || is_connection;

  return (
    <RadarSVG
      ref={ref}
      fill={palette.primary.main}
      height={is_full_width ? '100%' : containerSize}
      viewBox={viewBox}
      width={is_full_width ? '100%' : containerSize}
    >
      <RadarDeadZone />
      <RadarCircles />
      <RadarDegreeAxes />
      <RadarDegreeValues />
      <RadarCenter />
      <RadarXYAxes />
      <RadarCircleStepValue />
      {isVisibleSector && <RadarViewRadius />}
      <RadarTargetList targetSize={10 / zoomRatio} />
    </RadarSVG>
  );
}

export default memo(forwardRef(Radar));
