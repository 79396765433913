import { useCallback, useEffect, useState } from 'react';
import {
  isCoordinates,
  isCoordinatesSeparatedByCommas,
  replaceCommaWithDot,
} from '..';

export interface UseClipBoard {
  latitude: number;
  longitude: number;
  disabled: boolean;
}

export default function useClipboardCoords({
  latitude,
  longitude,
  disabled,
}: UseClipBoard) {
  const [currentCoords, setCurrentCoords] = useState({
    latitude,
    longitude,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [clipboardCoords, setClipboardCoords] = useState('');

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const checkClipBoard = useCallback(async () => {
    if (disabled) return;
    const value = await navigator.clipboard.readText();
    const isCoords = isCoordinates(value);
    const isCoordsByComa = isCoordinatesSeparatedByCommas(value);

    if (isCoords || isCoordsByComa) {
      const [lat, long] = isCoordsByComa
        ? replaceCommaWithDot(value).split(', ')
        : value.split(', ');
      const trimLat = Number(lat).toFixed(6);
      const trimLong = Number(long).toFixed(6);

      if (
        Number(trimLat) !== currentCoords.latitude ||
        Number(trimLong) !== currentCoords.longitude
      ) {
        setClipboardCoords(`${trimLat}, ${trimLong}`);
        setCurrentCoords({
          latitude: Number(trimLat),
          longitude: Number(trimLong),
        });
        handleOpen();
      }
    }
  }, [currentCoords, handleOpen, disabled]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkClipBoard();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [checkClipBoard]);

  return { checkClipBoard, clipboardCoords, isOpen, handleClose };
}
