import { RootState } from '../../app/store/store';
import { CommonDataField } from './types';

export function selectCommonData(state: RootState) {
  return state.common.data;
}

export function selectLanguage(state: RootState) {
  return state.common.data[CommonDataField.LANGUAGE];
}

export function selectSoundStatus(state: RootState) {
  return state.common.data[CommonDataField.IS_SOUND];
}
