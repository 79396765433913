import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { alpha, styled } from '@mui/material/styles';

export const Root = styled('div')({
  width: '100%',
  position: 'fixed',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.1)',
  bottom: 0,
  left: 0,
  zIndex: 100,
});

export const BottomNavigationActionStyled = styled(BottomNavigationAction)(
  ({ theme }) => ({
    '& .MuiSvgIcon-root': {
      marginBottom: 5,
      fill: theme.palette.primary.main,
    },
    '& .MuiBottomNavigationAction-label': {
      color: theme.palette.primary.contrastText,
    },
    '&:disabled': {
      backgroundColor: theme.palette.secondary.main,

      '& .MuiSvgIcon-root': {
        fill: alpha(theme.palette.primary.contrastText, 0.3),
      },
      '& .MuiBottomNavigationAction-label': {
        color: alpha(theme.palette.primary.contrastText, 0.3),
      },
    },
  }),
);
