import { useTheme } from '@mui/material';
import _ from 'lodash';
import { useSpring, animated, config } from '@react-spring/web';
import { memo } from 'react';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { useAppSelector } from '../../../app/store/store';
import { selectWebSocketConnection } from '../../socket/selectors';
import { selectRadarSettingsData } from '../selectors';
import { getSectorPath } from '..';
import { selectCommonData } from '../../common';

const { radarCenter, radarPadding, radarSize } = RADAR_IMAGE_PROPERTIES;

function RadarViewRadius() {
  const { palette } = useTheme();
  const is_connection = useAppSelector(selectWebSocketConnection);
  const { is_full_width } = useAppSelector(selectCommonData);
  const {
    grid_angle,
    grid_range,
    instrumental_range,
    direction,
    azimuth_scan_end,
    azimuth_scan_begin,
  } = useAppSelector(selectRadarSettingsData);

  const opacityAnimation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      duration: 1500,
    },
  });

  const rotateAnimation = useSpring({
    from: {
      rotate: 0,
    },
    to: {
      rotate: 360,
      transformOrigin: 'center center',
    },
    config: config.molasses,
    delay: is_connection ? 1000 : 0,
    loop: is_connection,
  });

  if (_.isNaN(direction) || _.isNaN(grid_angle)) {
    return null;
  }

  const angle = is_full_width ? 0 : grid_angle;

  const maxRadiusInPx = radarSize - radarPadding * 2;
  const pxInMeter = maxRadiusInPx / grid_range;
  const viewRadius = pxInMeter * instrumental_range;

  const calcMaxVision = viewRadius > maxRadiusInPx ? maxRadiusInPx : viewRadius;

  const begin = direction + (azimuth_scan_begin - 90) + angle;
  const end = direction + (azimuth_scan_end - 90) + angle;

  const x2 =
    radarCenter +
    (calcMaxVision / 2) * Math.cos((Math.PI * (begin + end)) / 360);
  const y2 =
    radarCenter +
    (calcMaxVision / 2) * Math.sin((Math.PI * (begin + end)) / 360);

  return (
    <g>
      <g opacity={0.2}>
        <animated.path
          d={getSectorPath({
            startX: radarCenter,
            startY: radarCenter,
            radius: calcMaxVision / 2,
            begin,
            end,
          })}
          fill="url(#gradient)"
          style={{ ...opacityAnimation, ...rotateAnimation }}
        />
      </g>
      <g opacity={0.7}>
        <animated.line
          stroke={palette.info.main}
          strokeDasharray="5,5"
          strokeWidth={1}
          style={{ ...opacityAnimation, ...rotateAnimation }}
          x1={radarCenter}
          x2={x2}
          y1={radarCenter}
          y2={y2}
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="gradient"
          x1={radarCenter}
          x2={radarCenter}
          y1={0}
          y2={radarCenter}
        >
          <stop stopColor={palette.primary.light} stopOpacity=".3" />
          <stop offset="1" stopColor={palette.primary.light} />
        </linearGradient>
      </defs>
    </g>
  );
}

export default memo(RadarViewRadius);
