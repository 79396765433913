import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
  alpha,
} from '@mui/material';

export interface MuiSwitchProps {
  defaultProps?: ComponentsProps['MuiSwitch'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSwitch'];
  variants?: ComponentsVariants['MuiSwitch'];
}

const MuiSwitch: MuiSwitchProps = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiSwitch-switchBase.Mui-disabled': {
        color: alpha(theme.palette.primary.contrastText, 0.1),
      },
    }),
  },
};

export default MuiSwitch;
