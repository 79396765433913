import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import {
  Control,
  Controller,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { InputField } from '../../../app/components';
import { EnFlagIcon, SelectArrowIcon, UkFlagIcon } from '../../../app/icons';
import translate from '../../../translations/translate';
import {
  SettingsRoot,
  SettingsTitle,
  SettingsForm,
  SettingsRow,
  SettingsFooter,
} from './settings-tab-content.styled';
import { CommonData, CommonDataField, LanguageField } from '../../common';
import { dateFormats, languages, timeFormats } from '../../common/utils';

export interface SettingsCommonModalProps {
  onClose: () => void;
  control: Control<CommonData, undefined>;
  handleSubmit: UseFormHandleSubmit<CommonData>;
  onSubmit: (data: CommonData) => void;
  register: UseFormRegister<CommonData>;
}

export default function SettingsCommon({
  control,
  onClose,
  handleSubmit,
  onSubmit,
  register,
}: SettingsCommonModalProps) {
  const intl = useIntl();

  const getCountryIcon = (lang: LanguageField) => {
    switch (lang) {
      case LanguageField.en: {
        return <EnFlagIcon />;
      }
      case LanguageField.uk: {
        return <UkFlagIcon />;
      }
      default: {
        return <UkFlagIcon />;
      }
    }
  };

  return (
    <SettingsRoot>
      <SettingsForm onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <SettingsTitle>
            {translate('settings.common.localization')}
          </SettingsTitle>
          <SettingsRow>
            <Controller
              control={control}
              name={CommonDataField.LANGUAGE}
              render={({ field: { onChange, ...otherField } }) => (
                <Autocomplete
                  disableClearable
                  getOptionLabel={(option) => option}
                  onChange={(_, data) => {
                    onChange(data);
                  }}
                  options={languages}
                  {...otherField}
                  popupIcon={<SelectArrowIcon color="primary" />}
                  renderInput={(params) => (
                    <TextField
                      label={intl.formatMessage({
                        id: 'settings.common.lang',
                      })}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        value: params.inputProps.value
                          ? intl.formatMessage({
                              id: `locales.${params.inputProps.value}`,
                            })
                          : '',
                      }}
                      // eslint-disable-next-line
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {getCountryIcon(
                              params.inputProps.value as LanguageField,
                            )}
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  )}
                  renderOption={(params, option) => (
                    <li {...params}>
                      <Stack alignItems="center" direction="row" gap={1}>
                        {getCountryIcon(option)}
                        {intl.formatMessage({ id: `locales.${option}` })}
                      </Stack>
                    </li>
                  )}
                />
              )}
            />
          </SettingsRow>
          <SettingsRow>
            <Controller
              control={control}
              name={CommonDataField.FORMAT_DATE}
              render={({ field: { onChange, ...otherField } }) => (
                <Autocomplete
                  disableClearable
                  getOptionLabel={(option) =>
                    dateFormats.find(
                      (dateFormat) => dateFormat.value === option,
                    )?.label || ''
                  }
                  onChange={(_, data) => {
                    onChange(data);
                  }}
                  options={dateFormats.map((dateFormat) => dateFormat.value)}
                  {...otherField}
                  popupIcon={<SelectArrowIcon color="primary" />}
                  renderInput={(params) => (
                    <TextField
                      label={intl.formatMessage({
                        id: 'settings.common.format_date',
                      })}
                      {...params}
                      variant="standard"
                    />
                  )}
                  renderOption={(params, option) => (
                    <li {...params}>
                      {
                        dateFormats.find(
                          (dateFormat) => dateFormat.value === option,
                        )?.label
                      }
                    </li>
                  )}
                />
              )}
            />
            <Controller
              control={control}
              name={CommonDataField.FORMAT_TIME}
              render={({ field: { onChange, ...otherField } }) => (
                <Autocomplete
                  disableClearable
                  getOptionLabel={(option) =>
                    timeFormats.find(
                      (dateFormat) => dateFormat.value === option,
                    )?.label || ''
                  }
                  onChange={(_, data) => {
                    onChange(data);
                  }}
                  options={timeFormats.map((dateFormat) => dateFormat.value)}
                  {...otherField}
                  popupIcon={<SelectArrowIcon color="primary" />}
                  renderInput={(params) => (
                    <TextField
                      label={intl.formatMessage({
                        id: 'settings.common.format_time',
                      })}
                      {...params}
                      variant="standard"
                    />
                  )}
                  renderOption={(params, option) => (
                    <li {...params}>
                      {
                        timeFormats.find(
                          (dateFormat) => dateFormat.value === option,
                        )?.label
                      }
                    </li>
                  )}
                />
              )}
            />
          </SettingsRow>
          <Box mt={5}>
            <SettingsTitle>{translate('settings.common.map')}</SettingsTitle>
            <SettingsRow>
              <InputField
                label={intl.formatMessage({
                  id: 'settings.common.online',
                })}
                placeholder="Url"
                sx={{ maxWidth: 300 }}
                {...register(CommonDataField.ONLINE_MAP)}
              />
            </SettingsRow>
            <SettingsRow>
              <InputField
                label={intl.formatMessage({
                  id: 'settings.common.offline',
                })}
                placeholder="Url"
                sx={{ maxWidth: 300 }}
                {...register(CommonDataField.OFFLINE_MAP)}
              />
            </SettingsRow>
          </Box>
        </Box>

        <SettingsFooter>
          <Button
            color="warning"
            onClick={onClose}
            sx={{ marginRight: '10px' }}
            variant="outlined"
          >
            {translate('button.cancel')}
          </Button>
          <Button color="primary" type="submit" variant="contained">
            {translate('button.save')}
          </Button>
        </SettingsFooter>
      </SettingsForm>
    </SettingsRoot>
  );
}
