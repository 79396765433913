import { useCallback, useEffect, useRef, useState } from 'react';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { Coords } from '..';
import { getRadarPaddingByContainerSize } from '../utils';
import { useAppSelector } from '../../../app';
import { selectCommonData } from '../../common';

const { radarSize } = RADAR_IMAGE_PROPERTIES;
let temp = {
  x: 0,
  y: 0,
};
let dragStartCoords = {
  x: 0,
  y: 0,
};
let offset = {
  x: 0,
  y: 0,
};

let zoomRatio = 1;

function getViewBoxSize() {
  return zoomRatio !== 0 ? Math.floor(radarSize / zoomRatio) : 0;
}
function getMaxOffset() {
  return radarSize - getViewBoxSize();
}

function getViewBoxData() {
  return [offset.x, offset.y, getViewBoxSize(), getViewBoxSize()].join(' ');
}

export interface UseZoom {
  viewBox: string;
  viewBoxSize: number;
  zoomRatio: number;
  offset: Coords;
  offsetPercent: Coords;
  radarRef: React.MutableRefObject<SVGSVGElement | null>;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleZoomReset: () => void;
  handleMoveTop: () => void;
  handleMoveBottom: () => void;
  handleMoveLeft: () => void;
  handleMoveRight: () => void;
  handleVerticalAlign: () => void;
  handleHorizontalAlign: () => void;
}

export interface useZoomProps {
  maxZoomRatio: number;
  mouseSensitive: number;
  buttonSensitive: number;
}

export default function useZoom({
  maxZoomRatio,
  mouseSensitive,
  buttonSensitive,
}: useZoomProps): UseZoom {
  const { is_full_width } = useAppSelector(selectCommonData);
  const radarRef = useRef<SVGSVGElement | null>(null);
  const [isDrag, setIsDrag] = useState(false);
  const [viewBox, setViewBox] = useState(getViewBoxData());

  useEffect(() => {
    document.body.style.cursor = isDrag ? 'grab' : 'default';
  }, [isDrag]);

  const getOffsetPercent = useCallback(
    () => ({
      x:
        offset.x /
        ((radarSize - getRadarPaddingByContainerSize(radarSize)) / 100),
      y:
        offset.y /
        ((radarSize - getRadarPaddingByContainerSize(radarSize)) / 100),
    }),
    [],
  );

  const handleZoomIn = useCallback(() => {
    if (maxZoomRatio > zoomRatio) {
      zoomRatio += mouseSensitive;

      if (zoomRatio < 1) {
        offset.x = ((getViewBoxSize() - 1000) / 2) * -1;
        offset.y = ((getViewBoxSize() - 1000) / 2) * -1;
      }

      setViewBox(getViewBoxData());
    }
  }, [mouseSensitive, maxZoomRatio]);

  const handleZoomOut = useCallback(() => {
    const max = is_full_width ? 0.1 : 1;

    if (zoomRatio > max) {
      zoomRatio -= mouseSensitive;
    }

    if (zoomRatio >= 1) {
      const maxOffset = getMaxOffset();

      if (offset.x > maxOffset) {
        offset.x = maxOffset;
      }
      if (offset.y > maxOffset) {
        offset.y = maxOffset;
      }
    } else {
      offset.x = ((getViewBoxSize() - 1000) / 2) * -1;
      offset.y = ((getViewBoxSize() - 1000) / 2) * -1;
    }
    setViewBox(getViewBoxData());
  }, [mouseSensitive, is_full_width]);

  const handleVerticalAlign = useCallback(() => {
    offset = {
      x: getMaxOffset() / 2,
      y: offset.y,
    };
    setViewBox(getViewBoxData());
  }, []);

  const handleHorizontalAlign = useCallback(() => {
    offset = {
      x: offset.x,
      y: getMaxOffset() / 2,
    };
    setViewBox(getViewBoxData());
  }, []);

  const handleZoomReset = useCallback(() => {
    zoomRatio = 1;
    offset = {
      x: 1,
      y: 1,
    };
    setViewBox(getViewBoxData());
  }, []);

  const handleMoveTop = useCallback(() => {
    offset = {
      x: offset.x,
      y: offset.y > buttonSensitive ? offset.y - buttonSensitive : 0,
    };
    setViewBox(getViewBoxData());
  }, [buttonSensitive]);

  const handleMoveBottom = useCallback(() => {
    const maxOffset = getMaxOffset();
    const availableOffset = maxOffset - offset.y;
    offset = {
      x: offset.x,
      y:
        availableOffset > buttonSensitive
          ? offset.y + buttonSensitive
          : offset.y + availableOffset,
    };
    setViewBox(getViewBoxData());
  }, [buttonSensitive]);

  const handleMoveLeft = useCallback(() => {
    offset = {
      x: offset.x > buttonSensitive ? offset.x - buttonSensitive : 0,
      y: offset.y,
    };
    setViewBox(getViewBoxData());
  }, [buttonSensitive]);

  const handleMoveRight = useCallback(() => {
    const maxOffset = getMaxOffset();
    const availableOffset = maxOffset - offset.x;
    offset = {
      x:
        availableOffset > buttonSensitive
          ? offset.x + buttonSensitive
          : offset.x + availableOffset,
      y: offset.y,
    };
    setViewBox(getViewBoxData());
  }, [buttonSensitive]);

  useEffect(() => {
    if (is_full_width) {
      handleZoomReset();
    }
  }, [handleZoomReset, is_full_width]);

  useEffect(() => {
    const ref = radarRef.current;

    const handleZoom = (event: Event) => {
      event.preventDefault();
      if (radarRef.current) {
        const wheelEvent = event as WheelEvent;
        if (wheelEvent.deltaY > 0) {
          handleZoomIn();
        }

        if (wheelEvent.deltaY < 0) {
          handleZoomOut();
        }
      }
    };

    const handleDrag = (ev: MouseEvent) => {
      setIsDrag(true);
      dragStartCoords = {
        x: ev.x,
        y: ev.y,
      };
    };

    const handleMove = (ev: MouseEvent) => {
      if (isDrag) {
        const x = temp.x + dragStartCoords.x - ev.x;
        const y = temp.y + dragStartCoords.y - ev.y;
        const maxOffset = getMaxOffset();

        offset = {
          x: x < maxOffset && x > 0 ? x : offset.x,
          y: y < maxOffset && y > 0 ? y : offset.y,
        };

        setViewBox(getViewBoxData());
      }
    };

    const handleDrop = () => {
      setIsDrag(false);
      temp = offset;
    };

    if (ref) {
      ref.addEventListener('mousewheel', handleZoom);
      ref.addEventListener('mousedown', handleDrag);
      ref.addEventListener('mousemove', handleMove);
      ref.addEventListener('mouseup', handleDrop);
      ref.addEventListener('mouseleave', handleDrop);
      document.addEventListener('scroll', (ev) => ev.preventDefault());
    }

    return () => {
      if (ref) {
        ref.removeEventListener('mousewheel', handleZoom);
        ref.removeEventListener('mousedown', handleDrag);
        ref.removeEventListener('mousemove', handleMove);
        ref.removeEventListener('mouseup', handleDrop);
        ref.removeEventListener('mouseleave', handleDrop);
        document.removeEventListener('scroll', (ev) => ev.preventDefault());
      }
    };
  }, [radarRef, is_full_width, isDrag, handleZoomIn, handleZoomOut]);

  return {
    handleHorizontalAlign,
    handleMoveBottom,
    handleMoveLeft,
    handleMoveRight,
    handleMoveTop,
    handleVerticalAlign,
    handleZoomIn,
    handleZoomOut,
    handleZoomReset,
    offset,
    offsetPercent: getOffsetPercent(),
    radarRef,
    viewBox,
    viewBoxSize: getViewBoxSize(),
    zoomRatio,
  };
}
