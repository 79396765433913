import { Theme } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { DataGridComponents } from '@mui/x-data-grid/themeAugmentation';

const MuiDataGrid: DataGridComponents<Theme>['MuiDataGrid'] = {
  styleOverrides: {
    menu: ({ theme }) => ({
      '& .MuiButtonBase-root': {
        fontSize: 14,
        lineHeight: '16px',
        color: theme.palette.primary.contrastText,
        '&.Mui-selected': {
          backgroundColor: 'rgba(46, 204, 113, 0.1)',
        },
        '&:hover': {
          backgroundColor: 'rgba(46, 204, 113, 0.1)',
        },
        '& .MuiListItemIcon-root': {
          color: 'inherit',
        },
      },
    }),
    root: ({ theme }) => ({
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 0,
      border: 'none',
      boxShadow: 'none',
      '& .MuiButtonBase-root': {
        color: theme.palette.primary.light,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '& .MuiDataGrid-overlay ': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiFormControlLabel-root': {
        color: theme.palette.primary.contrastText,
        fontSize: 14,
        lineHeight: '16px',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'space-between',
      },
      '& .MuiIconButton-root': {
        padding: 2,
      },
      '& .MuiDataGrid-columnHeader': {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px',
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down('xl')]: {
          fontSize: 10,
          lineHeight: '14px',
        },
      },
      '& .MuiDataGrid-cell': {
        color: theme.palette.primary.contrastText,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        [theme.breakpoints.down('xl')]: {
          fontSize: 10,
          lineHeight: '14px',
        },
      },
      '& .MuiTablePagination-displayedRows': {
        color: theme.palette.primary.contrastText,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
      },
    }),
  },
};

export default MuiDataGrid;
