import { memo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import useTable from '../hooks/table';

function Table() {
  const data = useTable();

  return <DataGrid {...data} />;
}

export default memo(Table);
