import * as yup from 'yup';
import { MapFields, RadarDataField } from './types';
import {
  ANGLE_MAX,
  ANGLE_MIN,
  DIRECTION_MAX,
  DIRECTION_MIN,
  ELEVATION_MAX,
  ELEVATION_MIN,
  LATITUDE_MAX,
  LATITUDE_MIN,
  LONGITUDE_MAX,
  LONGITUDE_MIN,
} from './constants';

export interface SettingsRadarForm {
  [RadarDataField.LATITUDE]: number;
  [RadarDataField.LONGITUDE]: number;
  [RadarDataField.GRID_RANGE]: number;
  [RadarDataField.GRID_ANGLE]: number;
  [RadarDataField.ALTITUDE]: number;
  [RadarDataField.ELEVATION]: number;
  [RadarDataField.DIRECTION]: number;
  [RadarDataField.POSITION]: boolean;
  [RadarDataField.MAP]: MapFields;
  [RadarDataField.GPS_AUTO_UPDATE]: boolean;
}

export const validationSchema = yup.object().shape({
  [RadarDataField.LATITUDE]: yup
    .number()
    .typeError('validate.enter_number')
    .test('max value', 'validate.max_value', (value) => {
      if (typeof value !== 'number') {
        return false;
      }
      if (value > LATITUDE_MAX) {
        return false;
      }

      return true;
    })
    .test('min value', 'validate.min_value', (value) => {
      if (typeof value !== 'number') {
        return false;
      }
      if (value < LATITUDE_MIN) {
        return false;
      }

      return true;
    })
    .required('validate.enter_lat'),
  [RadarDataField.ALTITUDE]: yup
    .number()
    .typeError('validate.enter_number')
    .test('altitude format', 'validate.greater_value', (value) => {
      if (typeof value !== 'number' || value < 0) {
        return false;
      }
      return true;
    })
    .required('validate.enter_height'),
  [RadarDataField.LONGITUDE]: yup
    .number()
    .typeError('validate.enter_number')
    .test('max value', 'validate.max_value', (value) => {
      if (typeof value !== 'number') {
        return false;
      }
      if (value > LONGITUDE_MAX) {
        return false;
      }

      return true;
    })
    .test('min value', 'validate.min_value', (value) => {
      if (typeof value !== 'number') {
        return false;
      }
      if (value < LONGITUDE_MIN) {
        return false;
      }

      return true;
    })
    .required('validate.enter_long'),
  [RadarDataField.DIRECTION]: yup
    .number()
    .typeError('validate.enter_number')
    .test('direction format', 'validate.between', (value) => {
      if (typeof value !== 'number') {
        return false;
      }
      if (value < DIRECTION_MIN || value > DIRECTION_MAX) {
        return false;
      }
      return true;
    })
    .required('validate.enter_direction'),
  [RadarDataField.GRID_ANGLE]: yup
    .number()
    .typeError('validate.enter_number')
    .test('gird_angle type', 'validate.invalid_format', (value) => {
      if (typeof value !== 'number') {
        return false;
      }
      return true;
    })
    .test('gird_angle format', 'validate.between', (value) => {
      if (typeof value !== 'number') {
        return false;
      }
      if (value < ANGLE_MIN || value > ANGLE_MAX) {
        return false;
      }
      return true;
    })
    .required('validate.enter_rotation'),
  [RadarDataField.ELEVATION]: yup
    .number()
    .typeError('validate.enter_number')
    .test('elevation format', 'validate.between', (value) => {
      if (typeof value !== 'number') {
        return false;
      }
      if (value < ELEVATION_MIN || value > ELEVATION_MAX) {
        return false;
      }
      return true;
    })
    .required('validate.enter_elevation'),
});
