import {
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const InputWrapper = styled(FormControl)(() => ({
  width: '100%',
}));

export const Label = styled(InputLabel)(({ theme }) => ({
  position: 'static',
  transform: 'none',
  marginBottom: 10,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '18px',
  letterSpacing: ' -0.2px',
  color: theme.palette.primary.contrastText,
  cursor: 'pointer',
  '&.Mui-error': {
    color: theme.palette.warning.main,
  },
  '&.Mui-focused': {
    color: theme.palette.primary.contrastText,
  },
  '&.Mui-disabled': {
    color: alpha(theme.palette.primary.contrastText, 0.1),
  },
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
    lineHeight: '14px',
    marginBottom: 8,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 10,
    lineHeight: '12px',
    marginBottom: 5,
  },
}));

export const Input = styled(InputBase)(({ theme }) => ({
  padding: 0,
  '&.Mui-error .MuiInputBase-input': {
    borderColor: theme.palette.warning.main,
  },
  '& .MuiInputBase-input': {
    transition: theme.transitions.create('border-color'),
    width: '100%',
    height: 'auto',
    border: `1px solid ${alpha(theme.palette.primary.contrastText, 0.3)}`,
    borderRadius: 5,
    padding: '9px 13px',
    fontSize: 16,
    lineHeight: '18px',
    fontWeight: 400,
    color: theme.palette.primary.contrastText,
    '&:focus': {
      borderColor: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
      color: alpha(theme.palette.primary.contrastText, 0.1),
      textFillColor: alpha(theme.palette.primary.contrastText, 0.1),
      border: `1px solid ${alpha(theme.palette.primary.contrastText, 0.1)}`,
    },
    '& .MuiInputAdornment-root': {
      color: alpha(theme.palette.primary.contrastText, 0.1),
    },
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
    'input[type=number]': {
      appearance: 'textfield',
    },

    [theme.breakpoints.down('xl')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '7px 13px',
    },
  },
  '& .MuiInputAdornment-root': {
    position: 'absolute',
    right: 10,
    '& .MuiTypography-root': {
      color: theme.palette.primary.contrastText,
    },
  },

  '&.Mui-disabled .MuiInputAdornment-root': {
    '& .MuiTypography-root': {
      color: alpha(theme.palette.primary.contrastText, 0.1),
    },
  },
}));

export const ErrorText = styled(FormHelperText)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  margin: 0,
  color: theme.palette.warning.main,
}));
