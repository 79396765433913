import { EQUATOR_LENGTH_IN_METER } from '../constants';
import { degreeToRadian } from './math';

const safeMathLog2 = (value: number): number => {
  if (typeof value !== 'number' || value <= 0) {
    throw new Error('Invalid input: value must be a positive number');
  }
  return Math.log2(value);
};

const safeMathCos = (value: number): number => {
  if (typeof value !== 'number') {
    throw new Error('Invalid input: value must be a number');
  }
  return Math.cos(value);
};

/**
 * Calculates the zoom level for a map based on the number of pixels per meter and the latitude.
 *
 * @param pxInMeter - The number of pixels per meter on the map. Must be a positive number.
 * @param latitude - The latitude of the location on the map. Must be a number between -90 and 90.
 * @returns The calculated zoom level for the map.
 * @throws {Error} If the input parameters are invalid.
 *
 * @example
 * const pxInMeter = 10;
 * const latitude = 45;
 * const zoom = getZoomMap(pxInMeter, latitude);
 * console.log(zoom); // Output: 10.2
 */

export default function getZoomMap(pxInMeter: number, latitude: number) {
  if (typeof pxInMeter !== 'number' || pxInMeter <= 0) {
    throw new Error('Invalid input: pxInMeter must be a positive number');
  }
  if (typeof latitude !== 'number') {
    throw new Error('Invalid input: latitude must be a number');
  }
  if (latitude < -90 || latitude > 90) {
    throw new Error('Invalid input: latitude must be between -90 and 90');
  }

  const latitudeLengthInMeter =
    EQUATOR_LENGTH_IN_METER * safeMathCos(degreeToRadian(latitude));

  const zoom = safeMathLog2(latitudeLengthInMeter / pxInMeter) - 8;

  return Number(zoom.toFixed(1));
}
