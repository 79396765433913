import { styled } from '@mui/material';

export const RadarInfo = styled('rect')(({ theme }) => ({
  stroke: 'rgba(255, 255, 255, 0.3)',
  strokeWidth: 0.8,
  fill: 'transparent',
  boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('xl')]: {},
}));

export const RadarInfoDivider = styled('line')(({ theme }) => ({
  stroke: 'rgba(255, 255, 255, 0.3)',
  strokeWidth: 0.8,
  [theme.breakpoints.down('xl')]: {},
}));

export const RadarInfoText = styled('text')(({ theme }) => ({
  fill: theme.palette.primary.contrastText,
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '14px',
  letterSpacing: '0.05em',
}));
