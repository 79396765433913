import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { TargetsSliceState } from './types';

const initialState: TargetsSliceState = {
  data: {
    targets: [
      // {
      //   id: 2, // ID объекта
      //   type: TargetType.DRONE,
      //   rcs: 22.87, // ЭПР в м2
      //   radSpeed: -79.0,
      //   speed: 90, // Полная скорость в м/с
      //   range: 17408.0, // Полное растояние от объекта до радра в м
      //   altitude: 1000.0, // Высота объекта относительно радара в м
      //   altitude_sl: 188, // Высота над уровнем моря в м
      //   altitude_gl: 13,
      //   dist_horizon: 12846.65, // Растояние до объекта в горизонтальной плоскости в м
      //   az: 19.61, // Азимут объекта в градусах
      //   el: 42.44, // Возвышение объекта в градусах
      //   lat: 49.442859, // Широта объекта в градусах
      //   long: 23.704239, // Долгота объекта в градусах
      //   track: [
      // { d: 12385.6, a: 25.14, h: 1000 },
      // { d: 12409.03, a: 24.8, h: 1000 },
      // { d: 12432.3, a: 24.46, h: 1000 },
      // { d: 12456.53, a: 24.13, h: 1000 },
      // { d: 12479.34, a: 23.82, h: 1000 },
      // { d: 12501.23, a: 23.54, h: 1000 },
      // { d: 12525.25, a: 23.23, h: 1000 },e
      // { d: 12548.75, a: 22.93, h: 1000 },
      // { d: 12573.06, a: 22.63, h: 1000 },
      // { d: 12599.3, a: 22.31, h: 1000 },
      // { d: 12623.92, a: 22.01, h: 1000 },
      // { d: 12647.17, a: 21.74, h: 1000 },
      // { d: 12672.72, a: 21.45, h: 1000 },
      // { d: 12700.8, a: 21.15, h: 1000 },
      // { d: 12728.01, a: 20.86, h: 1000 },
      // { d: 12755.41, a: 20.56, h: 1000 },
      // { d: 800.25, a: 45.24, h: 10 },
      // { d: 8000.25, a: 144.24, h: 200 },
      // { d: 10815.76, a: 33.92, h: 1000 },
      // { d: 12846.65, a: 19.61, h: 4000 },
      //   ],
      // },
    ],
    is_show_all_info: false,
    selectedObjects: [],
  },
};

const targetsSlice = createSlice({
  name: Features.targets,
  initialState,
  reducers: {
    setIsShowAllInfo(state, action) {
      state.data.is_show_all_info = action.payload;
    },

    addTargets(state, action) {
      state.data.targets = action.payload;
    },
    toggleSelectedObject(state, action: PayloadAction<number | string>) {
      const index = state.data.selectedObjects.indexOf(action.payload);
      if (index === -1) {
        state.data.selectedObjects.push(action.payload);
      } else {
        state.data.selectedObjects.splice(index, 1);
      }
    },
  },
});

export const { setIsShowAllInfo, addTargets, toggleSelectedObject } =
  targetsSlice.actions;

export default targetsSlice.reducer;
