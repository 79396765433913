import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { getPersistConfig } from 'redux-deep-persist';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './reducers';
import services from '../services';

const persistConfig = getPersistConfig({
  key: 'root',
  storage,
  version: 2,
  migrate: (state) => Promise.resolve(state),
  blacklist: [
    'radar.data.settings.status',
    'radar.data.errors',
    'common.data.is_full_width',
    'targets',
    'webSocket.data.web_socket',
    'webSocket.data.targets_web_socket',
    'webSocket.data.is_connection',
    'webSocket.data.is_connected',
  ],
  rootReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [
          'webSocket.data.web_socket',
          'webSocket.data.targets_web_socket',
          'common.data.is_full_width',
        ],
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          '@@webSocket/setTargetsWebSocket',
          '@@webSocket/setWebSocket',
        ],
      },
      thunk: { extraArgument: services },
    }),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export default store;
