import { Theme } from '@mui/material';
import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation';

const MuiCalendarPicker: PickerComponents<Theme>['MuiCalendarPicker'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.secondary.dark,
      // '& .MuiClockPointer-root': {
      //   backgroundColor: theme.theme.palette.primary.main,
      // },
      '& .MuiPickersCalendarHeader-label ': {
        color: theme.palette.primary.light,
      },
      '& .MuiPickersCalendarHeader-switchViewButton': {
        color: theme.palette.primary.light,
      },
      '& .MuiIconButton-edgeEnd': {
        color: theme.palette.primary.light,
      },
      '& .MuiIconButton-edgeStart': {
        color: theme.palette.primary.light,
      },
      '& .MuiDayPicker-weekDayLabel': {
        color: theme.palette.primary.light,
      },
      '& .MuiButtonBase-root': {
        color: theme.palette.primary.light,
      },
      '& .MuiButtonBase-root.MuiPickersDay-today': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '& .MuiPickersDay-root.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .PrivatePickersYear-yearButton': {
        color: theme.palette.primary.contrastText,
      },
      '& .PrivatePickersYear-yearButton.Mui-selected': {
        color: theme.palette.primary.main,
      },
      '& .PrivatePickersYear-yearButton:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
  },
};

export default MuiCalendarPicker;
