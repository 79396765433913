import { LanguageField } from './types';

export const languages = [LanguageField.uk, LanguageField.en];

export const dateFormats = [
  {
    label: '01.01.2000',
    value: 'dd.MM.yyy',
  },
  {
    label: '04/29/2020',
    value: 'dd/MM/yyy',
  },
  {
    label: 'Apr 29, 2020',
    value: 'PP',
  },
  {
    label: 'April 29th, 2020',
    value: 'PPP',
  },
  {
    label: 'Friday, April 29th, 2020',
    value: 'PPPP',
  },
];

export const timeFormats = [
  {
    label: '23:01:33',
    value: 'HH:mm:ss',
  },
  {
    label: '12:00:00 am',
    value: 'h:mm:ss aaa',
  },
  {
    label: '12:00 AM',
    value: 'p',
  },
  {
    label: '12:00:00 AM',
    value: 'pp',
  },
  {
    label: '12:00:00 AM GMT+2',
    value: 'ppp',
  },
  {
    label: '12:00:00 AM GMT+02:00	',
    value: 'pppp',
  },
];
