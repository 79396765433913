import { Theme } from '@mui/material';
import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation';

const MuiDatePicker: PickerComponents<Theme>['MuiDatePicker'] = {
  // defaultProps: {},
  // styleOverrides: {
  //   root: () => ({
  //     backgroundColor: theme.palette.secondary.dark,
  //     '& .MuiPickersCalendarHeader-label': {
  //       color: theme.palette.primary.light,
  //     },
  //   }),
  // },
};

export default MuiDatePicker;
