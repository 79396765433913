import { useTheme } from '@mui/material';
import { TargetData } from '../../targets';
import RadarTargetTail from './radar-target-tail';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import RadarTargetInfo from './radar-target-info';
import { getObjectCoordinateOnRadar } from '../functions/get-target-coords';
import RadarTargetTrack from './radar-target-track';
import { RadarSettingsData, getColorByTargetElevation } from '..';

const { radarCenter, radarPadding } = RADAR_IMAGE_PROPERTIES;
const maxDisplayRadius = radarCenter - radarPadding;

export interface TargetProps {
  targetSize: number;
  is_show_all_info: boolean;
  targetData: TargetData;
  radarSettings: RadarSettingsData;
  fullWidthRadar: boolean;
  selectedObjects: (number | string)[];
  onClick: (id: string | number) => void;
}

function RadarTarget({
  targetSize,
  is_show_all_info,
  targetData,
  radarSettings,
  fullWidthRadar,
  selectedObjects,
  onClick,
}: TargetProps) {
  const { palette } = useTheme();
  const {
    dist_horizon,
    track,
    radSpeed,
    az,
    altitude,
    id,
    point_id = '',
  } = targetData;
  const { grid_range, direction, grid_angle } = radarSettings;
  const pxInMeter = maxDisplayRadius / grid_range;
  const distance = dist_horizon * pxInMeter;

  if (maxDisplayRadius < distance) {
    return null;
  }

  const angle = fullWidthRadar ? 0 : grid_angle;

  const targetCords = getObjectCoordinateOnRadar({
    angle: direction + az - 90 + angle,
    distance,
    radarCenter,
  });

  const getTargetColor = () => {
    if (!track && radSpeed === 0) {
      return palette.info.dark;
    }
    return getColorByTargetElevation(altitude, palette);
  };

  const isShowInfo =
    selectedObjects.includes(id) ||
    selectedObjects.includes(point_id) ||
    is_show_all_info;

  const clickHandle = () => {
    if (id) {
      onClick(id);
    } else if (point_id) {
      onClick(point_id);
    }
  };

  return (
    <g onClick={clickHandle} style={{ cursor: 'pointer' }}>
      {track ? (
        <>
          <RadarTargetTail
            fullWidthRadar={fullWidthRadar}
            maxDisplayRadius={maxDisplayRadius}
            pxInMeter={pxInMeter}
            radarSettings={radarSettings}
            targetCoords={targetCords}
            targetData={targetData}
          />
          <RadarTargetTrack
            color={getTargetColor()}
            fullWidthRadar={fullWidthRadar}
            pxInMeter={pxInMeter}
            radarSettings={radarSettings}
            targetCords={targetCords}
            targetData={targetData}
          />
        </>
      ) : (
        <rect
          fill="none"
          height={targetSize}
          stroke={getTargetColor()}
          width={targetSize}
          x={targetCords.x - targetSize / 2}
          y={targetCords.y - targetSize / 2}
        />
      )}

      {isShowInfo && (
        <RadarTargetInfo
          targetCords={targetCords}
          targetData={targetData}
          targetSize={targetSize}
        />
      )}
    </g>
  );
}

export default RadarTarget;
