/* eslint-disable */
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function SettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon height={24} {...props} viewBox="0 0 24 25" width={25}>
      <path
        d="M12.0027 16.6545C10.8576 16.6545 9.75931 16.1996 8.94956 15.3898C8.13981 14.5801 7.6849 13.4818 7.6849 12.3366C7.6849 11.1915 8.13981 10.0932 8.94956 9.28348C9.75931 8.47373 10.8576 8.01882 12.0027 8.01882C13.1479 8.01882 14.2461 8.47373 15.0559 9.28348C15.8656 10.0932 16.3205 11.1915 16.3205 12.3366C16.3205 13.4818 15.8656 14.5801 15.0559 15.3898C14.2461 16.1996 13.1479 16.6545 12.0027 16.6545ZM21.1688 13.5333C21.2182 13.1385 21.2552 12.7437 21.2552 12.3366C21.2552 11.9295 21.2182 11.5224 21.1688 11.103L23.7719 9.0921C24.0063 8.90705 24.068 8.57397 23.9199 8.30256L21.4526 4.03408C21.3046 3.76268 20.9715 3.65165 20.7001 3.76268L17.6282 4.99634C16.9867 4.51521 16.3205 4.09577 15.5433 3.78735L15.0869 0.518141C15.0618 0.372837 14.9861 0.241093 14.8732 0.146255C14.7603 0.0514164 14.6175 -0.000394494 14.4701 2.26188e-06H9.5354C9.22698 2.26188e-06 8.96791 0.222062 8.91856 0.518141L8.46211 3.78735C7.6849 4.09577 7.01872 4.51521 6.37722 4.99634L3.30539 3.76268C3.03399 3.65165 2.7009 3.76268 2.55286 4.03408L0.0855318 8.30256C-0.0748444 8.57397 -0.00082446 8.90705 0.233572 9.0921L2.8366 11.103C2.78726 11.5224 2.75025 11.9295 2.75025 12.3366C2.75025 12.7437 2.78726 13.1385 2.8366 13.5333L0.233572 15.5812C-0.00082446 15.7662 -0.0748444 16.0993 0.0855318 16.3707L2.55286 20.6392C2.7009 20.9106 3.03399 21.0093 3.30539 20.9106L6.37722 19.6646C7.01872 20.1581 7.6849 20.5775 8.46211 20.8859L8.91856 24.1551C8.96791 24.4512 9.22698 24.6733 9.5354 24.6733H14.4701C14.7785 24.6733 15.0375 24.4512 15.0869 24.1551L15.5433 20.8859C16.3205 20.5652 16.9867 20.1581 17.6282 19.6646L20.7001 20.9106C20.9715 21.0093 21.3046 20.9106 21.4526 20.6392L23.9199 16.3707C24.068 16.0993 24.0063 15.7662 23.7719 15.5812L21.1688 13.5333Z"
        fill="#FFD233"
      />
    </SvgIcon>
  );
}
