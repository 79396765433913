import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { TargetType } from '../targets';
import {
  AirplaneIcon,
  CruiseMissileIcon,
  DroneIcon,
  GuidedBombIcon,
  HelicopterIcon,
  LoiteringMunitionIcon,
  TransportAircraftIcon,
  UavImpactIcon,
  UavTacticalIcon,
} from '../../app';

export function getTypeImage(type: TargetType | undefined) {
  if (type === undefined) {
    return {
      icon: HelpOutlineOutlinedIcon,
      name: 'Unknown',
    };
  }

  const types = {
    [TargetType.AIRPLANE]: {
      icon: AirplaneIcon,
      name: 'Airplane',
    },
    [TargetType.CRUISE_MISSILE]: {
      icon: CruiseMissileIcon,
      name: 'Cruise missile',
    },
    [TargetType.DRONE]: {
      icon: DroneIcon,
      name: 'Drone',
    },
    [TargetType.GUIDED_BOMB]: {
      icon: GuidedBombIcon,
      name: 'Guided bomb',
    },
    [TargetType.HELICOPTER]: {
      icon: HelicopterIcon,
      name: 'Helicopter',
    },
    [TargetType.LOITERING_MUNITION]: {
      icon: LoiteringMunitionIcon,
      name: 'Loitering munition',
    },
    [TargetType.TRANSPORT_AIRCRAFT]: {
      icon: TransportAircraftIcon,
      name: 'Transport aircraft',
    },

    [TargetType.UAV_IMPACT]: {
      icon: UavImpactIcon,
      name: 'Uav impact',
    },
    [TargetType.UAV_TACTICAL]: {
      icon: UavTacticalIcon,
      name: 'Uav tactical',
    },
    [TargetType.UNDEFINED]: {
      icon: HelpOutlineOutlinedIcon,
      name: 'Unknown',
    },
  };

  return (
    types[type] ?? {
      icon: HelpOutlineOutlinedIcon,
      name: 'Unknown',
    }
  );
}
