import { RootState } from '../../app/store/store';

export function selectTargets(state: RootState) {
  return state.targets.data.targets;
}

export function selectTargetsData(state: RootState) {
  return state.targets.data;
}

export function selectIsShowAllInfo(state: RootState) {
  return state.targets.data.is_show_all_info;
}

export function selectSelectedObjects(state: RootState) {
  return state.targets.data.selectedObjects;
}
