import { memo } from 'react';
import { RadarRadiusValue } from './radar-circle-step-value.styled';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { useAppSelector } from '../../../app/store/store';
import { selectRadarSettingsData } from '../selectors';

function RadarCircleStepValue() {
  const { grid_range } = useAppSelector(selectRadarSettingsData);

  const grid_range_km = grid_range / 1000;

  const { radarCenter, radarPadding, radarSize, radiusStepsCount } =
    RADAR_IMAGE_PROPERTIES;
  return (
    <g>
      <g>
        {[...Array(radiusStepsCount)].map((_, index) => {
          const x = ((radarSize / 2 - radarPadding) / radiusStepsCount) * index;
          return index > 0 ? (
            <RadarRadiusValue
              key={index}
              x={`${radarCenter + x}`}
              y={radarCenter}
            >
              {parseFloat(
                ((grid_range_km / radiusStepsCount) * index).toFixed(2),
              )}
            </RadarRadiusValue>
          ) : null;
        })}
      </g>
      <g>
        {[...Array(radiusStepsCount)].map((_, index) => {
          const x = ((radarSize / 2 - radarPadding) / radiusStepsCount) * index;
          return index > 0 ? (
            <RadarRadiusValue
              key={index}
              x={`${radarCenter - x}`}
              y={radarCenter}
            >
              {parseFloat(
                ((grid_range_km / radiusStepsCount) * index).toFixed(2),
              )}
            </RadarRadiusValue>
          ) : null;
        })}
      </g>
      <g>
        {[...Array(radiusStepsCount)].map((_, index) => {
          const y = ((radarSize / 2 - radarPadding) / radiusStepsCount) * index;
          return index > 0 ? (
            <RadarRadiusValue key={index} x={radarCenter} y={radarCenter + y}>
              {parseFloat(
                ((grid_range_km / radiusStepsCount) * index).toFixed(2),
              )}
            </RadarRadiusValue>
          ) : null;
        })}
      </g>
      <g>
        {[...Array(radiusStepsCount)].map((_, index) => {
          const y = ((radarSize / 2 - radarPadding) / radiusStepsCount) * index;
          return index > 0 ? (
            <RadarRadiusValue key={index} x={radarCenter} y={radarCenter - y}>
              {parseFloat(
                ((grid_range_km / radiusStepsCount) * index).toFixed(2),
              )}
            </RadarRadiusValue>
          ) : null;
        })}
      </g>
    </g>
  );
}

export default memo(RadarCircleStepValue);
