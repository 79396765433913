import {
  lighten,
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  darken,
  Theme,
} from '@mui/material';

export interface MuiButtonProps {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
}

const MuiButton: MuiButtonProps = {
  defaultProps: {},
  styleOverrides: {
    root: {
      textTransform: 'none',
      borderRadius: 12,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxShadow: 'none',
    },

    sizeMedium: ({ theme }) => ({
      padding: '11px 36px',
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: 700,
      [theme.breakpoints.down('xl')]: {
        padding: '10px 20px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '16px',
        padding: '8px 15px',
      },
    }),

    // BUTTON CONTAINED
    containedPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: darken(theme.palette.primary.light, 0.2),
      },
      '&:active': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:disabled': {
        backgroundColor: lighten(theme.palette.primary.light, 0.5),
      },
    }),
    containedSecondary: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      boxShadow: '0px 0px 10px rgba(63, 138, 226, 0.2)',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: darken(theme.palette.primary.main, 0.2),
      },
      '&:active': {
        backgroundColor: darken(theme.palette.primary.main, 0.2),
      },
      '&:disabled': {
        backgroundColor: lighten(theme.palette.primary.main, 0.5),
      },
    }),
    // BUTTON OUTLINED
    outlinedWarning: ({ theme }) => ({
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
      svg: {
        stroke: theme.palette.warning.main,
        transition: 'stroke 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },

      '&:disabled': {
        border: `1px solid ${lighten(theme.palette.warning.main, 0.5)}`,
        color: lighten(theme.palette.warning.main, 0.5),
        svg: {
          stroke: lighten(theme.palette.warning.main, 0.5),
        },
      },
    }),
    outlinedSecondary: ({ theme }) => ({
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      filter: 'drop-shadow(0px 0px 10px rgba(63, 138, 226, 0.25))',
      svg: {
        transition: 'stroke 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },

      '&:disabled': {
        border: `1px solid ${lighten(theme.palette.primary.main, 0.5)}`,
        color: lighten(theme.palette.primary.main, 0.5),
        svg: {
          stroke: lighten(theme.palette.primary.main, 0.5),
        },
      },
    }),
  },
};

export default MuiButton;
