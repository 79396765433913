import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { WebSocketSliceState } from './types';

const initialState: WebSocketSliceState = {
  data: {
    port: '',
    host: 'radar-server.wstart.com.ua',
    serial_number: '',
    is_connection: false,
    is_connected: false,
    web_socket: undefined,
    targets_web_socket: undefined,
  },
};

const webSocketSlice = createSlice({
  name: Features.webSocket,
  initialState,
  reducers: {
    updateWebSocketData(state, data) {
      state.data = {
        ...state.data,
        ...data.payload,
      };
    },

    setIsConnection(state, data) {
      state.data.is_connection = data.payload;
    },

    setWebSocket(state, data) {
      state.data.web_socket = data.payload;
      state.data.is_connected = !!data.payload;
    },

    setTargetsWebSocket(state, data) {
      state.data.targets_web_socket = data.payload;
    },
  },
});

export const {
  setWebSocket,
  updateWebSocketData,
  setTargetsWebSocket,
  setIsConnection,
} = webSocketSlice.actions;

export default webSocketSlice.reducer;
