import { memo } from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import { Stack } from '@mui/material';
import translate from '../../../translations/translate';
import { Root } from './radar-zoom-controls.styled';
import SettingsIconButton from '../../settings/components/settings-icon-button';

export interface RadarZoomControlsProps {
  onZoomIn: () => void;
  onZoomOut: () => void;
  onZoomReset: () => void;
  onTop: () => void;
  onBottom: () => void;
  onLeft: () => void;
  onRight: () => void;
  onVerticalAlign: () => void;
  onHorizontalAlign: () => void;
}
function RadarZoomControls({
  onBottom,
  onLeft,
  onRight,
  onTop,
  onZoomIn,
  onZoomOut,
  onZoomReset,
  onHorizontalAlign,
  onVerticalAlign,
}: RadarZoomControlsProps) {
  return (
    <Root>
      <Stack gap={0.5}>
        <Stack direction="row" gap={0.5}>
          <SettingsIconButton
            color="primary"
            enterDelay={1000}
            onClick={onVerticalAlign}
            size="small"
            tooltipTitle={translate('zoom.vertical_align')}
          >
            <VerticalAlignCenterIcon />
          </SettingsIconButton>
          <SettingsIconButton
            color="primary"
            enterDelay={1000}
            onClick={onTop}
            size="small"
            tooltipTitle={translate('zoom.up')}
          >
            <ArrowDropUpOutlinedIcon />
          </SettingsIconButton>
          <SettingsIconButton
            color="primary"
            enterDelay={1000}
            onClick={onHorizontalAlign}
            size="small"
            tooltipTitle={translate('zoom.horizontal_align')}
          >
            <VerticalAlignCenterIcon sx={{ transform: 'rotate(90deg)' }} />
          </SettingsIconButton>
        </Stack>
        <Stack direction="row" gap={0.5}>
          <SettingsIconButton
            color="primary"
            enterDelay={1000}
            onClick={onLeft}
            size="small"
            tooltipTitle={translate('zoom.left')}
          >
            <ArrowLeftOutlinedIcon />
          </SettingsIconButton>
          <SettingsIconButton
            color="primary"
            enterDelay={1000}
            onClick={onZoomReset}
            size="small"
            tooltipTitle={translate('zoom.reset')}
          >
            <ZoomInMapIcon />
          </SettingsIconButton>
          <SettingsIconButton
            color="primary"
            enterDelay={1000}
            onClick={onRight}
            size="small"
            tooltipTitle={translate('zoom.right')}
          >
            <ArrowRightOutlinedIcon />
          </SettingsIconButton>
        </Stack>
        <Stack direction="row" gap={0.5}>
          <SettingsIconButton
            color="primary"
            enterDelay={1000}
            onClick={onZoomIn}
            size="small"
            tooltipTitle={translate('zoom.in')}
          >
            <ZoomInIcon />
          </SettingsIconButton>
          <SettingsIconButton
            color="primary"
            enterDelay={1000}
            onClick={onBottom}
            size="small"
            tooltipTitle={translate('zoom.down')}
          >
            <ArrowDropDownOutlinedIcon />
          </SettingsIconButton>
          <SettingsIconButton
            color="primary"
            enterDelay={1000}
            onClick={onZoomOut}
            size="small"
            tooltipTitle={translate('zoom.out')}
          >
            <ZoomOutIcon />
          </SettingsIconButton>
        </Stack>
      </Stack>
    </Root>
  );
}

export default memo(RadarZoomControls);
