import { Stack, Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import translate from '../../../translations/translate';
import { ELEVATION_VALUES } from '../constants';
import { RadarDirectionInfoLine } from './radar-direction-info.styled';
import { getColorByTargetElevation } from '..';

function RadarDirectionInfo() {
  const { palette } = useTheme();
  return (
    <Stack gap={0.5}>
      {ELEVATION_VALUES.map(({ value, range }, index) => (
        <Stack key={index} alignItems="center" direction="row">
          <RadarDirectionInfoLine
            color={getColorByTargetElevation(value, palette)}
          />
          <Typography
            key={index}
            color="primary.contrastText"
            textAlign="end"
            variant="subtitle1"
            width={80}
          >
            {translate('info.height_in_meter', {
              value: range,
            })}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}

export default memo(RadarDirectionInfo);
