import toast from './toast.json';
import info from './info.json';
import description from './description.json';
import tooltip from './tooltip.json';
import statuses from './statuses.json';
import tabs from './tabs.json';
import button from './button.json';
import settings_common from './settings-common.json';
import settings_position from './settings-position.json';
import settings_virazh from './settings-virazh.json';
import settings_radar from './settings-radar.json';
import confirm from './confirm.json';
import validation from './validation.json';
import table from './table.json';
import mobile_nav from './mobile-nav.json';
import targets from './targets.json';
import zoom from './zoom.json';

export default {
  'locales.en': 'English',
  'locales.uk': 'Українська',
  'radar.radar': 'Radar',
  ...button,
  ...confirm,
  ...description,
  ...info,
  ...mobile_nav,
  ...settings_common,
  ...settings_position,
  ...settings_radar,
  ...settings_virazh,
  ...statuses,
  ...table,
  ...tabs,
  ...targets,
  ...toast,
  ...tooltip,
  ...validation,
  ...zoom,
};
