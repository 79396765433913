import { degreeToRadian } from './math';

export interface getObjectCoordinateOnRadar {
  distance: number;
  angle: number;
  radarCenter: number;
}

export function getObjectCoordinateOnRadar({
  angle,
  distance,
  radarCenter,
}: getObjectCoordinateOnRadar) {
  const x = distance * Math.cos(degreeToRadian(angle)) + radarCenter;

  const y = distance * Math.sin(degreeToRadian(angle)) + radarCenter;

  return { x, y };
}
