import { combineReducers } from '@reduxjs/toolkit';

import asyncRequestStateReducer, {
  REDUCER_NAME as ASYNC_REQUEST_STATE_REDUCER_NAME,
} from './async-request-state-reducer';
import radar from '../../../features/radar/slice';
import targets from '../../../features/targets/slice';
import virazh from '../../../features/virazh/slice';
import common from '../../../features/common/slice';
import webSocket from '../../../features/socket/slice';

const appReducer = combineReducers({
  radar,
  targets,
  webSocket,
  virazh,
  common,
  [ASYNC_REQUEST_STATE_REDUCER_NAME]: asyncRequestStateReducer,
});

export default appReducer;
