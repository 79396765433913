import { InputBaseProps } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { ForwardedRef, forwardRef } from 'react';
import { ErrorText, Input, InputWrapper, Label } from './input-field.styled';
import {
  LATITUDE_MAX,
  LATITUDE_MIN,
  LONGITUDE_MAX,
  LONGITUDE_MIN,
} from '../../features/radar/constants';

export enum FormatTypes {
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
  NUMBER = 'number',
  STRING = 'string',
}

export interface InputFieldProps extends InputBaseProps {
  label?: string;
  formatType?: FormatTypes;
  errorText?: string;
  error?: boolean;
}

function InputField(
  {
    error,
    errorText,
    label,
    className,
    name,
    disabled,
    sx,
    formatType = FormatTypes.STRING,
    ...otherProps
  }: InputFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const id = uuidv4();
  const errorId = uuidv4();

  const getInputPropsByFormatType = (type: FormatTypes) => {
    const inputProps = {
      [FormatTypes.LATITUDE]: {
        type: 'number',
        placeholder: '48.7687016',
        inputProps: {
          pattern: '^\\d{0,5}(\\.\\d{0,6})?$',
          maxLength: 11,
          step: 0.000001,
          max: LATITUDE_MAX,
          min: LATITUDE_MIN,
        },
      },
      [FormatTypes.LONGITUDE]: {
        type: 'number',
        placeholder: '48.7687016',
        inputProps: {
          pattern: '^\\d{0,5}(\\.\\d{0,6})?$',
          maxLength: 11,
          step: 0.000001,
          max: LONGITUDE_MAX,
          min: LONGITUDE_MIN,
        },
      },
      [FormatTypes.STRING]: {
        type: 'string',
      },
      [FormatTypes.NUMBER]: {
        inputProps: {
          pattern: '[0-9,.]*',
        },
      },
    };

    return inputProps[type];
  };

  return (
    <InputWrapper
      className={className}
      error={error}
      sx={sx}
      variant="standard"
    >
      {label && (
        <Label disabled={disabled} htmlFor={id} shrink>
          {label}
        </Label>
      )}

      <Input
        ref={ref}
        aria-describedby={errorId}
        disabled={disabled}
        error={Boolean(error)}
        id={id}
        name={name}
        {...getInputPropsByFormatType(formatType)}
        {...otherProps}
      />

      <ErrorText id={errorId}>{errorText}</ErrorText>
    </InputWrapper>
  );
}

export default forwardRef(InputField);
