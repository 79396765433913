import { memo } from 'react';
import { RadarRadiusCircle } from './radar-circles.styled';
import { RADAR_IMAGE_PROPERTIES } from '../constants';

function RadarCircles() {
  const { radarCenter, radarPadding, radiusStepsCount } =
    RADAR_IMAGE_PROPERTIES;

  return (
    <g>
      {[...Array(radiusStepsCount)].map((_, index) => {
        const radius =
          ((radarCenter - radarPadding) / radiusStepsCount) * (index + 1);
        return (
          <RadarRadiusCircle
            key={index}
            cx={radarCenter}
            cy={radarCenter}
            r={radius}
          />
        );
      })}
    </g>
  );
}

export default memo(RadarCircles);
