import { useMediaQuery, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import RadarSwitcher from './radar-switcher';
import RadarStatus from './radar-status';
import RadarZoomControls from './radar-zoom-controls';
import RadarInfo from './radar-info';
import RadarDirectionInfo from './radar-direction-info';
import Radar from './radar';
import RadarMap from './radar-map';
import { selectRadarStatus } from '../selectors';
import { selectWebSocketData } from '../../socket/selectors';
import {
  Root,
  RadarStatusWrapper,
  RadarZoomControlsWrapper,
  RadarInfoWrapper,
  RadarDirectionInfoWrapper,
  Container,
  RadarRecordWrapper,
} from './radar-page.styled';
import { SocketDataFields } from '../../socket/types';
import useZoom from '../hooks/use-zoom';
import { getRadarPaddingByContainerSize } from '../utils';
import { useAppSelector } from '../../../app';
import Record from '../../../app/components/record';
import { selectCommonData } from '../../common';

const variants = {
  open: { top: 40 },
  close: { top: 0 },
};

export default function RadarPage() {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [padding, setPadding] = useState(0);
  const [w, setW] = useState(0);
  const [containerSize, setContainerSize] = useState(0);
  const { is_full_width } = useAppSelector(selectCommonData);
  const {
    radarRef,
    handleZoomIn,
    handleZoomOut,
    handleMoveBottom,
    handleMoveLeft,
    handleMoveRight,
    handleMoveTop,
    handleZoomReset,
    handleHorizontalAlign,
    handleVerticalAlign,
    offsetPercent,
    viewBox,
    zoomRatio,
  } = useZoom({
    maxZoomRatio: 3,
    mouseSensitive: 0.01,
    buttonSensitive: 10,
  });
  const theme = useTheme();
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const status = useAppSelector(selectRadarStatus);
  const socketData = useAppSelector(selectWebSocketData);

  const ro = new ResizeObserver((entries) => {
    const { width, height } = entries[0].target.getBoundingClientRect();
    const maxSize = width < height ? width : height;
    const calculatePadding = getRadarPaddingByContainerSize(maxSize);
    setPadding(calculatePadding);
    setW(width);
    setContainerSize(maxSize);
  });

  if (containerRef.current) {
    ro.observe(containerRef.current);
  }

  return (
    <Root>
      <Container ref={containerRef}>
        <RadarStatusWrapper>
          {isUpSm && (
            <RadarStatus
              is_connected={socketData[SocketDataFields.IS_CONNECTED]}
              serial_number={socketData.serial_number}
              status={status}
            />
          )}
          <RadarSwitcher socketData={socketData} status={status} />
        </RadarStatusWrapper>
        <RadarDirectionInfoWrapper
          animate={is_full_width ? 'open' : 'closed'}
          variants={variants}
        >
          <RadarDirectionInfo />
        </RadarDirectionInfoWrapper>
        <RadarMap
          containerSize={containerSize}
          offsetPercent={offsetPercent}
          padding={padding}
          w={w}
          zoomRatio={zoomRatio}
        />
        <Radar
          ref={radarRef}
          containerSize={containerSize}
          status={status}
          viewBox={viewBox}
          zoomRatio={zoomRatio}
        />
        <RadarZoomControlsWrapper>
          <RadarZoomControls
            onBottom={handleMoveBottom}
            onHorizontalAlign={handleHorizontalAlign}
            onLeft={handleMoveLeft}
            onRight={handleMoveRight}
            onTop={handleMoveTop}
            onVerticalAlign={handleVerticalAlign}
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
            onZoomReset={handleZoomReset}
          />
        </RadarZoomControlsWrapper>

        <RadarInfoWrapper>
          <RadarInfo />
        </RadarInfoWrapper>
        <RadarRecordWrapper>
          <Record />
        </RadarRecordWrapper>
      </Container>
    </Root>
  );
}
