import { toast as baseToast, TypeOptions } from 'react-toastify';
import ToastCard from '../components/toast-card';
import sound from '../../assets/sounds/wrong.mp3';

export interface ToastMessageProps {
  type: TypeOptions;
  message: string;
  isSound?: boolean;
}

export default function toast({
  type,
  message,
  isSound = false,
}: ToastMessageProps) {
  const audio = new Audio(sound);

  return baseToast(ToastCard, {
    type,
    role: message,
    onOpen: () => isSound && audio.play(),
  });
}
