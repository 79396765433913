/* eslint-disable */
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function HelicopterIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        fill="currentColor"
      >
        <path
          d="
  M 64.00 15.62
  L 64.00 16.48
  L 63.49 16.96
  Q 63.14 17.28 62.67 17.28
  L 14.73 17.28
  A 1.29 1.29 0.0 0 1 13.45 15.87
  Q 13.46 15.75 13.48 15.62
  A 1.04 1.04 0.0 0 1 14.51 14.73
  L 36.63 14.73
  Q 37.25 14.73 37.32 14.11
  L 37.45 13.04
  Q 37.52 12.44 38.07 12.18
  Q 38.74 11.86 39.34 12.29
  C 39.93 12.70 40.01 13.39 39.99 14.06
  Q 39.97 14.70 40.61 14.71
  Q 51.19 14.74 61.75 14.73
  Q 63.49 14.73 64.00 15.62
  Z"
        />
        <path
          d="
  M 64.00 38.98
  L 64.00 41.24
  Q 61.81 45.99 56.26 46.50
  A 0.28 0.28 0.0 0 0 56.01 46.79
  L 56.01 49.20
  Q 56.01 49.40 56.22 49.40
  Q 59.51 49.35 61.72 47.21
  Q 62.93 46.04 64.00 47.64
  L 64.00 48.54
  Q 61.05 51.97 55.74 51.99
  Q 43.30 52.02 30.88 51.99
  Q 29.62 51.99 29.41 50.75
  Q 29.37 50.51 29.44 50.29
  Q 29.75 49.37 30.72 49.37
  L 42.18 49.37
  A 0.55 0.55 0.0 0 0 42.73 48.82
  L 42.73 47.23
  Q 42.73 46.64 42.14 46.63
  Q 39.48 46.59 36.91 46.65
  C 30.54 46.82 27.36 41.63 24.92 36.60
  C 23.24 33.14 13.95 31.52 10.50 30.90
  Q 10.06 30.82 9.71 31.10
  Q 7.58 32.73 6.55 35.10
  Q 6.32 35.63 5.78 35.83
  Q 4.53 36.27 3.37 35.76
  A 1.05 1.04 -78.5 0 1 2.74 34.80
  L 2.73 33.34
  Q 2.72 32.82 2.33 32.48
  Q 0.63 31.00 0.00 28.86
  L 0.00 26.96
  Q 2.17 24.00 0.00 20.41
  L 0.00 19.44
  Q 1.68 18.22 3.92 17.55
  A 0.99 0.98 67.6 0 1 5.07 18.03
  Q 5.98 19.74 6.85 21.58
  C 7.56 23.07 8.65 23.92 9.81 25.00
  A 1.39 1.38 66.1 0 0 10.75 25.37
  L 29.55 25.37
  Q 30.13 25.37 30.54 24.96
  L 35.08 20.41
  Q 35.48 20.00 36.05 20.00
  L 40.13 20.01
  A 1.12 1.10 -12.7 0 1 41.11 20.62
  L 43.21 24.79
  Q 43.45 25.27 43.99 25.34
  Q 57.59 27.12 64.00 38.98
  Z
  M 50.75 38.69
  Q 55.14 38.75 60.17 38.74
  Q 60.93 38.74 60.50 38.11
  Q 55.22 30.33 45.32 28.11
  Q 44.40 27.91 44.66 28.81
  Q 45.29 31.01 45.91 32.98
  C 46.91 36.20 47.42 38.64 50.75 38.69
  Z
  M 53.37 47.12
  A 0.50 0.50 0.0 0 0 52.87 46.62
  L 45.79 46.62
  A 0.50 0.50 0.0 0 0 45.29 47.12
  L 45.29 48.88
  A 0.50 0.50 0.0 0 0 45.79 49.38
  L 52.87 49.38
  A 0.50 0.50 0.0 0 0 53.37 48.88
  L 53.37 47.12
  Z"
        />
      </svg>
    </SvgIcon>
  );
}
