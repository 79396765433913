import { Features } from '../../features/types';

/**
 * Returns the action type prefix for a given feature and action.
 *
 * @param feature - The feature name or identifier.
 * @param action - The action name or identifier.
 * @returns A string representing the action type prefix, which is a combination of the feature and action names separated by a forward slash (/).
 * @throws {Error} If either the feature or action is empty or null.
 *
 * @example
 * import { Features } from '../../features/types';
 *
 * const prefix = getActionTypePrefix(Features.Feature1, 'ACTION_TYPE');
 * console.log(prefix); // Output: 'Feature1/ACTION_TYPE'
 */

export default function getActionTypePrefix<T extends string>(
  feature: Features | string,
  action: T | string,
): string {
  if (!feature || !action) {
    throw new Error('Feature and action cannot be empty or null');
  }

  return [feature, action].join('/');
}
