import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { CommonDataField, CommonSliceState, LanguageField } from './types';
import { dateFormats, timeFormats } from './utils';

const initialState: CommonSliceState = {
  data: {
    [CommonDataField.IS_FULL_WIDTH]: false,
    [CommonDataField.LANGUAGE]: LanguageField.uk,
    [CommonDataField.FORMAT_DATE]: dateFormats[0].value,
    [CommonDataField.FORMAT_TIME]: timeFormats[0].value,
    [CommonDataField.IS_SOUND]: false,
    [CommonDataField.OFFLINE_MAP]:
      'http://176.9.45.189:8085/tile/{z}/{x}/{y}.png',
    [CommonDataField.ONLINE_MAP]:
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
};

const commonSlice = createSlice({
  name: Features.common,
  initialState,
  reducers: {
    setCommonData(state, data) {
      state.data = { ...state.data, ...data.payload };
    },
    setSoundStatus(state, data) {
      state.data[CommonDataField.IS_SOUND] = data.payload;
    },
  },
});

export const { setCommonData, setSoundStatus } = commonSlice.actions;

export default commonSlice.reducer;
