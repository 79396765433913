import services from '../services';
import { AppDispatch, RootState } from './store';

export type ThunkExtraArgument = typeof services;

export interface ThunkApi {
  state: RootState;
  dispatch: AppDispatch;
  extra: ThunkExtraArgument;
}

export enum AsyncRequestState {
  pending = 'pending',
  rejected = 'rejected',
  fulfilled = 'fulfilled',
}

export enum AsyncRequestStateActions {
  RESET = 'resetAsyncRequestState',
}

export enum CommonFeatures {
  app = '@@app',
}
