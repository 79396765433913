export enum VirazhDataField {
  STATUS = 'status',
  PORT = 'port',
  HOST = 'host',
  SIC = 'sic',
}

export enum VirazhStatus {
  ON = 'on',
  OFF = 'off',
}

export interface VirazhData {
  [VirazhDataField.STATUS]: VirazhStatus;
  [VirazhDataField.HOST]: string;
  [VirazhDataField.PORT]: string;
  [VirazhDataField.SIC]: number;
}

export interface RadarSliceState {
  data: VirazhData;
}
