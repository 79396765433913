import { Theme, alpha, styled } from '@mui/material/styles';
import { RadarStatuses } from '../types';

export interface StatusProps {
  status: RadarStatuses;
  is_connected: boolean;
}

export const getColorByStatus = (
  status: RadarStatuses,
  is_connected: boolean,
  theme: Theme,
) => {
  if (!is_connected) {
    return alpha(theme.palette.primary.contrastText, 0.5);
  }

  const colors = {
    [RadarStatuses.DISCONNECTED]: theme.palette.warning.main,
    [RadarStatuses.ON]: theme.palette.primary.light,
    [RadarStatuses.OFF]: theme.palette.primary.light,
  };

  return colors[status];
};

export const StatusIndicator = styled('div', {
  shouldForwardProp: (prop) => prop !== 'is_connected' && prop !== 'status',
})<StatusProps>(({ theme, status, is_connected }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  backgroundColor: getColorByStatus(status, is_connected, theme),
  transition: theme.transitions.create('backgroundColor'),

  [theme.breakpoints.down('xl')]: {
    width: 10,
    height: 10,
  },
}));
