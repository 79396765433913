import { memo } from 'react';
import { RadarDegreeValueStyled } from './radar-degree-values.styled';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { useAppSelector } from '../../../app/store/store';
import { selectRadarSettingsData } from '../selectors';
import { getObjectCoordinateOnRadar } from '../functions/get-target-coords';
import { selectCommonData } from '../../common';

function RadarDegreeValues() {
  const { radarCenter, radarPadding, radarSize } = RADAR_IMAGE_PROPERTIES;
  const { grid_angle = 0 } = useAppSelector(selectRadarSettingsData);
  const { is_full_width } = useAppSelector(selectCommonData);

  return (
    <g>
      {[...Array(36)].map((_, index) => {
        const radius = radarSize / 2 - radarPadding + 10;
        const deg = index * 10 - 90 + (is_full_width ? 0 : grid_angle);

        const offsetX = () => {
          if (index * 10 < 10) {
            return '-9.9';
          }
          if (index * 10 < 10) {
            return '-19.7';
          }
          return '-29.5';
        };
        const offsetY = '18';

        const getOffset = (d: number) => {
          if (d >= -270 && d < -180) {
            return `${offsetX()} ${offsetY}`;
          }
          if (d >= -180 && d < -90) {
            return `${offsetX()} 0`;
          }
          if (d >= -90 && d <= 0) {
            return '0 0';
          }
          if (d > 0 && d <= 90) {
            return `0 ${offsetY}`;
          }
          if (d > 90 && d <= 180) {
            return `${offsetX()} ${offsetY}`;
          }
          if (d > 180 && d <= 270) {
            return `${offsetX()} 0`;
          }
          return '0 0';
        };

        const { x, y } = getObjectCoordinateOnRadar({
          angle: deg,
          distance: radius,
          radarCenter,
        });

        return (
          <RadarDegreeValueStyled
            key={index}
            transform={`translate(${getOffset(deg % 360)})`}
            x={x}
            y={y}
          >
            {index * 10}
          </RadarDegreeValueStyled>
        );
      })}
    </g>
  );
}

export default memo(RadarDegreeValues);
