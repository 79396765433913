import BottomNavigation from '@mui/material/BottomNavigation';
import { useModal } from 'mui-modal-provider';
import { useCallback } from 'react';

import { RadarStatuses, selectRadarSettingsData } from '../../features/radar';
import RadarSettingsModal from '../../features/radar/components/radar-settings-modal';
import { SettingsModal } from '../../features/settings';
import { selectIsShowAllInfo, setIsShowAllInfo } from '../../features/targets';
import translate from '../../translations/translate';
import { InfoIcon, InfoOffIcon, RadarIcon, SettingsIcon } from '../icons';
import { useAppDispatch, useAppSelector } from '../store/store';
import { Root, BottomNavigationActionStyled } from './mobile-navigation.styled';

export default function MobileNavigation() {
  const dispatch = useAppDispatch();
  const isShowAllInfo = useAppSelector(selectIsShowAllInfo);
  const radarSettingsData = useAppSelector(selectRadarSettingsData);
  const { showModal } = useModal();

  const openGlobalSettingsModal = useCallback(() => {
    showModal(SettingsModal);
  }, [showModal]);

  const handleToggleShowInfo = useCallback(() => {
    dispatch(setIsShowAllInfo(!isShowAllInfo));
  }, [dispatch, isShowAllInfo]);

  const openRadarSettingsModal = useCallback(() => {
    showModal(RadarSettingsModal, radarSettingsData);
  }, [radarSettingsData, showModal]);

  return (
    <Root>
      <BottomNavigation showLabels>
        <BottomNavigationActionStyled
          disabled={radarSettingsData.status !== RadarStatuses.ON}
          icon={<RadarIcon />}
          label={translate('mobile_nav.label.radar')}
          onClick={openRadarSettingsModal}
        />
        <BottomNavigationActionStyled
          icon={<SettingsIcon />}
          label={translate('mobile_nav.label.settings')}
          onClick={openGlobalSettingsModal}
        />
        <BottomNavigationActionStyled
          icon={isShowAllInfo ? <InfoOffIcon /> : <InfoIcon />}
          label={translate('mobile_nav.label.info')}
          onClick={handleToggleShowInfo}
        />
      </BottomNavigation>
    </Root>
  );
}
