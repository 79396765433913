import { styled } from '@mui/material';
import { DialogRoot } from '../../../app/components/modal.styled';

export const RadarSettingsModalHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 34,
  [theme.breakpoints.down('xl')]: {
    marginBottom: 27,
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: 15,
  },
}));

export const DialogRootStyled = styled(DialogRoot)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& .MuiDialog-container': {
      alignItems: 'flex-end',
    },
  },
}));
