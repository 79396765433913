/* eslint-disable */
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon height={24} {...props} viewBox="0 0 24 24" width={24}>
      <path
        d="M11.9975 0H21.1206C22.8752 0 23.9967 1.1162 23.9967 2.86214V21.1387C23.9967 22.8705 22.8736 23.9992 21.1498 23.9992H2.84524C1.13643 23.9992 0.00083316 22.8688 0.00083316 21.167C0.00027772 15.0523 0 8.94072 0 2.83215C0 1.12786 1.1356 0 2.84691 0C5.89794 0 8.94814 0 11.9975 0ZM5.34389 7.98251H18.6503V5.35527H5.34389V7.98251ZM5.34389 13.3053H18.6528V10.6889H5.34805L5.34389 13.3053ZM5.34389 18.6506H14.6519V16.0233H5.34389V18.6506Z"
        fill="#FFD233"
      />
    </SvgIcon>
  );
}
