import { RadarDataField, RadarStatuses, SystemList } from '../radar';
import { TargetData } from '../targets';
import { VirazhDataField } from '../virazh';

export enum SocketDataFields {
  HOST = 'host',
  PORT = 'port',
  IS_CONNECTED = 'is_connected',
  IS_CONNECTION = 'is_connection',
  WEB_SOCKET = 'web_socket',
  SERIAL_NUMBER = 'serial_number',
  TARGETS_WEB_SOCKET = 'targets_web_socket',
}

export interface SocketData {
  [SocketDataFields.HOST]: string;
  [SocketDataFields.PORT]: string;
  [SocketDataFields.SERIAL_NUMBER]: string;
}

export interface WebSocketSliceData extends SocketData {
  [SocketDataFields.IS_CONNECTED]: boolean;
  [SocketDataFields.IS_CONNECTION]: boolean;
  [SocketDataFields.WEB_SOCKET]?: WebSocket;
  [SocketDataFields.TARGETS_WEB_SOCKET]?: WebSocket;
}

export interface WebSocketSliceState {
  data: WebSocketSliceData;
}

export enum MethodFields {
  status = 'status',
  grid = 'grid',
  radar = 'radar',
  visualization = 'visualization',
  virazh = 'virazh',
  update = 'update',
  auto_update = 'auto_update',
}

export enum ModuleFields {
  radar = 'radar',
  gps = 'gps',
  settings = 'settings',
  integrations = 'integrations',
}

export interface SocketRadarStatusData {
  [SocketDataFields.SERIAL_NUMBER]: string;
  method: MethodFields.status;
  module: ModuleFields.radar;
  status: RadarStatuses;
  errors?: Record<SystemList, boolean>;
  logging?: {
    status: boolean;
    file: string;
  };
}
export interface SocketRadarSettingData {
  [SocketDataFields.SERIAL_NUMBER]: string;
  method: MethodFields;
  module: ModuleFields.settings;
  data: Record<RadarDataField, unknown>;
}

export interface SocketRadarIntegrationsData {
  [SocketDataFields.SERIAL_NUMBER]: string;
  method: MethodFields;
  module: ModuleFields.integrations;
  data: Record<VirazhDataField, unknown>;
}

export type RadarSocketResponse =
  | SocketRadarStatusData
  | SocketRadarSettingData
  | SocketRadarIntegrationsData;

export type SocketTargetsData = {
  [SocketDataFields.SERIAL_NUMBER]: string;
  type: 'render' | 'prerender';
  sector_id?: string;
  targets: TargetData[];
};
