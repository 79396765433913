import { memo } from 'react';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { useAppSelector } from '../../../app';
import { selectRadarSettingsData } from '../selectors';
import { RadarDeadZoneCircle } from './radar-circles.styled';

function RadarDeadZone() {
  const { grid_range, blind_area } = useAppSelector(selectRadarSettingsData);
  const { radarCenter, radarPadding } = RADAR_IMAGE_PROPERTIES;
  const pxInMeter = grid_range / (radarCenter - radarPadding);
  const radius = blind_area / pxInMeter;

  return (
    <g>
      <RadarDeadZoneCircle cx={radarCenter} cy={radarCenter} r={radius} />
    </g>
  );
}

export default memo(RadarDeadZone);
