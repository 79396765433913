/* eslint-disable */
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function RadarIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      height="20"
      {...props}
      sx={{ fill: '#fff' }}
      viewBox="0 0 20 20"
      width="20"
    >
      <path d="M9.21875 0H10.7812C10.9812 0.0894531 11.0414 0.25 11.0363 0.458984C11.0277 0.777344 11.034 1.09648 11.034 1.41523V2.13359C12.8188 2.39648 14.3441 3.13633 15.6043 4.39648C16.8645 5.65664 17.607 7.1918 17.8668 8.9668C18.4367 8.9668 18.9895 8.97188 19.5414 8.96445C19.7508 8.96133 19.9113 9.01797 20.0004 9.21836V10.7809C19.9109 10.9812 19.7504 11.0391 19.541 11.0348C19.1445 11.0262 18.7473 11.0324 18.3504 11.0324H17.8652C17.6035 12.8176 16.8629 14.3426 15.6035 15.6027C14.3441 16.8629 12.8094 17.607 11.0332 17.866C11.0332 18.4359 11.0285 18.9883 11.0355 19.5406C11.0383 19.7496 10.9793 19.9098 10.7805 19.9996H9.21875C9.01914 19.9102 8.95938 19.75 8.96367 19.541C8.97188 19.1703 8.96602 18.7988 8.96602 18.4281V17.8645C7.18164 17.6027 5.65664 16.8621 4.3957 15.6031C3.13477 14.3441 2.3918 12.8086 2.13281 11.0328C1.5625 11.0328 1.01016 11.0277 0.458203 11.0352C0.25 11.0379 0.0886719 10.982 0 10.7812V9.21875C0.0855469 9.02891 0.234375 8.96055 0.439062 8.96523C0.777344 8.97344 1.11563 8.96719 1.45469 8.96719H2.13477C2.39531 7.18203 3.13633 5.65703 4.39609 4.39687C5.65586 3.13672 7.19102 2.39336 8.96641 2.13438C8.96641 1.56328 8.97109 1.01094 8.96406 0.458594C8.96094 0.249609 9.01875 0.0890625 9.21875 0ZM4.14062 9.99531C4.1397 11.1541 4.48239 12.2872 5.12537 13.2512C5.76835 14.2152 6.68274 14.967 7.75293 15.4114C8.82312 15.8558 10.0011 15.9729 11.1378 15.7478C12.2745 15.5228 13.319 14.9658 14.1392 14.1472C14.9594 13.3286 15.5184 12.2852 15.7456 11.1489C15.9728 10.0126 15.858 8.83444 15.4157 7.7634C14.9734 6.69235 14.2234 5.7765 13.2606 5.13166C12.2978 4.48682 11.1654 4.14194 10.0066 4.14062C6.77422 4.13672 4.14297 6.76289 4.14062 9.99531Z" />
      <path d="M10.0069 12.7602C8.49323 12.7668 7.24948 11.5316 7.23932 10.0117C7.22916 8.4918 8.47877 7.23828 10.0022 7.23828C10.7342 7.23766 11.4366 7.52787 11.9546 8.04506C12.4727 8.56225 12.7641 9.26406 12.7647 9.99609C12.7653 10.7281 12.4751 11.4304 11.9579 11.9485C11.4407 12.4666 10.7389 12.758 10.0069 12.7586V12.7602Z" />
    </SvgIcon>
  );
}
