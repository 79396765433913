import { Button } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import translate from '../../translations/translate';
import { DialogRoot, Text, Title, ActionsWrapper } from './modal.styled';

export interface ConfirmModalProps extends DialogProps {
  open: boolean;
  title?: string;
  text?: string;
  confirmButtonText?: string;
  discardButtonText?: string;
  onClose: () => void;
  onConfirm: () => void;
}

export default function ConfirmModal({
  open,
  title,
  text,
  confirmButtonText,
  discardButtonText,
  onClose,
  onConfirm,
}: ConfirmModalProps) {
  return (
    <DialogRoot
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: 400,
        },
      }}
    >
      <Title>{title}</Title>
      <DialogContent>
        <Text>{text}</Text>
      </DialogContent>
      <ActionsWrapper>
        <Button color="warning" onClick={onConfirm} variant="contained">
          {confirmButtonText || translate('button.yes')}
        </Button>
        <Button color="info" onClick={onClose} variant="outlined">
          {discardButtonText || translate('button.no')}
        </Button>
      </ActionsWrapper>
    </DialogRoot>
  );
}
