import { styled } from '@mui/material/styles';

export const RadarSVG = styled('svg')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: `translate(-50%, -50%)`,
  overflow: 'hidden',
  userSelect: 'none',
});
