import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

export interface MuiFabProps {
  defaultProps?: ComponentsProps['MuiFab'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiFab'];
  variants?: ComponentsVariants['MuiFab'];
}

const MuiFab: MuiFabProps = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      width: 39,
      height: 38,
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: 5,
      minHeight: 38,
      minWidth: 39,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'rgba(46, 204, 113, 0.2)',
        border: '1px solid #2ECC71',
      },
      '&:focus': {
        backgroundColor: 'rgba(46, 204, 113, 0.2)',
        border: '1px solid #2ECC71',
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        '& .MuiSvgIcon-root': {
          stroke: 'rgb(54, 59, 59)',
        },
      },
      [theme.breakpoints.down('xl')]: {},
      [theme.breakpoints.down('sm')]: {
        width: 34,
        height: 34,
        minHeight: 34,
        minWidth: 34,
        '& .MuiSvgIcon-root': {
          fontSize: 20,
        },
      },
    }),
  },
};

export default MuiFab;
