import { ChangeEvent, useCallback, useState } from 'react';
import { set, get } from 'local-storage';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGridProps,
  GridColDef,
  GridEventListener,
  GridLocaleText,
  GridToolbar,
} from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { getTypeImage } from '../utils';
import {
  TargetData,
  TargetType,
  selectTargetsData,
  toggleSelectedObject,
} from '../../targets';
import { useAppDispatch, useAppSelector } from '../../../app/store/store';

const TABLE_COLUMNS_STORAGE_KEY = 'table_columns';

export type ExcludeTargetDataKeys = keyof Omit<
  TargetData,
  | 'object_x'
  | 'object_y'
  | 'lat'
  | 'lon'
  | 'track'
  | 'point_id'
  | 'scan_id'
  | 'id'
  | 'isSelected'
>;
export type ListOfExpandableKeys = 'coords';
export type VisibleColumnsKeys = ExcludeTargetDataKeys | ListOfExpandableKeys;
export type VisibleColumnsData = Record<VisibleColumnsKeys, boolean>;

const getInitialVisibleColumns = () => {
  const storageData = get(TABLE_COLUMNS_STORAGE_KEY);

  if (storageData) {
    const data = JSON.parse(storageData as string) as VisibleColumnsData;
    return data;
  }

  return {
    type: true,
    rcs: true,
    coords: true,
    range: true,
    altitude: true,
    altitude_sl: false,
    altitude_gl: false,
    az: true,
    el: true,
    speed: true,
    radSpeed: true,
    dist_horizon: false,
  };
};

export default function useTable() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { selectedObjects, targets } = useAppSelector(selectTargetsData);
  const isDownXl = useMediaQuery(theme.breakpoints.down('xl'));
  const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsData>(
    getInitialVisibleColumns(),
  );

  const { formatMessage } = useIntl();

  const t = (id: string) =>
    formatMessage({
      id,
    });

  const rows = targets.map(({ lat, lon, point_id, id, ...target }) => ({
    ...target,
    coords: `${lat} ${lon}`,
    id: point_id || id,
  }));

  const handleEvent: GridEventListener<'rowClick'> = (
    params, // GridRowParams
  ) => {
    dispatch(toggleSelectedObject(params.id));
  };

  const handleToggle = useCallback((field: VisibleColumnsKeys) => {
    setVisibleColumns((prev) => {
      const updatedData = {
        ...prev,
        [field]: !prev[field],
      };

      set(TABLE_COLUMNS_STORAGE_KEY, JSON.stringify(updatedData));

      return updatedData;
    });
  }, []);

  const defaultWidth = isDownXl ? 70 : 120;

  const columns: GridColDef[] = [
    {
      field: 'type',
      headerName: t('table.head.type'),
      renderCell: ({ value }) => {
        const { name, icon } = getTypeImage(value as TargetType);
        const Icon = icon;
        return (
          <Stack alignItems="center" direction="row" gap={1}>
            <Icon />
            {name}
          </Stack>
        );
      },
      width: isDownXl ? 130 : 150,
    },
    {
      field: 'rcs',
      headerName: t('table.head.rcs'),
      width: defaultWidth,
    },
    {
      field: 'coords',
      headerName: t('table.head.coords'),
      width: isDownXl ? 120 : 150,
    },
    {
      field: 'range',
      headerName: t('table.head.distance'),
      width: defaultWidth,
    },
    {
      field: 'altitude',
      headerName: t('table.head.altitude'),
      renderCell: (data) => Number(data.value).toFixed(2),
      width: defaultWidth,
    },
    {
      field: 'altitude_sl',
      headerName: t('table.head.altitude_sl'),
      renderCell: (data) => Number(data.value).toFixed(2),
      width: defaultWidth,
    },
    {
      field: 'altitude_gl',
      headerName: t('table.head.altitude_gl'),
      width: defaultWidth,
    },
    {
      field: 'az',
      headerName: t('table.head.azimuth'),
      renderCell: (data) => `${data.value}°`,
      width: defaultWidth,
    },
    {
      field: 'el',
      headerName: t('table.head.angle'),
      renderCell: (data) => `${data.value}°`,
      width: defaultWidth,
    },
    {
      field: 'speed',
      headerName: t('table.head.speed'),
      width: defaultWidth,
    },
    {
      field: 'radSpeed',
      headerName: t('table.head.dopler'),
      width: defaultWidth,
    },
    {
      field: 'dist_horizon',
      headerName: t('table.head.dist_horizon'),
      width: isDownXl ? 90 : 120,
    },
  ];

  const localeText: Partial<GridLocaleText> = {
    toolbarDensity: t('toolbarDensity'),
    toolbarDensityLabel: t('toolbarDensityLabel'),
    toolbarDensityCompact: t('toolbarDensityCompact'),
    toolbarDensityStandard: t('toolbarDensityStandard'),
    toolbarDensityComfortable: t('toolbarDensityComfortable'),
    toolbarExport: t('toolbarExport'),
    toolbarExportCSV: t('toolbarExportCSV'),
    toolbarExportPrint: t('toolbarExportPrint'),
    toolbarColumns: t('toolbarColumns'),
    columnsPanelHideAllButton: t('columnsPanelHideAllButton'),
    columnsPanelShowAllButton: t('columnsPanelShowAllButton'),
    columnMenuSortAsc: t('columnMenuSortAsc'),
    columnMenuSortDesc: t('columnMenuSortDesc'),
    columnMenuLabel: t('columnMenuLabel'),
    columnMenuUnsort: t('columnMenuUnsort'),
    columnMenuFilter: t('columnMenuFilter'),
    columnMenuHideColumn: t('columnMenuHideColumn'),
    columnMenuManageColumns: t('columnMenuManageColumns'),
    columnHeaderSortIconLabel: t('columnHeaderSortIconLabel'),
    columnsPanelTextFieldLabel: t('columnsPanelTextFieldLabel'),
    columnsPanelTextFieldPlaceholder: t('columnsPanelTextFieldPlaceholder'),
    toolbarFiltersTooltipHide: t('toolbarFiltersTooltipHide'),
    toolbarFiltersTooltipShow: t('toolbarFiltersTooltipShow'),
    filterOperatorContains: t('filterOperatorContains'),
    filterOperatorEquals: t('filterOperatorEquals'),
    filterOperatorStartsWith: t('filterOperatorStartsWith'),
    filterOperatorEndsWith: t('filterOperatorEndsWith'),
    filterOperatorIsEmpty: t('filterOperatorIsEmpty'),
    filterOperatorIsNotEmpty: t('filterOperatorIsNotEmpty'),
    filterOperatorIsAnyOf: t('filterOperatorIsAnyOf'),
    filterPanelColumns: t('filterPanelColumns'),
    filterPanelOperator: t('filterPanelOperator'),
    filterPanelDeleteIconLabel: t('filterPanelDeleteIconLabel'),
    noResultsOverlayLabel: t('noResultsOverlayLabel'),
    noRowsLabel: t('noRowsLabel'),
    filterPanelInputLabel: t('filterPanelInputLabel'),
    filterPanelInputPlaceholder: t('filterPanelInputPlaceholder'),
    toolbarFilters: t('toolbarFilters'),
    // 'filterOperator!=': '1',
    // 'filterOperator<': '2',
    // 'filterOperator<=': '3',
    // 'filterOperator=': '4',
    // 'filterOperator>': '5',
    // 'filterOperator>=': '6',
    // columnMenuShowColumns: 'columnMenuShowColumns',
    // columnsPanelDragIconLabel: 'columnsPanelDragIconLabel',
    // filterOperatorAfter: 'filterOperatorAfter',
    // filterOperatorBefore: 'filterOperatorBefore',
    // filterOperatorIs: 'filterOperatorIs',
    // filterOperatorNot: 'filterOperatorNot',
    // filterOperatorOnOrAfter: 'filterOperatorOnOrAfter',
    // filterOperatorOnOrBefore: 'filterOperatorOnOrBefore',
    // filterPanelAddFilter: 'filterPanelAddFilter',
    // 'headerFilterOperator!=': 'headerFilterOperator!=',
    // 'headerFilterOperator<': 'headerFilterOperator<',
    // 'headerFilterOperator<=': 'headerFilterOperator<=',
    // 'headerFilterOperator=': 'headerFilterOperator=',
    // 'headerFilterOperator>': 'headerFilterOperator>',
    // 'headerFilterOperator>=': 'headerFilterOperator>=',
  };

  const data: DataGridProps = {
    rows,
    columns,
    localeText,
    slots: { toolbar: GridToolbar },
    onRowClick: handleEvent,

    getRowClassName: (params) =>
      selectedObjects.includes(params.id) ? `super-app-theme` : '',
    sx: {
      '& .super-app-theme': {
        backgroundColor: 'rgba(255, 210, 51, 0.08)',
      },
    },
    initialState: {
      columns: {
        columnVisibilityModel: visibleColumns,
      },
    },
    // autoPageSize: true,
    pageSizeOptions: [100],
    onColumnVisibilityModelChange: (fields) => {
      set(TABLE_COLUMNS_STORAGE_KEY, JSON.stringify(fields));
      setVisibleColumns(fields as VisibleColumnsData);
    },
    slotProps: {
      toolbar: {
        quickFilterProps: {
          variant: 'standard',
        },
      },
      filterPanel: {
        sx: {
          '& .MuiButtonBase-root': {
            color: '#fff',
          },
          '& .MuiDataGrid-filterForm': {
            gap: 0.5,
          },
          '& .MuiInputBase-root': {
            fontSize: 14,
            marginTop: 0,
            color: '#fff',
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid #fff',
            },
            '&:before': {
              borderBottom: '1px solid #fff',
            },
            '& .MuiSvgIcon-root': {
              color: '#fff',
            },
          },
        },
        filterFormProps: {
          operatorInputProps: {},
          columnInputProps: {},
          logicOperatorInputProps: {},
          valueInputProps: {
            variant: 'standard',
          },
          deleteIconProps: {
            color: 'primary',
          },
        },
      },
      columnsPanel: {
        onChange: (ev) => {
          const event = ev as unknown as ChangeEvent<HTMLInputElement>;
          handleToggle(event.target.name as VisibleColumnsKeys);
        },

        // getTogglableColumns: (q: GridColDef[]) => ([]),
      },
      pagination: {
        sx: {
          '& .MuiTablePagination-actions': {
            display: 'flex',
            gap: 1,
            mr: 1,
          },
        },
        backIconButtonProps: {
          color: 'success',
          size: 'small',
        },
        nextIconButtonProps: {
          color: 'success',
          size: 'small',
        },
      },
    },
  };

  return data;
}
