import { alpha, styled } from '@mui/material';

export const RadarDirectionInfoRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  [theme.breakpoints.down('sm')]: {
    gap: 4,
  },
}));

export const RadarDirectionInfoCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const RadarDirectionInfoCell = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: 12,
  justifyContent: 'flex-end',
  height: 20,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: 8,
    height: 12,
  },
}));

export const RadarDirectionInfoLine = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ theme, color }) => ({
  height: 3,
  borderRadius: 4,
  width: 35,
  background: `linear-gradient(90deg, ${color} 0%, ${alpha(color, 0)} 100%)`,
  [theme.breakpoints.down('sm')]: {
    width: 25,
  },
}));
