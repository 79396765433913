import { useCallback } from 'react';
import { MotionProps } from 'framer-motion';
import { useMediaQuery, useTheme } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import SettingsIcon from '@mui/icons-material/Settings';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import TableChartIcon from '@mui/icons-material/TableChart';
import {
  RadarControlEnd,
  RadarControlStart,
  Root,
} from './settings-controls.styled';
import { useAppDispatch, useAppSelector, Clock, Date } from '../../../app';
import { selectIsShowAllInfo, setIsShowAllInfo } from '../../targets';
import translate from '../../../translations/translate';
import { SettingsSystemList, SettingsIconButton, SettingsModal } from '.';
import { selectCommonData, setCommonData, setSoundStatus } from '../../common';

export interface SettingsControlsProps extends MotionProps {
  isOpenFullWidthTable: boolean;
  onClick: () => void;
}

export default function SettingsControls({
  isOpenFullWidthTable,
  onClick,
  ...props
}: SettingsControlsProps) {
  const theme = useTheme();
  const idUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useAppDispatch();
  const isShowAllInfo = useAppSelector(selectIsShowAllInfo);
  const { showModal } = useModal();
  const { formatDate, formatTime, language, isSound, is_full_width } =
    useAppSelector(selectCommonData);

  const handleClick = useCallback(() => {
    showModal(SettingsModal);
  }, [showModal]);

  const handleToggleShowInfo = useCallback(() => {
    dispatch(setIsShowAllInfo(!isShowAllInfo));
  }, [dispatch, isShowAllInfo]);

  const handleToggleFullWidth = useCallback(() => {
    dispatch(setCommonData({ is_full_width: !is_full_width }));
  }, [dispatch, is_full_width]);

  return (
    <Root {...props}>
      <RadarControlStart alignSelf={is_full_width ? 'flex-end' : 'center'}>
        <Clock formatTime={formatTime} language={language} />
        <Date formatDate={formatDate} language={language} />
      </RadarControlStart>

      <RadarControlEnd>
        {idUpMd && (
          <>
            <SettingsIconButton
              color="primary"
              onClick={handleToggleFullWidth}
              tooltipTitle={translate(
                is_full_width ? 'tooltip.standard' : 'tooltip.full_width',
              )}
            >
              {is_full_width ? <TrackChangesIcon /> : <TravelExploreIcon />}
            </SettingsIconButton>
            {is_full_width && (
              <SettingsIconButton
                color={isOpenFullWidthTable ? 'success' : 'secondary'}
                onClick={onClick}
                tooltipTitle={translate('tooltip.table')}
              >
                <TableChartIcon />
              </SettingsIconButton>
            )}
          </>
        )}

        <SettingsIconButton
          color={isShowAllInfo ? 'success' : 'secondary'}
          onClick={handleToggleShowInfo}
          tooltipTitle={
            isShowAllInfo
              ? translate('tooltip.info_off')
              : translate('tooltip.info_on')
          }
        >
          {isShowAllInfo ? <SubtitlesIcon /> : <SubtitlesOffIcon />}
        </SettingsIconButton>

        <SettingsIconButton
          color="primary"
          onClick={handleClick}
          tooltipTitle={translate('tooltip.global')}
        >
          <SettingsIcon />
        </SettingsIconButton>

        <SettingsIconButton
          color={isSound ? 'success' : 'secondary'}
          onClick={() => dispatch(setSoundStatus(!isSound))}
          tooltipTitle={
            isSound
              ? translate('tooltip.sound_off')
              : translate('tooltip.sound_on')
          }
        >
          {isSound ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </SettingsIconButton>
        <SettingsSystemList />
      </RadarControlEnd>
    </Root>
  );
}
