import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100dvh',
  maxWidth: 4096,
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    minHeight: '100dvh',
    paddingBottom: 80,
  },
}));
