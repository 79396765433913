import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { LanguageField, locales } from '../../features/common';

export interface ClockProps {
  formatTime: string;
  language: LanguageField;
}

export default function Clock({ formatTime, language }: ClockProps) {
  const ref = useRef<NodeJS.Timeout>();

  const [time, setTime] = useState(
    format(new Date(), formatTime, {
      locale: locales[language],
    }),
  );

  useEffect(() => {
    ref.current = setInterval(() => {
      setTime(format(new Date(), formatTime, { locale: locales[language] }));
    }, 1000);
    return () => clearInterval(ref.current);
  }, [formatTime, language]);

  return (
    <Stack
      alignItems="center"
      color="primary.contrastText"
      direction="row"
      gap={0.5}
    >
      <AccessTimeFilledIcon fontSize="small" />
      <Typography variant="body1">{time}</Typography>
    </Stack>
  );
}
