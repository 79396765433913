type SectorPathInput = {
  startX: number;
  startY: number;
  radius: number;
  begin: number;
  end: number;
};

/**
 * Generates the SVG path for a sector.
 *
 * @param startX - The x-coordinate of the starting point of the sector.
 * @param startY - The y-coordinate of the starting point of the sector.
 * @param radius - The radius of the sector.
 * @param begin - The starting angle of the sector in degrees.
 * @param end - The ending angle of the sector in degrees.
 * @returns The SVG path string representing the sector.
 */
export default function getSectorPath({
  startX,
  startY,
  radius,
  begin,
  end,
}: SectorPathInput): string {
  if (
    typeof startX !== 'number' ||
    typeof startY !== 'number' ||
    typeof radius !== 'number' ||
    typeof begin !== 'number' ||
    typeof end !== 'number'
  ) {
    throw new Error(
      'Invalid input parameters. startX, startY, radius, begin, and end must be numbers.',
    );
  }
  const x1 = startX + radius * Math.cos((Math.PI * begin) / 180);
  const y1 = startY + radius * Math.sin((Math.PI * begin) / 180);
  const x2 = startX + radius * Math.cos((Math.PI * end) / 180);
  const y2 = startY + radius * Math.sin((Math.PI * end) / 180);

  return `M ${startX} ${startY} L ${x1} ${y1} A ${radius} ${radius} 0 0 1 ${x2} ${y2} Z`;
}
