import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
  alpha,
} from '@mui/material';

export interface MuiFormControlLabelProps {
  defaultProps?: ComponentsProps['MuiFormControlLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormControlLabel'];
  variants?: ComponentsVariants['MuiFormControlLabel'];
}

const MuiFormControlLabel: MuiFormControlLabelProps = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiTypography-root': {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: theme.palette.primary.contrastText,
      },
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color: alpha(theme.palette.primary.contrastText, 0.1),
      },
    }),
  },
};

export default MuiFormControlLabel;
