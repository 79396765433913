import { useCallback } from 'react';
import { RadarStatuses } from '../types';
import { useAppDispatch } from '../../../app/store/store';
import {
  Root,
  RadarSwitcherOff,
  RadarSwitcherOn,
} from './radar-switcher.styled';
import { addTargets } from '../../targets';
import { setRadarStatus } from '../slice';
import { WebSocketSliceData } from '../../socket/types';

export interface RadarSwitcherProps {
  status: RadarStatuses;
  socketData: WebSocketSliceData;
}

function RadarSwitcher({ status, socketData }: RadarSwitcherProps) {
  const dispatch = useAppDispatch();
  const { web_socket, serial_number, is_connected } = socketData;

  const disabled = !is_connected || status === RadarStatuses.DISCONNECTED;

  const isOn = status === RadarStatuses.ON;
  const isOff = status === RadarStatuses.OFF;
  const isDisconnected = status === RadarStatuses.DISCONNECTED;

  const clickHandle = useCallback(() => {
    if (isDisconnected || !web_socket) {
      return;
    }

    if (isOn) {
      dispatch(addTargets([]));
      web_socket.send(
        JSON.stringify({
          serial_number,
          module: 'radar',
          method: 'scan',
          status: 'off',
        }),
      );
      dispatch(setRadarStatus(RadarStatuses.OFF));
    } else {
      web_socket.send(
        JSON.stringify({
          serial_number,
          module: 'radar',
          method: 'scan',
          status: 'on',
        }),
      );
      dispatch(setRadarStatus(RadarStatuses.ON));
    }
  }, [dispatch, web_socket, isDisconnected, isOn, serial_number]);

  return (
    <Root disabled={disabled} onClick={clickHandle} status={status}>
      <RadarSwitcherOn active={isOn}>ON</RadarSwitcherOn>
      <RadarSwitcherOff active={isOff}>OFF</RadarSwitcherOff>
    </Root>
  );
}

export default RadarSwitcher;
