import { TargetData } from '../../targets';
import { Coords } from '../types';
import {
  RadarInfoDivider,
  RadarInfoText,
  RadarInfo,
} from './radar-target-info.styled';

export interface RadarTargetInfoProps {
  targetCords: Coords;
  targetSize: number;
  targetData: TargetData;
}

export default function RadarTargetInfo({
  targetCords,
  targetSize,
  targetData,
}: RadarTargetInfoProps) {
  const { speed, radSpeed, rcs, altitude } = targetData;
  const blockInfoWidth = 92;
  const blockInfoHeight = 34;
  const lineInfoX1 = targetCords.x + targetSize / 2;
  const lineInfoY1 = targetCords.y - targetSize / 2;
  const lineInfoX2 = lineInfoX1 + blockInfoHeight / 2;
  const lineInfoY2 = lineInfoY1 - blockInfoHeight / 2;

  const v = speed || radSpeed;

  return (
    <g>
      <line
        stroke="rgba(255, 255, 255, 0.7)"
        x1={lineInfoX1}
        x2={lineInfoX2}
        y1={lineInfoY1}
        y2={lineInfoY2}
      />
      <RadarInfo
        height={blockInfoHeight}
        rx={2}
        ry={2}
        width={blockInfoWidth}
        x={lineInfoX2}
        y={lineInfoY2 - 2}
      />
      <RadarInfoDivider
        x1={lineInfoX2}
        x2={lineInfoX2 + blockInfoWidth}
        y1={lineInfoY2 + 16}
        y2={lineInfoY2 + 16}
      />
      <RadarInfoText
        textAnchor="middle"
        x={lineInfoX2 + blockInfoWidth / 2}
        y={lineInfoY2 + 11}
      >
        {rcs}
      </RadarInfoText>
      <RadarInfoText x={lineInfoX2 + 5} y={lineInfoY2 + 28}>
        V:{v.toFixed(0)}
      </RadarInfoText>
      <RadarInfoText x={lineInfoX2 + 50} y={lineInfoY2 + 28}>
        H:{(altitude / 1000).toFixed(1)}
      </RadarInfoText>
    </g>
  );
}
