/* eslint-disable */
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function EnFlagIcon(props: SvgIconProps) {
  return (
    <SvgIcon height={13} {...props} viewBox="0,0 25,15" width={20}>
      <rect width="25" height="15" fill="#00247d" />
      <path d="M 0,0 L 25,15 M 25,0 L 0,15" stroke="#fff" strokeWidth="3" />
      <path d="M 12.5,0 V 15 M 0,7.5 H 25" stroke="#fff" strokeWidth="5" />
      <path d="M 12.5,0 V 15 M 0,7.5 H 25" stroke="#cf142b" strokeWidth="3" />
    </SvgIcon>
  );
}
