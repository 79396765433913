import { FormControlLabel, Typography, styled } from '@mui/material';

export const RadarSettingsForm = styled('form')(() => ({
  cursor: 'move',
}));

export const RadarSettingsRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& + &': {
    marginTop: 23,
  },

  [theme.breakpoints.down('xl')]: {
    '& + &': {
      marginTop: 18,
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 10,
    alignItems: 'flex-start',
  },
}));

export const RadarSettingsTitle = styled('h2')(({ theme }) => ({
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '23px',
  color: theme.palette.primary.main,
  userSelect: 'none',
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    lineHeight: '18px',
  },
}));

export const RadarSettingsFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 73,
  [theme.breakpoints.down('xl')]: {
    marginTop: 38,
  },
  [theme.breakpoints.down('xl')]: {
    marginTop: 15,
  },
}));

export const RadarSettingsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexGrow: 1,
  maxWidth: 'calc(50% - 30px)',
  '& button': {
    marginLeft: 12,
  },

  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.primary.contrastText,
  },
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
}));
