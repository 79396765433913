import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';
import vhCheck from 'vh-check';
import 'react-toastify/dist/ReactToastify.css';
import 'leaflet/dist/leaflet.css';
import {
  App,
  store,
  persistor,
  theme,
  ErrorBoundary,
  StyledToastContainer,
} from './app';

vhCheck();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <CssBaseline />
            <App />
            <StyledToastContainer pauseOnHover={false} />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
