import { RootState } from '../../app/store/store';
import { RadarDataField } from './types';

export function selectRadarSettingsData(state: RootState) {
  return state.radar.data.settings;
}

export function selectSystemStatuses(state: RootState) {
  return state.radar.data.errors;
}

export function selectRadarStatus(state: RootState) {
  return state.radar.data.settings[RadarDataField.STATUS];
}

export function selectRecordData(state: RootState) {
  return state.radar.data.record;
}
