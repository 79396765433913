import { memo } from 'react';
import _ from 'lodash';
import { MapContainer, TileLayer } from 'react-leaflet';
import ErrorBoundary from '../../../app/components/error-boundary';
import {
  RadarMapContainer,
  RadarMapProps,
  RadarMapWrapper,
} from './radar-map.styled';
import {
  LATITUDE_MAX,
  LATITUDE_MIN,
  LONGITUDE_MAX,
  LONGITUDE_MIN,
} from '../constants';
import { MapFields, getZoomMap, selectRadarSettingsData } from '..';
import { useAppSelector } from '../../../app/store/store';
import { selectCommonData } from '../../common';

function RadarMap(props: RadarMapProps) {
  const { containerSize, padding, zoomRatio, w } = props;
  const { map, grid_angle, grid_range, latitude, longitude } = useAppSelector(
    selectRadarSettingsData,
  );
  const { offline_map, online_map, is_full_width } =
    useAppSelector(selectCommonData);

  if (map === MapFields.OFF) {
    return null;
  }

  const isValidData =
    latitude &&
    longitude &&
    !_.isNaN(latitude) &&
    !_.isNaN(longitude) &&
    !_.isNaN(grid_range) &&
    latitude <= LATITUDE_MAX &&
    latitude >= LATITUDE_MIN &&
    longitude <= LONGITUDE_MAX &&
    longitude >= LONGITUDE_MIN &&
    containerSize;

  if (isValidData) {
    const range = zoomRatio < 1 ? grid_range / zoomRatio : grid_range;
    const pxInMeter = (range * 2) / (containerSize - padding);

    const zoom = getZoomMap(pxInMeter, latitude);

    const key = JSON.stringify([
      latitude,
      longitude,
      grid_range,
      containerSize,
      map,
      offline_map,
      online_map,
      padding,
      zoomRatio,
      is_full_width,
      w,
    ]);

    const size = `${containerSize - padding}px`;

    return (
      <RadarMapContainer
        containerSize={containerSize}
        is_full_width={is_full_width}
      >
        <RadarMapWrapper
          grid_angle={grid_angle}
          is_full_width={is_full_width}
          {...props}
        >
          <ErrorBoundary>
            <MapContainer
              key={key}
              center={[latitude, longitude]}
              scrollWheelZoom={false}
              style={{
                width: is_full_width ? containerSize * 2.5 : size,
                height: is_full_width ? containerSize * 2.5 : size,
                transform: `scale(${zoomRatio < 1 ? 1 : zoomRatio})`,
              }}
              zoom={zoom}
              zoomControl={false}
              zoomSnap={0.1}
            >
              <TileLayer
                url={map === MapFields.OFFLINE ? offline_map : online_map}
              />
            </MapContainer>
          </ErrorBoundary>
        </RadarMapWrapper>
      </RadarMapContainer>
    );
  }

  return null;
}

export default memo(RadarMap);
