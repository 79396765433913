import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DroneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="currentColor"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
  M 64.00 15.33
  L 64.00 16.72
  L 56.12 17.04
  Q 55.41 17.07 55.44 17.78
  L 55.47 18.33
  A 0.83 0.83 0.0 0 1 54.66 19.19
  L 53.79 19.21
  Q 53.11 19.22 53.28 18.57
  Q 53.41 18.07 53.38 17.56
  Q 53.34 17.02 52.79 17.02
  L 45.80 17.01
  Q 45.13 17.01 44.93 16.37
  Q 44.82 16.03 44.89 15.68
  A 0.86 0.85 5.3 0 1 45.73 14.99
  L 52.38 14.98
  Q 52.93 14.98 53.36 14.64
  L 54.11 14.03
  A 0.48 0.47 50.7 0 1 54.77 14.10
  Q 55.03 14.42 55.37 14.64
  Q 55.81 14.91 56.32 14.94
  L 64.00 15.33
  Z"
        />
        <path
          d="
  M 0.00 16.53
  L 0.00 15.29
  Q 3.51 14.80 7.24 14.97
  C 7.98 15.00 8.65 14.78 9.13 14.20
  Q 9.61 13.61 10.15 14.15
  L 10.63 14.62
  A 1.23 1.20 67.6 0 0 11.50 14.99
  L 18.21 14.99
  Q 18.85 14.98 19.06 15.60
  Q 19.18 15.95 19.08 16.31
  A 0.94 0.94 0.0 0 1 18.17 17.02
  L 11.22 17.01
  Q 10.68 17.01 10.61 17.55
  Q 10.55 17.91 10.67 18.26
  A 0.71 0.71 0.0 0 1 10.01 19.20
  L 9.10 19.21
  Q 8.48 19.21 8.51 18.60
  L 8.56 17.75
  Q 8.60 17.11 7.97 17.07
  L 0.00 16.53
  Z"
        />
        <path
          d="
  M 38.21 21.85
  A 0.91 0.90 -9.4 0 0 39.07 22.46
  L 48.48 22.46
  Q 49.00 22.46 49.01 22.98
  L 49.02 24.28
  A 0.89 0.89 0.0 0 1 48.44 25.13
  Q 43.71 26.85 38.98 28.81
  Q 36.38 29.88 33.87 29.83
  C 31.33 29.77 28.75 30.23 26.34 29.26
  Q 20.72 26.98 15.47 25.04
  A 0.76 0.75 9.9 0 1 14.97 24.33
  L 14.99 22.99
  Q 14.99 22.47 15.51 22.47
  L 24.71 22.46
  Q 25.31 22.46 25.64 21.95
  C 26.32 20.88 26.60 19.72 27.63 18.84
  C 31.46 15.55 36.62 17.15 38.21 21.85
  Z"
        />
        <rect
          height="4.16"
          rx="0.95"
          transform="translate(9.61,23.45) rotate(0.4)"
          width="6.28"
          x="-3.14"
          y="-2.08"
        />
        <rect height="4.16" rx="0.98" width="6.28" x="51.25" y="21.37" />
        <rect
          height="19.80"
          rx="0.26"
          transform="translate(17.02,38.57) rotate(18.7)"
          width="2.36"
          x="-1.18"
          y="-9.90"
        />
        <rect
          height="19.80"
          rx="0.27"
          transform="translate(46.99,38.58) rotate(-18.7)"
          width="2.36"
          x="-1.18"
          y="-9.90"
        />
        <path
          d="
  M 34.08 32.01
  A 0.19 0.19 0.0 0 1 34.26 32.20
  L 34.26 32.31
  A 1.87 1.86 0.3 0 1 32.38 34.16
  L 31.56 34.16
  A 1.87 1.86 0.3 0 1 29.70 32.29
  L 29.70 32.18
  A 0.19 0.19 0.0 0 1 29.90 31.99
  L 34.08 32.01
  Z"
        />
        <path
          d="
  M 38.37 45.83
  A 1.08 1.08 0.0 0 1 37.29 46.91
  L 26.71 46.91
  A 1.08 1.08 0.0 0 1 25.63 45.83
  L 25.63 37.35
  A 1.08 1.08 0.0 0 1 26.71 36.27
  L 37.29 36.27
  A 1.08 1.08 0.0 0 1 38.37 37.35
  L 38.37 45.83
  Z
  M 35.18 41.60
  A 3.18 3.18 0.0 0 0 32.00 38.42
  A 3.18 3.18 0.0 0 0 28.82 41.60
  A 3.18 3.18 0.0 0 0 32.00 44.78
  A 3.18 3.18 0.0 0 0 35.18 41.60
  Z"
        />
        <circle cx="31.99" cy="41.60" r="1.01" />
      </svg>
    </SvgIcon>
  );
}
