/* eslint-disable */
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function UkFlagIcon(props: SvgIconProps) {
  return (
    <SvgIcon height={13} {...props} viewBox="0 0 20 13" width={20}>
      <path d="M20 0H0V12H20V0Z" fill="#005BBB" />
      <path d="M20 6.5H0V13H20V6.5Z" fill="#FFD500" />
    </SvgIcon>
  );
}
