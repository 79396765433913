/* eslint-disable */
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function WarningIcon(props: SvgIconProps) {
  return (
    <SvgIcon height={22} {...props} viewBox="0 0 24 22" width={24}>
      <path
        d="M23.6475 17.5759L14.2288 1.2887C14.0033 0.896978 13.6785 0.571607 13.2873 0.345365C12.896 0.119122 12.452 0 12 0C11.548 0 11.104 0.119122 10.7127 0.345365C10.3215 0.571607 9.99671 0.896978 9.77122 1.2887L0.352471 17.5759C0.123665 17.9657 0.00206773 18.409 2.61389e-05 18.8609C-0.00201545 19.3129 0.115572 19.7573 0.340847 20.1491C0.566123 20.5409 0.891063 20.8661 1.28267 21.0916C1.67428 21.3172 2.11861 21.4351 2.57054 21.4335H21.4295C21.8814 21.4351 22.3257 21.3172 22.7173 21.0916C23.1089 20.8661 23.4339 20.5409 23.6592 20.1491C23.8844 19.7573 24.002 19.3129 24 18.8609C23.9979 18.409 23.8763 17.9657 23.6475 17.5759ZM11.1428 8.5751C11.1428 8.34775 11.2331 8.12971 11.3939 7.96895C11.5546 7.80819 11.7726 7.71787 12 7.71787C12.2273 7.71787 12.4454 7.80819 12.6061 7.96895C12.7669 8.12971 12.8572 8.34775 12.8572 8.5751V12.8612C12.8572 13.0886 12.7669 13.3066 12.6061 13.4674C12.4454 13.6281 12.2273 13.7184 12 13.7184C11.7726 13.7184 11.5546 13.6281 11.3939 13.4674C11.2331 13.3066 11.1428 13.0886 11.1428 12.8612V8.5751ZM12 18.0046C11.7457 18.0046 11.4971 17.9291 11.2856 17.7879C11.0742 17.6466 10.9094 17.4457 10.812 17.2108C10.7147 16.9758 10.6893 16.7173 10.7389 16.4679C10.7885 16.2184 10.9109 15.9893 11.0908 15.8095C11.2706 15.6297 11.4997 15.5072 11.7491 15.4576C11.9986 15.408 12.2571 15.4334 12.4921 15.5308C12.727 15.6281 12.9278 15.7929 13.0691 16.0044C13.2104 16.2158 13.2858 16.4644 13.2858 16.7187C13.2858 17.0597 13.1504 17.3868 12.9092 17.6279C12.6681 17.8691 12.341 18.0046 12 18.0046Z"
        fill="#FF2E00"
      />
    </SvgIcon>
  );
}
