import { IntlShape } from 'react-intl';
import { rootReducer } from '../../app/store/reducers';
import { AppDispatch } from '../../app/store/store';
import {
  RadarStatuses,
  setRadarStatus,
  setRecord,
  setSettingsData,
  setSystemStatus,
} from '../radar';
import {
  setIsConnection,
  setTargetsWebSocket,
  setWebSocket,
  updateWebSocketData,
} from './slice';
import {
  MethodFields,
  ModuleFields,
  RadarSocketResponse,
  SocketTargetsData,
} from './types';
import { addTargets } from '../targets';
import { setVirazhData } from '../virazh';
import { ThunkExtraArgument } from '../../app/store/types';

type RootState = ReturnType<typeof rootReducer>;

let watchdog: null | NodeJS.Timer = null;
let connected = false;
let connected_error = false;

const connectTargetsWebSocket =
  (url: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    if (state.webSocket.data.web_socket) {
      const targetsWebSocket = new WebSocket(`${url}/ws/infofromradar/`);

      targetsWebSocket.onopen = () => {
        dispatch(setTargetsWebSocket(targetsWebSocket));
      };

      targetsWebSocket.onmessage = (evt: MessageEvent<string>) => {
        if (evt.data.indexOf('serial_number') !== -1) {
          const data = JSON.parse(evt.data) as SocketTargetsData;
          dispatch(addTargets(data.targets));
        }
      };
    }
  };

const disconnectTargetsWebSocket =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { webSocket } = getState();

    if (
      webSocket.data.targets_web_socket &&
      webSocket.data.targets_web_socket.readyState === WebSocket.OPEN
    ) {
      webSocket.data.targets_web_socket.close();
    }
    dispatch(setTargetsWebSocket(undefined));
  };

const reconnectWebSocket = (
  url: string,
  dispatch: AppDispatch,
  getState: () => RootState,
  intl: IntlShape,
  services: ThunkExtraArgument,
) => {
  const { logger, notification } = services;
  const webSocket = new WebSocket(`${url}/ws/currentstate/`);

  webSocket.onopen = (ev) => {
    const { serial_number } = getState().webSocket.data;
    const { isSound } = getState().common.data;
    connected = true;
    connected_error = false;
    logger.info('On open currentstate:', ev);

    webSocket.send(
      JSON.stringify({
        serial_number,
        module: 'radar',
        method: 'init',
      }),
    );
    dispatch(setIsConnection(false));
    dispatch(setWebSocket(webSocket));
    dispatch(connectTargetsWebSocket(url));
    notification({
      type: 'success',
      message: intl.formatMessage({
        id: 'toast.connection_open',
      }),
      isSound,
    });
  };

  webSocket.onclose = (ev) => {
    const { isSound } = getState().common.data;
    logger.info('On close currentstate:', ev);
    dispatch(setWebSocket(undefined));
    dispatch(setRadarStatus(RadarStatuses.OFF));
    dispatch(disconnectTargetsWebSocket());
    // dispatch(addTargets([]));
    if (connected) {
      notification({
        type: 'warning',
        message: intl.formatMessage({
          id: 'toast.connection_closed',
        }),
        isSound,
      });
    }
    connected = false;
  };

  webSocket.onmessage = (evt: MessageEvent<string>) => {
    if (evt.data.indexOf('serial_number') === -1) return;
    const { isSound } = getState().common.data;
    const { status } = getState().radar.data.settings;
    const radarData = JSON.parse(evt.data) as RadarSocketResponse;

    if (radarData.module === ModuleFields.settings) {
      dispatch(setSettingsData(radarData.data));
      dispatch(
        updateWebSocketData({
          serial_number: radarData.serial_number,
        }),
      );

      if (radarData.data.gps_error) {
        notification({
          type: 'error',
          message: intl.formatMessage({
            id: 'toast.gps_error',
          }),
          isSound,
        });
      }
    }

    if (radarData.module === ModuleFields.radar) {
      if (radarData.logging) {
        dispatch(setRecord(radarData.logging));
      }

      if (radarData.errors) {
        dispatch(setSystemStatus(radarData.errors));

        const errors = Object.entries(radarData.errors).filter(
          (value) => !value[1],
        );

        if (errors.length) {
          notification({
            type: 'error',
            message: intl.formatMessage(
              {
                id: 'toast.errors',
              },
              {
                value: errors
                  .map((error) =>
                    intl.formatMessage({
                      id: error[0],
                    }),
                  )
                  .join(', '),
              },
            ),
            isSound,
          });
        }
      }
      if (status !== radarData.status) {
        dispatch(setRadarStatus(radarData.status));
        dispatch(addTargets([]));

        if (radarData.status === RadarStatuses.DISCONNECTED) {
          notification({
            type: 'error',
            message: intl.formatMessage({
              id: 'toast.not_available',
            }),
            isSound,
          });
        }
      }
    }
    if (radarData.module === ModuleFields.integrations) {
      if (radarData.method === MethodFields.virazh) {
        dispatch(setVirazhData(radarData.data));
      }
    }
  };

  webSocket.onerror = (ev) => {
    const { isSound } = getState().common.data;
    logger.error('On error currentstate:', ev);
    dispatch(setIsConnection(false));
    dispatch(setRadarStatus(RadarStatuses.OFF));
    if (!connected_error) {
      notification({
        type: 'error',
        message: intl.formatMessage({
          id: 'toast.not_connection',
        }),
        isSound,
      });
    }
    connected_error = true;
  };

  return webSocket;
};

export const connectWebSocket =
  (host: string, port: string, serial_number: string, intl: IntlShape) =>
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    services: ThunkExtraArgument,
  ) => {
    const { isSound } = getState().common.data;
    const { notification, logger } = services;

    if (!host) {
      notification({
        type: 'error',
        message: intl.formatMessage({
          id: 'toast.not_host',
        }),
        isSound,
      });

      return;
    }

    if (!serial_number) {
      notification({
        type: 'error',
        message: intl.formatMessage({
          id: 'toast.not_serial',
        }),
        isSound,
      });
      return;
    }

    const protocol = port ? 'ws' : 'wss';
    const p = port ? `:${port}` : '';
    const url = `${protocol}://${host}${p}`;

    try {
      let socket = reconnectWebSocket(url, dispatch, getState, intl, services);

      watchdog = setInterval(() => {
        if (socket.readyState === 3) {
          socket = reconnectWebSocket(url, dispatch, getState, intl, services);
        }
      }, 5500);
    } catch (e) {
      logger.error(e);
    }
  };

export const disconnectWebSocket =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { webSocket } = getState();

    if (watchdog) {
      clearInterval(watchdog);
    }

    if (
      webSocket.data.web_socket &&
      webSocket.data.web_socket.readyState === WebSocket.OPEN
    ) {
      webSocket.data.web_socket.close();
    }
    dispatch(setWebSocket(undefined));
  };
