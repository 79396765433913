import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

export interface MuiPaperProps {
  defaultProps?: ComponentsProps['MuiPaper'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiPaper'];
  variants?: ComponentsVariants['MuiPaper'];
}

const MuiPaper: MuiPaperProps = {
  defaultProps: {},
  styleOverrides: {
    root: () => ({
      borderRadius: 12,
      border: `1px solid rgba(255, 255, 255, 0.07)`,
      boxShadow: `0px 2px 22px rgba(0, 0, 0, 0.1)`,
    }),
  },
};

export default MuiPaper;
