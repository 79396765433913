import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

export const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: 15,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const LeftBar = styled(motion.div)(({ theme }) => ({
  minWidth: 650,
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    minWidth: '50%',
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  },
}));

// export const RightBar = styled(motion.div)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   paddingTop: 4,
//   [theme.breakpoints.down('md')]: {
//     marginTop: 17,
//   },
// }));
