import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { motion } from 'framer-motion';

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.1)',
  borderRadius: 12,
  width: '100%',
  height: '100%',
  padding: 15,
  display: 'flex',
  overflow: 'hidden',
  '&:before': {
    content: "''",
    display: 'block',
    marginTop: '100%',
  },

  [theme.breakpoints.down('sm')]: {
    padding: 8,
    flexShrink: 0,
  },
}));

export const Container = styled(Stack)({
  width: '100%',
  flexGrow: 1,
  position: 'relative',
});

export const RadarRadiusCircle = styled('circle')(({ theme }) => ({
  strokeWidth: 0.5,
  opacity: 0.2,
  fill: 'none',
  stroke: theme.palette.primary.light,

  '&:first-of-type': {
    strokeWidth: 2,
    opacity: 0.35,
  },
  '&:last-of-type': {
    strokeWidth: 2,
  },
}));

export const RadarStatusWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  [theme.breakpoints.down('xl')]: {
    gap: 10,
    top: 0,
    left: 0,
  },
  [theme.breakpoints.down('sm')]: {
    left: 0,
    top: 0,
  },
}));

export const RadarZoomControlsWrapper = styled('div')(() => ({
  position: 'absolute',
  zIndex: 1,
  left: -10,
  bottom: -10,
}));

export const RadarInfoWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  bottom: 0,
  right: 0,
  [theme.breakpoints.down('xl')]: {
    bottom: 0,
    right: 0,
  },
  [theme.breakpoints.down('sm')]: {
    bottom: 0,
    right: 0,
  },
}));

export const RadarRecordWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  bottom: -10,
  left: 80,
  [theme.breakpoints.up('xl')]: {
    left: 110,
  },
}));

export const RadarDirectionInfoWrapper = styled(motion.div)(() => ({
  position: 'absolute',
  zIndex: 1,
  right: 0,
}));
