import { styled } from '@mui/material';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '12px 12px 0 12px',
}));

export const RadarTargetWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 14,
  [theme.breakpoints.down('xl')]: {
    marginTop: 12,
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: 4,
  },
}));
