import { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { MotionProps } from 'framer-motion';
import { useAppSelector } from '../../../app';
import { selectCommonData } from '../../common';

function getInitialRightBar(isDownMd: boolean) {
  return {
    visible: {
      width: isDownMd ? '100%' : 'calc(47% - 15px)',
      marginLeft: isDownMd ? 0 : 15,
    },
    hide: { width: '0%', marginLeft: 0 },
  };
}

function getInitialLeftBar(isDownMd: boolean) {
  return {
    open: { width: '100%' },
    closed: { width: isDownMd ? '100%' : '53%' },
  };
}

function getInitialControls(isDownMd: boolean) {
  return {
    open: {
      top: 30,
      right: 30,
      width: 'calc(100% - 48px)',
      height: 60,
      paddingLeft: 100,
      transition: {
        animation: 'linear',
      },
    },
    closed: {
      top: 15,
      right: isDownMd ? 0 : 15,
      width: isDownMd ? '100%' : 'calc(46% - 15px)',
      height: 'auto',
      paddingLeft: 0,
      transition: {
        animation: 'linear',
      },
    },
  };
}

export default function useAnimation() {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { is_full_width } = useAppSelector(selectCommonData);
  const [rightBarVariants, setRightBarVariants] = useState(
    getInitialRightBar(isDownMd),
  );
  const [leftBarVariants, setLeftBarVariants] = useState(
    getInitialLeftBar(isDownMd),
  );
  const [controlsVariants, setControlsVariants] = useState(
    getInitialControls(isDownMd),
  );

  useEffect(() => {
    setRightBarVariants(getInitialRightBar(isDownMd));
    setLeftBarVariants(getInitialLeftBar(isDownMd));
    setControlsVariants(getInitialControls(isDownMd));
  }, [isDownMd]);

  const rightBarAnimationProps: MotionProps = {
    variants: rightBarVariants,
    animate: is_full_width ? 'hide' : 'visible',
    initial: is_full_width ? 'hide' : 'visible',
    transition: {
      type: 'linear',
    },
  };

  const leftBarAnimationProps: MotionProps = {
    variants: leftBarVariants,
    animate: is_full_width ? 'open' : 'closed',
    initial: is_full_width ? 'open' : 'closed',
    transition: {
      type: 'linear',
    },
  };

  const controlsAnimationProps: MotionProps = {
    variants: controlsVariants,
    animate: is_full_width ? 'open' : 'closed',
    initial: is_full_width ? 'open' : 'closed',
  };

  return {
    rightBarAnimationProps,
    leftBarAnimationProps,
    controlsAnimationProps,
    is_full_width,
  };
}
