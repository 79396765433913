import { styled } from '@mui/material';
import { Coords } from '..';

export interface RadarMapProps {
  containerSize: number;
  padding: number;
  zoomRatio: number;
  offsetPercent: Coords;
  w: number;
}

export interface RadarMapWrapper extends RadarMapProps {
  grid_angle: number;
}

export const RadarMapWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'grid_angle' &&
    prop !== 'containerSize' &&
    prop !== 'padding' &&
    prop !== 'zoomRatio' &&
    prop !== 'offsetPercent' &&
    prop !== 'is_full_width',
})<RadarMapWrapper & { is_full_width: boolean }>(
  ({
    grid_angle,
    containerSize,
    padding,
    zoomRatio,
    offsetPercent,
    is_full_width,
  }) => {
    const size = (containerSize - padding) * zoomRatio;

    return {
      position: 'absolute',
      top: is_full_width
        ? `calc(-50% + ${
            ((zoomRatio - 1) * containerSize) / 2 -
            (size / 100) * offsetPercent.y
          }px)`
        : (padding / 2) * zoomRatio - (size / 100) * offsetPercent.y,
      left: is_full_width
        ? `calc(-50% + ${
            ((zoomRatio - 1) * containerSize) / 2 -
            (size / 100) * offsetPercent.x
          }px)`
        : (padding / 2) * zoomRatio - (size / 100) * offsetPercent.x,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: is_full_width ? 'visible' : 'hidden',
      borderRadius: is_full_width ? 0 : '50%',
      backgroundColor: 'transparent',
      width: is_full_width ? '200%' : size,
      height: is_full_width ? '200%' : size,
      transform: `rotate(${grid_angle}deg)`,
      '&:before': {
        content: "''",
        position: 'absolute',
        top: '-4000px',
        left: '-4000px',
        right: '-4000px',
        bottom: '-4000px',
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
      },
      '& .leaflet-right': {
        display: 'none',
      },
    };
  },
);

export const RadarMapContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'containerSize' && prop !== 'is_full_width',
})<Pick<RadarMapProps, 'containerSize'> & { is_full_width: boolean }>(
  ({ containerSize, is_full_width }) => ({
    width: is_full_width ? 'calc(100% + 30px)' : containerSize,
    height: is_full_width ? 'calc(100% + 30px)' : containerSize,
    left: is_full_width ? -15 : '50%',
    top: is_full_width ? -15 : '50%',
    transform: is_full_width ? 'translate(0%, 0%) ' : 'translate(-50%, -50%)',
    overflow: is_full_width ? 'visible' : 'hidden',
    flexGrow: 1,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
  }),
);
