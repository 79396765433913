/* eslint-disable */
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function InfoOffIcon(props: SvgIconProps) {
  return (
    <SvgIcon height={29} {...props} viewBox="0 0 29 29" width={29}>
      <rect
        strokeWidth="0.5"
        strokeLinecap="round"
        x1="1.41421"
        x2="28"
        y1="1"
        y2="27.5858"
        fill="red"
      />
      <path
        clipRule="evenodd"
        d="M5.04747 4.04747C3.79712 4.26674 3 5.22931 3 6.5965C3 12.1968 3.00025 17.7999 3.00076 23.4058C3.00076 24.966 4.04187 26.0023 5.6085 26.0023H22.39C23.7715 26.0023 24.7321 25.2116 24.952 23.952L17.1982 16.1982H7.89924L7.90306 13.7995H14.7995L12.3183 11.3183H7.89924V8.90968H9.90968L5.04747 4.04747ZM15.8538 11.3183H20.0985V8.90968H13.4452L8.53553 4H13.9992H22.3632C23.9719 4 25 5.02333 25 6.62399V20.4645L20.1008 15.5652V13.7995H18.335L15.8538 11.3183ZM16.4328 21.0987H7.89924V18.6901H16.4328V21.0987Z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
