import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
  alpha,
} from '@mui/material';

export interface MuiTextFieldProps {
  defaultProps?: ComponentsProps['MuiTextField'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField'];
  variants?: ComponentsVariants['MuiTextField'];
}

const MuiTextField: MuiTextFieldProps = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiInputBase-root': {
        transition: theme.transitions.create('border-color'),
        width: '100%',
        height: 'auto',
        border: `1px solid ${alpha(theme.palette.primary.contrastText, 0.3)}`,
        borderRadius: 5,
        padding: '5px 13px',
        fontSize: 16,
        lineHeight: '18px',
        fontWeight: 400,
        color: theme.palette.primary.contrastText,
        marginTop: 0,
        [theme.breakpoints.down('xl')]: {
          fontSize: 13,
        },
        [theme.breakpoints.down('sm')]: {
          padding: '7px 13px',
        },
        '&.Mui-focused': {
          borderColor: theme.palette.primary.light,
        },
        '&.Mui-disabled': {
          color: alpha(theme.palette.primary.contrastText, 0.1),
          textFillColor: alpha(theme.palette.primary.contrastText, 0.1),
          border: `1px solid ${alpha(theme.palette.primary.contrastText, 0.1)}`,
        },
        '&.Mui-error': {
          borderColor: theme.palette.warning.main,
        },
        '&:placeholder': {
          fontSize: 16,
          lineHeight: '18px',
          color: theme.palette.primary.main,
          [theme.breakpoints.down('xl')]: {
            fontSize: 13,
          },
        },
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          appearance: 'none',
          margin: 0,
        },
        'input[type=number]': {
          appearance: 'textfield',
        },
        'input[type=date]': {
          appearance: 'none',
        },
        '& input': {
          padding: 0,
          '&:-webkit-autofill': {
            boxShadow: `0 0 0 100px ${theme.palette.primary.light} inset`,
            textFillColor: theme.palette.text.primary,
          },
        },
        '& fieldset': {
          border: 'none',
        },
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },

      '& .MuiInputAdornment-positionEnd': {
        right: 13,
        color: theme.palette.secondary.main,
        '& .MuiButtonBase-root': {
          color: 'inherit',
        },
      },

      '& .MuiAutocomplete-endAdornment': {
        right: 8,
        '& .MuiSvgIcon-root': {
          width: 10,
        },
      },
      '& .MuiFormHelperText-root': {
        marginTop: 6,
        fontSize: 13,
        lineHeight: '14px',
        '&.Mui-error': {
          color: theme.palette.error.main,
        },
      },
    }),
  },
};

export default MuiTextField;
