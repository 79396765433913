import { memo, useState } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import translate from '../../../translations/translate';
import { StatusIndicator, StatusProps } from './radar-status.styled';
import { MenuStyled } from '../../settings/components/settings-system-list.styled';
import RadarStatusDetails from './radar-status-details';

export interface RadarStatusProps extends StatusProps {
  serial_number: string;
}

function RadarStatus({
  serial_number,
  status,
  is_connected,
}: RadarStatusProps) {
  const labelText = translate('radar.radar');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack alignItems="center" direction="row" gap={1}>
      <IconButton onClick={handleClick} size="small" sx={{ p: 0 }}>
        <StatusIndicator is_connected={is_connected} status={status} />
      </IconButton>
      <MenuStyled
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={0}
        id="radar-info-menu"
        MenuListProps={{
          'aria-labelledby': 'radar-info-button',
        }}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <RadarStatusDetails is_connected={is_connected} status={status} />
      </MenuStyled>
      <Typography color="primary.main" variant="h6">
        {labelText}: {serial_number}
      </Typography>
    </Stack>
  );
}

export default memo(RadarStatus);
