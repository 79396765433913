import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { getIntroPath } from '../routing/app-router-paths';

export interface PrivateRouteProps {
  children: ReactElement;
}

export default function PrivateRoute({ children }: PrivateRouteProps) {
  const isAuthenticated = false;

  return isAuthenticated ? (
    children
  ) : (
    <Navigate state={{ from: window.location.href }} to={getIntroPath()} />
  );
}
