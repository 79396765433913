/**
 * Capitalizes the first letter of a string and trims any leading or trailing whitespace.
 * If the input string is null or undefined, it returns an empty string.
 *
 * @param string - The input string that needs to be capitalized.
 * @returns The input string with the first letter capitalized.
 *
 * @example
 * const result = capitalizeFirstLetter('hello world');
 * console.log(result); // Output: 'Hello world'
 */
export default function capitalizeFirstLetter(string: string) {
  if (string === null || string === undefined) {
    return '';
  }

  const trimmedString = string.trim();
  const firstChar = trimmedString.charAt(0).toUpperCase();
  const restOfString = trimmedString.slice(1);

  return firstChar + restOfString;
}
