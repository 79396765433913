import { styled } from '@mui/material';

export const RadarIconWrapper = styled('div')(({ theme }) => ({
  marginBottom: 13,
  marginLeft: 'auto',

  [theme.breakpoints.down('xl')]: {
    marginBottom: 10,
  },
}));
