import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
  alpha,
} from '@mui/material';

export interface MuiAutocompleteProps {
  defaultProps?: ComponentsProps['MuiAutocomplete'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAutocomplete'];
  variants?: ComponentsVariants['MuiAutocomplete'];
}

const MuiAutocomplete: MuiAutocompleteProps = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: 200,
      '& .MuiInputBase-root': {
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.contrastText,
        },
        '.Mui-disabled': {
          '& .MuiSvgIcon-root': {
            color: alpha(theme.palette.primary.contrastText, 0.1),
          },
          color: alpha(theme.palette.primary.contrastText, 0.1),
          textFillColor: alpha(theme.palette.primary.contrastText, 0.1),
        },
      },
      '& .MuiIconButton-root': {
        '&.Mui-disabled': {
          border: 'none',
          backgroundColor: 'transparent',
        },
      },
    }),
    paper: ({ theme }) => ({
      '& .MuiAutocomplete-listbox': {
        padding: '0',
        '.MuiAutocomplete-option': {
          color: theme.palette.primary.contrastText,
          padding: '10px 17px',
          alignItems: 'center',
          fontSize: 16,
          fontWeight: 400,
          [theme.breakpoints.down('xl')]: {
            padding: '7px 13px',
            fontSize: 12,
            lineHeight: '14px',
          },
        },
        '.MuiAutocomplete-option[aria-selected="true"]': {
          backgroundColor: 'rgba(46, 204, 113, 0.1)',
          color: theme.palette.primary.light,
        },
        /* eslint-disable max-len */
        '.MuiAutocomplete-option[aria-selected="true"].Mui-focused, .MuiAutocomplete-option.Mui-focused':
          {
            backgroundColor: 'rgba(46, 204, 113, 0.1)',
          },
      },
    }),
    endAdornment: {
      '& .MuiAutocomplete-popupIndicator': {
        transition: '0.3s',
      },
    },
  },
};

export default MuiAutocomplete;
