import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import palette from './palette';
import breakpoints from './breakpoints';
import fonts from './fonts';
import {
  MuiButton,
  MuiDatePicker,
  MuiClockPicker,
  MuiCalendarPicker,
  MuiAutocomplete,
  MuiTextField,
  MuiInputLabel,
  MuiSwitch,
  MuiFormControlLabel,
  MuiDataGrid,
  MuiPaper,
  MuiFab,
  MuiTypography,
  MuiIconButton,
} from './components';

const theme = createTheme({
  palette,
  breakpoints,
  typography: {
    fontFamily: 'Ubuntu, sans-serif',
  },
  components: {
    MuiButton,
    MuiDatePicker,
    MuiClockPicker,
    MuiCalendarPicker,
    MuiAutocomplete,
    MuiTextField,
    MuiInputLabel,
    MuiSwitch,
    MuiFormControlLabel,
    MuiDataGrid,
    MuiPaper,
    MuiFab,
    MuiTypography,
    MuiIconButton,
    ...fonts,
  },
});

export default theme;
