import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { DialogProps } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import { useForm, useWatch } from 'react-hook-form';
import {
  excludeNaNFields,
  ConfirmModal,
  DraggablePaper,
  useAppDispatch,
  useAppSelector,
} from '../../../app';
import { setSettingsData } from '../slice';
import {
  RadarSettingsModalHeader,
  DialogRootStyled,
} from './radar-settings-modal.styled';
import RadarStatus from './radar-status';
import RadarSwitcher from './radar-switcher';
import { SettingsRadarForm, validationSchema } from '../schema';
import {
  PositionFields,
  RadarDataField,
  RadarSettingsData,
  RadarStatuses,
  ScanRange,
} from '../types';
import { selectWebSocketData } from '../../socket/selectors';
import { selectRadarSettingsData } from '../selectors';
import RadarSettingsForm from './radar-settings-form';
import {
  LATITUDE_MAX,
  LATITUDE_MIN,
  LONGITUDE_MAX,
  LONGITUDE_MIN,
} from '../constants';
import useClipboard from '../hooks/use-clipboard';
import {
  MethodFields,
  ModuleFields,
  SocketDataFields,
} from '../../socket/types';

export type TemplateActionModalProps = DialogProps & RadarSettingsData;

export default function RadarSettingsModal({
  onClose,
  open,
  ...otherProps
}: TemplateActionModalProps) {
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const socketData = useAppSelector(selectWebSocketData);
  const { serial_number, is_connected, web_socket } = socketData;
  const {
    instrumental_range,
    scan_type,
    latitude,
    longitude,
    altitude,
    direction,
    grid_angle,
    grid_range,
    map,
    position,
    elevation,
    gps_auto_update,
  } = otherProps;
  const { gps_error, status } = useAppSelector(selectRadarSettingsData);

  const disabled =
    !socketData[SocketDataFields.IS_CONNECTED] ||
    status === RadarStatuses.DISCONNECTED;

  const dispatch = useAppDispatch();

  const intl = useIntl();

  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    formState: { isDirty, errors },
    reset,
  } = useForm<SettingsRadarForm>({
    defaultValues: {
      altitude,
      direction,
      grid_angle,
      latitude,
      longitude,
      grid_range,
      map,
      position: position === PositionFields.GPS,
      elevation,
      gps_auto_update,
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (formData: SettingsRadarForm) => {
    const {
      map: mapData,
      position: positionData,
      grid_angle: gridAngleData,
      grid_range: gridRangeData,
      gps_auto_update: gpsAutoUpdate,
      ...otherFormData
    } = formData;

    const sendSettingsData = positionData ? {} : otherFormData;

    const newPosition = positionData
      ? PositionFields.GPS
      : PositionFields.MANUAL;

    const isInstrumental =
      scan_type === ScanRange.INSTRUMENTAL ||
      gridRangeData > instrumental_range;

    dispatch(
      setSettingsData({
        ...formData,
        position: newPosition,
        scan_range: isInstrumental ? instrumental_range : gridRangeData,
      }),
    );

    if (web_socket instanceof WebSocket) {
      web_socket?.send(
        JSON.stringify({
          serial_number,
          module: ModuleFields.settings,
          method: MethodFields.radar,
          data: {
            position: newPosition,
            scan_range: isInstrumental ? instrumental_range : gridRangeData,
            ...sendSettingsData,
          },
        }),
      );
      web_socket?.send(
        JSON.stringify({
          serial_number,
          module: ModuleFields.settings,
          method: MethodFields.grid,
          data: {
            grid_angle: gridAngleData,
            grid_range: gridRangeData,
            map: mapData,
          },
        }),
      );
      if (positionData) {
        web_socket.send(
          JSON.stringify({
            serial_number,
            module: ModuleFields.gps,
            method: MethodFields.auto_update,
            status: gpsAutoUpdate ? 'on' : 'off',
          }),
        );
      }
    }
    onClose?.({}, 'backdropClick');
  };

  const data = useWatch({
    control,
  });

  const positionStatus = data.position
    ? PositionFields.GPS
    : PositionFields.MANUAL;

  const { checkClipBoard, clipboardCoords, isOpen, handleClose } = useClipboard(
    {
      latitude,
      longitude,
      disabled: data.position || disabled,
    },
  );

  useEffect(() => {
    checkClipBoard();
  }, [checkClipBoard]);

  const isAutoUpdate = data.gps_auto_update;

  const handleCancellation = () => {
    if (isDirty) {
      setIsLeaveModalOpen(true);
    } else {
      onClose?.({}, 'backdropClick');
    }
  };

  const canceledChanges = useCallback(() => {
    reset();
    setIsLeaveModalOpen(false);
    onClose?.({}, 'backdropClick');
  }, [reset, onClose]);

  const handleRefresh = useCallback(() => {
    if (web_socket) {
      web_socket.send(
        JSON.stringify({
          serial_number,
          module: ModuleFields.gps,
          method: 'update',
        }),
      );
    }
  }, [web_socket, serial_number]);

  useEffect(() => {
    const formatLatitude = _.toNumber(_.toString(data.latitude).slice(0, 11));
    const formatLongitude = _.toNumber(_.toString(data.longitude).slice(0, 11));

    const cords: Partial<Record<RadarDataField, number>> = {};

    if (formatLatitude >= LATITUDE_MIN && formatLatitude <= LATITUDE_MAX) {
      cords[RadarDataField.LATITUDE] = formatLatitude;
    }
    if (formatLongitude >= LONGITUDE_MIN && formatLongitude <= LONGITUDE_MAX) {
      cords[RadarDataField.LONGITUDE] = formatLongitude;
    }

    const { position: positionData, ...otherData } = data;

    dispatch(
      setSettingsData({
        ...excludeNaNFields(otherData),
        [RadarDataField.POSITION]: positionData
          ? PositionFields.GPS
          : PositionFields.MANUAL,
        ...cords,
      }),
    );
  }, [data, dispatch, errors]);

  return (
    <DialogRootStyled
      hideBackdrop
      keepMounted
      onClose={handleCancellation}
      open={open}
      PaperComponent={DraggablePaper}
    >
      <div id="draggable-dialog">
        <RadarSettingsModalHeader>
          <RadarStatus
            is_connected={is_connected}
            serial_number={serial_number}
            status={status}
          />
          <RadarSwitcher socketData={socketData} status={status} />
        </RadarSettingsModalHeader>
        <RadarSettingsForm
          control={control}
          disabled={disabled}
          errors={errors}
          gps_error={gps_error}
          handleCancellation={handleCancellation}
          handleRefresh={handleRefresh}
          isAutoUpdate={!!isAutoUpdate}
          isGps={!!data.position}
          onSubmit={handleSubmit(onSubmit)}
          positionStatus={positionStatus}
          register={register}
          setValue={setValue}
          watch={watch}
        />
      </div>

      <ConfirmModal
        onClose={() => setIsLeaveModalOpen(false)}
        onConfirm={() => {
          canceledChanges();
        }}
        open={isLeaveModalOpen}
        text={intl.formatMessage({
          id: 'leave.question',
        })}
        title={intl.formatMessage({
          id: 'leave.title',
        })}
      />

      <ConfirmModal
        onClose={handleClose}
        onConfirm={() => {
          const [lat, long] = clipboardCoords.split(', ');
          setValue(RadarDataField.LATITUDE, Number(lat) as unknown as number);
          setValue(RadarDataField.LONGITUDE, Number(long) as unknown as number);
          handleClose();
        }}
        open={isOpen}
        text={intl.formatMessage({
          id: 'leave.clip.question',
        })}
        title={clipboardCoords}
      />
    </DialogRootStyled>
  );
}
