import { styled } from '@mui/material/styles';
import { motion, MotionProps } from 'framer-motion';

const BAR_HEIGHT = 50;

export const DraggableWrapperStyled = styled(motion.div)<MotionProps>(
  ({ theme, drag }) => ({
    overflow: 'hidden',
    borderRadius: drag ? 0 : 12,
    zIndex: 2,
    border: '1px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.1)',
    width: 'calc(47% - 15px)',
    marginLeft: '15px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      height: 400,
      width: '100%',
    },
  }),
);

export const DraggableContainer = styled(motion.div)<{ isDraggable: boolean }>(
  ({ theme, isDraggable }) => ({
    height: isDraggable ? `calc(100% - ${BAR_HEIGHT}px)` : '100%',
    overflow: 'auto',
    [theme.breakpoints.down('xl')]: {},
    [theme.breakpoints.down('md')]: {},
  }),
);

export const DraggableBar = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.paper,
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '1rem 10px',
  gap: 10,
  cursor: 'grab',
  height: BAR_HEIGHT,
  [theme.breakpoints.down('xl')]: {},
  [theme.breakpoints.down('md')]: {},
}));
