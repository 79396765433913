import { Theme } from '@mui/material';
import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation';

const MuiClockPicker: PickerComponents<Theme>['MuiClockPicker'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.secondary.dark,
      '& .MuiClockPointer-root': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiClockPointer-thumb': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '& .MuiClock-pin': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiClockNumber-root': {
        color: theme.palette.primary.light,
      },
      '& .MuiButtonBase-root .MuiSvgIcon-root': {
        color: theme.palette.primary.light,
      },
      '& .MuiButtonBase-root.Mui-disabled': {
        opacity: 0.3,
      },
      '& .MuiClock-pmButton': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiClock-amButton': {
        color: theme.palette.primary.contrastText,
      },
    }),
  },
};

export default MuiClockPicker;
