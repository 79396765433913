import { Button, Theme, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RadarStatuses } from '../types';

const getStylesByStatus = (
  status: RadarStatuses,
  disabled: boolean | undefined,
  theme: Theme,
) => {
  if (disabled) {
    return {
      borderColor: alpha(theme.palette.primary.contrastText, 0.3),
      backgroundColor: alpha(theme.palette.primary.contrastText, 0.1),
      boxShadow: `0px 0px 10px 0px ${alpha(
        theme.palette.primary.contrastText,
        0.1,
      )}`,
    };
  }

  const borderColors = {
    [RadarStatuses.ON]: theme.palette.primary.light,
    [RadarStatuses.OFF]: theme.palette.warning.main,
    [RadarStatuses.DISCONNECTED]: alpha(
      theme.palette.primary.contrastText,
      0.3,
    ),
  };
  const bgColors = {
    [RadarStatuses.ON]: 'rgba(46, 204, 113, 0.2)',
    [RadarStatuses.OFF]: 'rgba(255, 46, 0, 0.2)',
    [RadarStatuses.DISCONNECTED]: alpha(
      theme.palette.primary.contrastText,
      0.1,
    ),
  };

  const boxShadow = {
    [RadarStatuses.ON]: alpha(theme.palette.primary.light, 0.25),
    [RadarStatuses.OFF]: alpha(theme.palette.warning.main, 0.25),
    [RadarStatuses.DISCONNECTED]: alpha(
      theme.palette.primary.contrastText,
      0.1,
    ),
  };

  return {
    borderColor: borderColors[status],
    backgroundColor: bgColors[status],
    boxShadow: `0px 0px 10px 0px ${boxShadow[status]}`,
  };
};

export const Root = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ status: RadarStatuses }>(({ theme, disabled, status }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  ...getStylesByStatus(status, disabled, theme),
  strokeWidth: 1,
  filter: 'drop-shadow(0px 0px 10px rgba(46, 204, 113, 0.25))',
  cursor: 'pointer',
  width: 105,
  padding: '8px 12px',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: 12,
  transition: '0.3s',

  '&.Mui-disabled': {
    '& span': {
      color: alpha(theme.palette.primary.contrastText, 0.3),
    },
  },

  [theme.breakpoints.down('xl')]: {
    width: 70,
    padding: '5px 7px',
  },
}));

export const RadarSwitcherOn = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  color: active
    ? theme.palette.primary.light
    : theme.palette.primary.contrastText,
  fontWeight: 500,
  fontSize: 16,
  userSelect: 'none',
  cursor: 'pointer',
  transition: '0.3s',
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const RadarSwitcherOff = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  color: active
    ? theme.palette.warning.main
    : theme.palette.primary.contrastText,
  fontWeight: 500,
  fontSize: 16,
  userSelect: 'none',
  cursor: 'pointer',
  transition: '0.3s',

  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));
