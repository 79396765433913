import {
  DialogContentText,
  DialogContentTextProps,
  DialogTitle,
  DialogTitleProps,
  styled,
} from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

export const DialogRoot = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiBackdrop-root': {
    background: 'rgba(34, 31, 31, 0.8)',
  },
  '& .MuiDialog-container': {
    alignItems: 'center',
  },
  '& .MuiPaper-root': {
    width: '100%',
    margin: 0,
    padding: '17px 30px 23px',
    borderRadius: 12,
    backgroundColor: theme.palette.background.default,
    border: '1px solid rgba(255, 255, 255, 0.07)',
    boxShadow: `0px 2px 22px rgba(0, 0, 0, 0.1)`,
    [theme.breakpoints.down('xl')]: {
      padding: '15px 20px',
    },
  },

  '& .MuiDialogContent-root': {
    marginBottom: 32,
    padding: 0,
    overflowY: 'unset',
    [theme.breakpoints.down('xl')]: {},
  },
  '& .MuiDialogActions-root': {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    '& > button': {
      width: '100%',
      minHeight: 56,
      marginLeft: 0,
      textTransform: 'none',
      boxShadow: 'none',
    },
    '& > button:last-child': {
      marginTop: 8,
    },
  },
}));

export const Title = styled(DialogTitle)<DialogTitleProps>(({ theme }) => ({
  marginBottom: 24,
  padding: 0,
  fontWeight: 700,
  fontSize: '28px',
  lineHeight: '110%',
  letterSpacing: '-1px',
  color: theme.palette.primary.main,
  [theme.breakpoints.down('xl')]: {
    marginBottom: 16,
    fontSize: 22,
  },
}));

export const Text = styled(DialogContentText)<DialogContentTextProps>(
  ({ theme }) => ({
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
    letterSpacing: -0.2,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('xl')]: {
      fontSize: 16,
      lineHeight: '21px',
    },
  }),
);

export const ActionsWrapper = styled('div')({
  display: 'flex',
  gap: '10px',
  justifyContent: 'flex-end',
});
