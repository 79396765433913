import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function SelectArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon height={6} {...props} viewBox="0 0 10 6" width={10}>
      <path
        d="M5.17678 5.82322L9.63431 1.36569C10.1383
        0.861713 9.78135 9.20053e-08 9.06863 1.45814e-07L0.93137
        7.6016e-07C0.218646 8.1397e-07 -0.138287 0.861715 0.365685 
        1.36569L4.82322 5.82322C4.92085 5.92085 5.07915 5.92085 5.17678 5.82322Z"
      />
    </SvgIcon>
  );
}
