import { alpha, styled } from '@mui/material/styles';

export const RadarRadiusCircle = styled('circle')(({ theme }) => ({
  strokeWidth: 0.5,
  opacity: 0.4,
  fill: 'none',
  stroke: theme.palette.primary.main,
  '&:last-of-type': {
    strokeWidth: 2,
  },
}));

export const RadarDeadZoneCircle = styled('circle')(({ theme }) => ({
  strokeWidth: 1,
  fill: alpha(theme.palette.primary.main, 0.1),
  stroke: theme.palette.primary.main,
}));
