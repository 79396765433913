import { SvgIcon, SvgIconProps } from '@mui/material';

export default function UavTacticalIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="currentColor"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
  M 40.14 8.28
  C 34.95 3.81 29.45 3.66 23.72 8.32
  Q 23.12 8.80 22.85 8.08
  L 22.74 7.78
  A 1.13 1.13 0.0 0 1 23.11 6.49
  Q 32.06 -0.47 40.92 6.54
  Q 41.61 7.09 41.18 7.86
  L 41.03 8.12
  A 0.58 0.58 0.0 0 1 40.14 8.28
  Z"
        />
        <path
          d="
  M 36.16 9.17
  Q 36.97 9.56 37.22 10.11
  Q 38.57 13.07 35.86 11.23
  Q 31.57 8.34 27.38 11.81
  Q 26.85 12.25 26.52 11.64
  L 26.38 11.37
  Q 26.03 10.71 26.59 10.22
  Q 30.74 6.55 36.16 9.17
  Z"
        />
        <path
          d="
  M 29.99 15.22
  A 0.26 0.26 0.0 0 1 29.74 14.96
  L 29.74 14.80
  A 2.04 1.70 0.3 0 1 31.79 13.11
  L 32.23 13.11
  A 2.04 1.70 0.3 0 1 34.26 14.82
  L 34.26 14.98
  A 0.26 0.26 0.0 0 1 33.99 15.24
  L 29.99 15.22
  Z"
        />
        <path
          d="
  M 64.00 47.08
  L 64.00 55.90
  Q 60.70 57.25 55.83 55.72
  Q 55.15 55.50 54.90 56.17
  Q 54.74 56.60 54.35 56.84
  Q 53.68 57.27 53.42 56.51
  L 53.23 55.94
  Q 53.02 55.35 52.43 55.14
  Q 51.58 54.85 50.97 54.23
  A 1.41 1.40 -28.7 0 0 49.65 53.85
  L 48.17 54.20
  Q 47.67 54.31 47.15 54.22
  Q 42.98 53.47 38.77 52.79
  C 35.39 52.24 35.98 55.45 33.34 55.97
  Q 32.91 56.06 32.91 56.50
  L 32.91 57.68
  Q 32.91 58.19 33.43 58.19
  L 38.32 58.22
  A 0.57 0.56 -81.1 0 1 38.85 58.95
  L 38.71 59.37
  Q 38.52 59.99 37.87 59.99
  L 33.52 60.00
  Q 33.00 60.00 32.68 60.40
  L 32.46 60.66
  Q 32.06 61.15 31.61 60.70
  L 31.36 60.46
  Q 30.92 60.03 30.30 60.02
  L 25.72 59.95
  A 0.43 0.42 78.4 0 1 25.33 59.68
  L 25.17 59.25
  A 0.77 0.77 0.0 0 1 25.89 58.21
  L 30.43 58.21
  Q 31.06 58.21 31.11 57.57
  L 31.16 56.79
  Q 31.21 56.12 30.55 55.98
  C 27.99 55.42 28.62 52.18 25.10 52.80
  Q 18.17 54.00 11.61 55.10
  A 1.04 1.01 10.7 0 0 10.92 55.56
  Q 10.69 55.95 10.63 56.40
  A 0.65 0.65 0.0 0 1 9.55 56.80
  Q 9.23 56.51 9.07 56.12
  A 0.72 0.70 73.5 0 0 8.28 55.68
  Q 5.51 56.17 2.76 56.70
  Q 0.89 57.07 0.00 55.35
  L 0.00 47.02
  L 25.78 28.43
  Q 26.94 27.59 27.02 26.17
  Q 27.10 24.74 27.07 23.27
  C 26.94 16.17 37.63 16.42 36.94 24.09
  C 36.77 25.99 36.94 27.69 38.68 28.85
  Q 52.00 37.76 64.00 47.08
  Z"
        />
      </svg>
    </SvgIcon>
  );
}
