import { memo } from 'react';
import { RadarAxesLine } from './radar-degree-axes.styled';
import { RADAR_IMAGE_PROPERTIES } from '../constants';
import { useAppSelector } from '../../../app/store/store';
import { selectRadarSettingsData } from '../selectors';
import { selectCommonData } from '../../common';

function RadarDegreeAxes() {
  const { radarCenter, radarPadding, radarSize } = RADAR_IMAGE_PROPERTIES;
  const { grid_angle = 0 } = useAppSelector(selectRadarSettingsData);
  const { is_full_width } = useAppSelector(selectCommonData);

  return (
    <g>
      {[...Array(18)].map((_, index) => (
        <g
          key={index}
          transform={`rotate(${
            index * 10 + (is_full_width ? 0 : grid_angle)
          }  ${radarCenter} ${radarCenter})`}
        >
          <RadarAxesLine
            x1={radarCenter}
            x2={radarCenter}
            y1={radarPadding}
            y2={`${radarSize - radarPadding}`}
          />
        </g>
      ))}
    </g>
  );
}

export default memo(RadarDegreeAxes);
