import { alpha, styled, Tab, Tabs, Theme } from '@mui/material';
import { DialogRoot } from '../../../app/components/modal.styled';

export const SettingsModalRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const SettingsModalDialog = styled(DialogRoot)(({ theme }) => ({
  '& .MuiDialog-container': {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
    },
  },
  '& .MuiBackdrop-root': {
    background: 'rgba(34, 31, 31, 0.8)',
  },
  '& .MuiPaper-root': {
    padding: '0',
    [theme.breakpoints.down('xl')]: {
      padding: '0',
    },
  },
  '& .MuiDialog-paper': {
    width: 1000,
    maxWidth: '80%',
    height: 840,
    maxHeight: 'calc(90vh - var(--vh-offset, 0px))',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));

export const SettingsTabs = styled(Tabs)(({ theme }) => ({
  borderRight: `1px solid ${alpha(theme.palette.primary.contrastText, 0.1)}`,
  height: '100%',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    flexShrink: 0,
  },
  '& .MuiTabs-scroller': {
    '& .MuiTabs-indicator ': {
      width: '100%',
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
    },
  },
}));

export interface CustomTabProps {
  isValid?: boolean;
}

const getColor = (theme: Theme, isValid?: boolean) => {
  switch (isValid) {
    case false: {
      return theme.palette.warning.main;
    }
    case true: {
      return theme.palette.primary.light;
    }

    default: {
      return 'transparent';
    }
  }
};

export const SettingsTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'isValid',
})<CustomTabProps>(({ theme, isValid }) => ({
  padding: '20px 28px',
  width: 320,
  justifyContent: 'flex-start',
  textAlign: 'left',
  color: theme.palette.primary.contrastText,
  borderBottom: `1px solid ${alpha(theme.palette.primary.contrastText, 0.1)}`,
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    minHeight: 50,
    padding: '15px 20px',
    width: 'calc(100% / 3)',
  },
  '&.Mui-selected': {
    color: theme.palette.primary.light,
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.light,
    },
  },

  '&:before': {
    content: "''",
    width: 7,
    height: 7,
    position: 'absolute',
    borderRadius: '50%',
    right: 120,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: `${getColor(theme, isValid)}`,
    [theme.breakpoints.down('md')]: {
      right: 20,
    },
  },
}));
