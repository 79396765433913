import { SvgIcon, SvgIconProps } from '@mui/material';

export default function UavImpactIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="currentColor"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
  M 51.39 0.00
  L 54.23 0.00
  Q 62.13 1.77 64.00 9.64
  L 64.00 13.22
  L 63.08 15.71
  A 0.89 0.89 0.0 0 1 61.94 16.23
  L 61.52 16.07
  A 0.66 0.65 20.5 0 1 61.13 15.23
  Q 62.57 11.33 61.29 8.24
  Q 57.98 0.27 48.56 2.87
  A 0.53 0.53 0.0 0 1 47.93 2.53
  L 47.79 2.12
  A 0.99 0.99 0.0 0 1 48.45 0.86
  L 51.39 0.00
  Z"
        />
        <path
          d="
  M 59.89 64.00
  L 59.12 64.00
  L 38.85 45.64
  Q 38.38 45.21 37.92 45.66
  L 35.77 47.76
  Q 34.88 48.63 34.00 47.74
  L 32.73 46.47
  Q 31.95 45.69 32.73 44.91
  L 34.71 42.92
  Q 35.28 42.35 34.68 41.80
  L 30.28 37.83
  Q 29.74 37.34 29.22 37.86
  L 22.55 44.53
  A 0.94 0.94 0.0 0 0 22.32 45.49
  L 25.56 55.19
  Q 25.92 56.26 25.08 57.00
  L 23.88 58.08
  Q 22.87 58.99 22.29 57.76
  L 18.00 48.55
  A 5.04 5.02 89.8 0 0 15.55 46.11
  L 6.44 41.85
  Q 5.07 41.21 6.10 40.10
  L 6.95 39.18
  Q 7.87 38.20 9.15 38.62
  L 18.69 41.81
  Q 19.18 41.97 19.54 41.61
  L 26.20 34.94
  Q 26.77 34.36 26.23 33.76
  L 22.18 29.31
  Q 21.75 28.84 21.30 29.29
  L 19.31 31.29
  Q 18.41 32.19 17.52 31.28
  L 16.25 29.98
  Q 15.49 29.21 16.25 28.44
  L 18.57 26.08
  Q 18.94 25.70 18.58 25.30
  L 0.00 4.71
  L 0.00 4.27
  L 2.68 1.56
  A 1.17 1.16 42.4 0 1 4.27 1.49
  L 24.84 19.12
  Q 25.27 19.49 25.64 19.06
  C 27.25 17.24 29.61 15.11 32.12 14.96
  A 0.56 0.56 0.0 0 1 32.70 15.61
  C 32.21 18.34 30.65 19.87 28.74 21.84
  Q 28.42 22.18 28.77 22.48
  L 33.09 26.20
  Q 33.49 26.54 33.81 26.12
  Q 37.60 21.15 41.88 14.95
  C 44.46 11.21 48.39 9.88 52.80 10.43
  A 0.96 0.95 0.1 0 1 53.62 11.24
  C 54.48 17.52 51.80 20.61 46.67 24.02
  Q 42.05 27.10 37.75 30.51
  Q 37.59 30.64 37.72 30.79
  L 41.73 35.48
  A 0.32 0.32 0.0 0 0 42.21 35.48
  Q 45.50 31.70 48.60 31.42
  Q 49.23 31.36 49.15 31.98
  C 48.82 34.47 47.13 36.63 45.19 38.26
  Q 44.57 38.78 45.10 39.40
  L 62.68 59.90
  A 1.04 1.04 0.0 0 1 62.62 61.31
  L 59.89 64.00
  Z"
        />
        <path
          d="
  M 53.47 6.90
  C 52.67 6.67 51.59 6.99 50.78 7.17
  Q 50.22 7.30 49.81 6.89
  Q 49.55 6.63 49.52 6.25
  A 0.98 0.97 76.6 0 1 50.11 5.28
  C 55.69 2.91 60.24 7.82 58.99 13.51
  A 1.27 1.25 -7.3 0 1 58.32 14.36
  Q 57.68 14.67 57.10 14.22
  A 0.73 0.72 26.6 0 1 56.84 13.45
  Q 58.29 8.29 53.47 6.90
  Z"
        />
      </svg>
    </SvgIcon>
  );
}
