import { SvgIcon, SvgIconProps } from '@mui/material';

export default function GuidedBombIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="currentColor"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
  M 47.81 0.00
  L 48.97 0.00
  Q 50.74 0.97 50.08 2.97
  Q 49.92 3.46 50.28 3.82
  L 52.15 5.66
  Q 52.54 6.04 52.94 5.68
  Q 53.48 5.19 54.22 5.09
  Q 58.54 4.51 59.00 8.63
  Q 59.16 10.01 58.27 11.09
  Q 57.95 11.48 58.31 11.84
  L 60.18 13.70
  Q 60.55 14.06 61.04 13.91
  Q 63.12 13.25 64.00 15.12
  L 64.00 16.27
  Q 59.91 20.51 55.67 24.70
  Q 53.73 26.62 49.97 25.91
  A 1.32 1.31 -56.0 0 0 48.97 26.11
  C 45.77 28.21 45.03 31.57 44.11 34.94
  Q 43.63 36.70 41.85 38.49
  Q 30.38 50.03 19.16 61.16
  Q 17.12 63.18 12.85 64.00
  L 10.26 64.00
  Q 1.66 62.31 0.00 53.62
  L 0.00 51.01
  Q 0.86 46.84 4.72 42.97
  Q 15.70 31.93 25.57 22.08
  Q 27.31 20.35 29.12 19.90
  C 32.51 19.05 35.59 18.24 37.84 15.19
  Q 38.15 14.77 38.08 14.26
  C 37.73 11.72 37.73 10.08 39.66 8.04
  Q 43.63 3.85 47.81 0.00
  Z"
        />
      </svg>
    </SvgIcon>
  );
}
