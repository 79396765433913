/* eslint-disable */
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function SuccessIcon(props: SvgIconProps) {
  return (
    <SvgIcon height={24} {...props} viewBox="0 0 24 24" width={24}>
      <circle cx="12" cy="12" fill="#2ECC71" r="12" />
      <path
        d="M9.84753 14.6457L16.1973 8.29596C16.3946 8.09865 16.6457 8 16.9507 8C17.2556 8 17.5067 8.09865 17.704 8.29596C17.9013 8.49327 18 8.74439 18 9.04933C18 9.35426 17.9013 9.60538 17.704 9.80269L10.6009 16.9058C10.3856 17.1211 10.1345 17.2287 9.84753 17.2287C9.56054 17.2287 9.30942 17.1211 9.09417 16.9058L6.29596 14.1076C6.09865 13.9103 6 13.6592 6 13.3543C6 13.0493 6.09865 12.7982 6.29596 12.6009C6.49327 12.4036 6.74439 12.3049 7.04933 12.3049C7.35426 12.3049 7.60538 12.4036 7.80269 12.6009L9.84753 14.6457Z"
        fill="white"
      />
    </SvgIcon>
  );
}
