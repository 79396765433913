import { uk as ua, enUS } from 'date-fns/locale';

export enum LanguageField {
  uk = 'uk',
  en = 'en',
}

export enum CommonDataField {
  IS_FULL_WIDTH = 'is_full_width',
  LANGUAGE = 'language',
  FORMAT_DATE = 'formatDate',
  FORMAT_TIME = 'formatTime',
  IS_SOUND = 'isSound',
  ONLINE_MAP = 'online_map',
  OFFLINE_MAP = 'offline_map',
}

export const locales = {
  [LanguageField.en]: enUS,
  [LanguageField.uk]: ua,
};

export interface CommonData {
  [CommonDataField.IS_FULL_WIDTH]: boolean;
  [CommonDataField.LANGUAGE]: LanguageField;
  [CommonDataField.FORMAT_DATE]: string;
  [CommonDataField.FORMAT_TIME]: string;
  [CommonDataField.IS_SOUND]: boolean;
  [CommonDataField.ONLINE_MAP]: string;
  [CommonDataField.OFFLINE_MAP]: string;
}

export interface CommonSliceState {
  data: CommonData;
}
