import { RootState } from '../../app/store/store';

export function selectWebSocketData(state: RootState) {
  return state.webSocket.data;
}

export function selectWebSocketHost(state: RootState) {
  return state.webSocket.data.host;
}

export function selectWebSocketPort(state: RootState) {
  return state.webSocket.data.port;
}

export function selectWebSocket(state: RootState) {
  return state.webSocket.data.web_socket;
}

export function selectWebSocketConnected(state: RootState) {
  return state.webSocket.data.is_connected;
}

export function selectWebSocketConnection(state: RootState) {
  return state.webSocket.data.is_connection;
}

export function selectSerialNumber(state: RootState) {
  return state.webSocket.data.serial_number;
}
