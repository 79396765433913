import { SvgIcon, SvgIconProps } from '@mui/material';
import { motion } from 'framer-motion';

export default function RecordIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg fill="currentColor" viewBox="0 0 24 24">
        <motion.path
          animate={{
            scale: [1, 1.2, 1, 1.2, 1],
          }}
          d={[
            'M12 7c-2.76 0-5 2.24-5 5s2.24',
            '5 5 5 5-2.24 5-5-2.24-5-5-5m0-5C6.48',
            '2 2 6.48 2 12s4.48 10 10 10 10-4.48',
            '10-10S17.52 2 12 2m0 18c-4.42 0-8-3.58-8-8s3.58-8',
            '8-8 8 3.58 8 8-3.58 8-8 8',
          ].join(' ')}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 1,
          }}
        />
      </svg>
    </SvgIcon>
  );
}
