import { alpha, Menu, MenuItem, styled } from '@mui/material';

export enum MenuItemVariant {
  WARNING = 'warning',
  SUCCESS = 'success',
}

export interface MenuItemProps {
  variant: MenuItemVariant;
}

export const MenuStyled = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: 15,
    background: theme.palette.background.default,
    border: '1px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0px 2px 22px rgba(0, 0, 0, 0.2)',
    borderRadius: 12,
    padding: 15,
    width: 430,
  },
  '& .MuiList-root': {
    padding: 0,
  },
  [theme.breakpoints.down('xl')]: {
    '& .MuiPaper-root': {
      marginTop: 8,
      padding: 10,
      width: 350,
    },
  },
}));

export const MenuItemStyled = styled(MenuItem)<MenuItemProps>(
  ({ theme, variant }) => ({
    background: alpha(
      variant === MenuItemVariant.SUCCESS
        ? theme.palette.primary.light
        : theme.palette.warning.main,
      0.08,
    ),
    transition: theme.transitions.create('background'),
    border: `1.5px solid ${alpha(
      variant === MenuItemVariant.SUCCESS
        ? theme.palette.primary.light
        : theme.palette.warning.main,
      0.3,
    )}`,
    borderRadius: 6,
    padding: '11px 17px',
    gap: 17,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color:
      variant === MenuItemVariant.SUCCESS
        ? theme.palette.primary.light
        : theme.palette.warning.main,
    '&: hover': {
      background: alpha(
        variant === MenuItemVariant.SUCCESS
          ? theme.palette.primary.light
          : theme.palette.warning.main,
        0.08,
      ),
    },
    marginBottom: 11,
    '&: last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.down('xl')]: {
      padding: '6px 12px',
      gap: 12,
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      marginBottom: 6,
    },
  }),
);
