import { selectRadarSettingsData } from '..';
import { useAppDispatch, useAppSelector } from '../../../app';
import { selectCommonData } from '../../common';
import { selectTargetsData, toggleSelectedObject } from '../../targets';
import RadarTarget from './radar-target';

export interface RadarTargetListProps {
  targetSize: number;
}

export default function RadarTargetList({ targetSize }: RadarTargetListProps) {
  const dispatch = useAppDispatch();
  const radarSettings = useAppSelector(selectRadarSettingsData);
  const { is_show_all_info, selectedObjects, targets } =
    useAppSelector(selectTargetsData);
  const { is_full_width } = useAppSelector(selectCommonData);

  const handleSelectedToggle = (id: string | number) => {
    dispatch(toggleSelectedObject(id));
  };

  return (
    <g>
      {targets.map((target, index) => (
        <RadarTarget
          key={index}
          fullWidthRadar={is_full_width}
          is_show_all_info={is_show_all_info}
          onClick={handleSelectedToggle}
          radarSettings={radarSettings}
          selectedObjects={selectedObjects}
          targetData={target}
          targetSize={targetSize}
        />
      ))}
    </g>
  );
}
