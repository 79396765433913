import { memo } from 'react';
import { RadarXYAxesLine } from './radar-xy-axes.styled';
import { RADAR_IMAGE_PROPERTIES } from '../constants';

function RadarXYAxes() {
  const { radarCenter, radarPadding, radarSize } = RADAR_IMAGE_PROPERTIES;
  return (
    <g>
      <RadarXYAxesLine
        x1={radarCenter}
        x2={radarCenter}
        y1={radarPadding}
        y2={`${radarSize - radarPadding}`}
      />
      <RadarXYAxesLine
        transform={`rotate(90 ${radarCenter} ${radarCenter})`}
        x1={radarCenter}
        x2={radarCenter}
        y1={radarPadding}
        y2={`${radarSize - radarPadding}`}
      />
    </g>
  );
}

export default memo(RadarXYAxes);
