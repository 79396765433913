import { RADAR_IMAGE_PROPERTIES } from './constants';

export const radiusSteps = [
  1000, 1500, 3000, 5000, 7000, 10000, 15000, 20000, 25000, 30000, 40000, 50000,
];

export enum ChangeInputEnum {
  INCREMENT = 'increment',
  DECREMENT = 'decrement',
}

export const changeInputNumberValue = (
  prevValue: number,
  type: ChangeInputEnum,
  callback: (newValue: number) => void,
  maxValue: number,
) => {
  if (type === ChangeInputEnum.DECREMENT) {
    if (Number.isNaN(prevValue)) {
      callback(-0.1);
    }
    if (prevValue > maxValue) {
      callback(prevValue ? Number((prevValue - 0.1).toFixed(1)) : -0.1);
    }
  }

  if (type === ChangeInputEnum.INCREMENT) {
    if (Number.isNaN(prevValue)) {
      callback(0.1);
    }
    if (prevValue < maxValue) {
      callback(prevValue ? Number((prevValue + 0.1).toFixed(1)) : 0.1);
    }
  }
};

export const changeLatLongValue = (
  prevValue: number,
  type: ChangeInputEnum,
  callback: (newValue: number) => void,
  maxValue: number,
) => {
  if (type === ChangeInputEnum.DECREMENT) {
    if (Number.isNaN(prevValue)) {
      callback(-0.000001);
    }
    if (prevValue > maxValue) {
      callback(
        prevValue ? Number((prevValue - 0.000001).toFixed(6)) : -0.000001,
      );
    }
  }

  if (type === ChangeInputEnum.INCREMENT) {
    if (Number.isNaN(prevValue)) {
      callback(0.000001);
    }
    if (prevValue < maxValue) {
      callback(
        prevValue ? Number((prevValue + 0.000001).toFixed(6)) : 0.000001,
      );
    }
  }
};

export function getRadarPaddingByContainerSize(containerSize: number) {
  return (
    (containerSize / RADAR_IMAGE_PROPERTIES.radarSize) *
    (RADAR_IMAGE_PROPERTIES.radarPadding * 2)
  );
}
