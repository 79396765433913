import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { theme, useAppDispatch, useAppSelector } from '../../../app';
import { SettingsControls } from '../../settings';
import { DraggableWrapper, Table } from '../../table';
import { RadarPage } from '../components';
import { Root, LeftBar } from './radar-screen.styled';
import {
  connectWebSocket,
  disconnectWebSocket,
  selectWebSocketData,
} from '../../socket';
import useAnimation from '../hooks/use-animation';
import { selectCommonData } from '../../common';

export default function RadarScreen() {
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [isOpenFullWidthTable, setIsOpenFullWidthTable] = useState(false);
  const dispatch = useAppDispatch();
  const { leftBarAnimationProps, controlsAnimationProps } = useAnimation();
  const { host, port, serial_number } = useAppSelector(selectWebSocketData);
  const { is_full_width } = useAppSelector(selectCommonData);
  const intl = useIntl();

  const handleToggle = () => {
    setIsOpenFullWidthTable((p) => !p);
  };

  useEffect(() => {
    if (!is_full_width) {
      setIsOpenFullWidthTable(false);
    }
  }, [is_full_width]);

  useEffect(() => {
    dispatch(connectWebSocket(host, port, serial_number, intl));

    return () => {
      dispatch(disconnectWebSocket());
    };
  }, [dispatch, intl, host, port, serial_number]);

  return (
    <Root>
      <LeftBar {...leftBarAnimationProps}>
        <RadarPage />
      </LeftBar>
      {isUpSm && (
        <SettingsControls
          isOpenFullWidthTable={isOpenFullWidthTable}
          onClick={handleToggle}
          {...controlsAnimationProps}
        />
      )}
      <DraggableWrapper
        isFullWidth={is_full_width}
        isOpen={isOpenFullWidthTable}
        onClose={handleToggle}
      >
        <Table />
      </DraggableWrapper>
    </Root>
  );
}
