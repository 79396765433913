import { Switch, Button, Typography, Box } from '@mui/material';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { InputField } from '../../../app/components';
import translate from '../../../translations/translate';

import {
  SettingsForm,
  SettingsRoot,
  SettingsRow,
  SettingsTitle,
  SettingsFooter,
} from './settings-tab-content.styled';
import { FormatTypes } from '../../../app/components/input-field';
import { VirazhDataField, VirazhData, VirazhStatus } from '../../virazh/types';
import { FormControlLabelStyled } from '../../radar/components/radar-settings-form.styled';
import { RadarStatuses, selectRadarStatus } from '../../radar';
import { useAppSelector } from '../../../app/store/store';
import palette from '../../../app/theme/palette';

export interface SettingsVirazhModalProps {
  onClose: () => void;
  control: Control<VirazhData, undefined>;
  register: UseFormRegister<VirazhData>;
  handleSubmit: UseFormHandleSubmit<VirazhData>;
  onSubmit: (data: VirazhData) => void;
  errors: FieldErrors<VirazhData>;
}

export default function SettingsVirazh({
  control,
  register,
  errors,
  handleSubmit,
  onSubmit,
  onClose,
}: SettingsVirazhModalProps) {
  const intl = useIntl();
  const radarStatus = useAppSelector(selectRadarStatus);

  const getErrorProps = <T extends keyof FieldErrors<VirazhData>>(
    fieldName: T,
  ) => ({
    error: !!errors[fieldName],
    errorText:
      errors[fieldName]?.message &&
      intl.formatMessage({
        id: errors[fieldName]?.message,
      }),
  });

  const disabled = radarStatus !== RadarStatuses.ON;

  return (
    <SettingsRoot>
      <SettingsForm onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <SettingsTitle>{translate('settings.virazh.title')}</SettingsTitle>
          <SettingsRow>
            <Controller
              control={control}
              name={VirazhDataField.STATUS}
              render={({ field: { onChange, value, ...otherField } }) => (
                <FormControlLabelStyled
                  color="#fff"
                  control={
                    <Switch
                      color="primary"
                      defaultChecked={value === VirazhStatus.ON}
                      disabled={disabled}
                    />
                  }
                  label={intl.formatMessage({
                    id: 'settings.virazh.status',
                  })}
                  onChange={(_, checked) => {
                    const isOn = checked ? VirazhStatus.ON : VirazhStatus.OFF;
                    onChange(isOn);
                  }}
                  value={value === VirazhStatus.ON}
                  {...otherField}
                />
              )}
            />
          </SettingsRow>
          <SettingsRow>
            <InputField
              disabled={disabled}
              label={intl.formatMessage({
                id: 'settings.virazh.host',
              })}
              placeholder="123.123.244.22"
              sx={{ maxWidth: 200 }}
              {...register(VirazhDataField.HOST)}
              {...getErrorProps(VirazhDataField.HOST)}
            />

            <InputField
              disabled={disabled}
              formatType={FormatTypes.NUMBER}
              label={intl.formatMessage({
                id: 'settings.virazh.port',
              })}
              placeholder="80"
              sx={{ maxWidth: 100 }}
              {...register(VirazhDataField.PORT)}
              {...getErrorProps(VirazhDataField.PORT)}
            />

            <InputField
              disabled={disabled}
              formatType={FormatTypes.NUMBER}
              label="SIC"
              placeholder="1"
              sx={{ maxWidth: 50 }}
              {...register(VirazhDataField.SIC)}
              {...getErrorProps(VirazhDataField.SIC)}
            />
          </SettingsRow>
          <SettingsRow>
            <Typography
              color={palette.primary.dark}
              fontStyle="italic"
              variant="caption"
            >
              {translate('settings.virazh.helper')}
            </Typography>
          </SettingsRow>
        </Box>

        <SettingsFooter>
          <Button
            color="warning"
            onClick={onClose}
            sx={{ marginRight: '10px' }}
            variant="outlined"
          >
            {translate('button.cancel')}
          </Button>
          <Button color="primary" type="submit" variant="contained">
            {translate('button.save')}
          </Button>
        </SettingsFooter>
      </SettingsForm>
    </SettingsRoot>
  );
}
