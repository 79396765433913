import { Palette } from '@mui/material';

/**
 * Returns the color based on the elevation value.
 *
 * - Colors:
 *   - `palette.warning.main`: Elevation >= 4000
 *   - `palette.primary.dark`: Elevation >= 1000
 *   - `palette.primary.main`: Elevation >= 100
 *   - `palette.primary.light`: Elevation < 100
 *   - `transparent`: Elevation < 0
 */
export default function getColorByTargetElevation(
  elevation: number,
  palette: Palette,
) {
  if (typeof elevation !== 'number') {
    throw new Error('Invalid input: elevation must be a number');
  }

  switch (true) {
    case elevation >= 4000:
      return palette.warning.main;
    case elevation >= 1000:
      return palette.primary.dark;
    case elevation >= 100:
      return palette.primary.main;
    case elevation >= 0:
      return palette.info.light;
    default:
      return palette.secondary.light;
  }
}
