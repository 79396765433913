import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
  alpha,
} from '@mui/material';

export interface MuiInputLabelProps {
  defaultProps?: ComponentsProps['MuiInputLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputLabel'];
  variants?: ComponentsVariants['MuiInputLabel'];
}

const MuiInputLabel: MuiInputLabelProps = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      position: 'static',
      transform: 'none',
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '18px',
      letterSpacing: ' -0.2px',
      color: theme.palette.primary.contrastText,
      cursor: 'pointer',
      '&.Mui-error': {
        color: theme.palette.warning.main,
      },
      '&.Mui-focused': {
        color: theme.palette.primary.contrastText,
      },
      '&.Mui-disabled': {
        color: alpha(theme.palette.primary.contrastText, 0.1),
      },
      [theme.breakpoints.down('xl')]: {
        fontSize: 12,
        lineHeight: '14px',
        marginBottom: 8,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
        lineHeight: '12px',
        marginBottom: 5,
      },
    }),
  },
};

export default MuiInputLabel;
