export function isCoordinates(input: string) {
  const regex =
    /^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?),\s*-?((1[0-7]|[1-9])?[0-9](\.\d+)?|180(\.0+)?)$/;

  return regex.test(input);
}

export function isCoordinatesSeparatedByCommas(input: string) {
  if (input === null || input === undefined) {
    return false;
  }

  const regexComa =
    /^-?([1-8]?[0-9](,\d+)?|90(\.0+)?),\s*-?((1[0-7]|[1-9])?[0-9](,\d+)?|180(\.0+)?)$/;

  return regexComa.test(input.trim());
}

export function replaceCommaWithDot(coordinates: string) {
  const parts = coordinates.split(', ');
  return parts.map((part) => part.replace(',', '.')).join(', ');
}
