import { MapFields } from './types';

export const RADAR_IMAGE_PROPERTIES = {
  radarSize: 1000,
  radiusStepsCount: 10,
  radarPadding: 40,
  radarCenter: 500,
};

export const HEIGHT_RANGES = {
  small: '1 - 99',
  medium: '100 - 999',
  large: '1000 - 4000',
  huge: '> 4000',
};

export const MAP_OPTIONS = [MapFields.OFF, MapFields.ONLINE, MapFields.OFFLINE];

export const ELEVATION_VALUES = [
  { value: 20, range: HEIGHT_RANGES.small },
  { value: 400, range: HEIGHT_RANGES.medium },
  { value: 3000, range: HEIGHT_RANGES.large },
  { value: 4500, range: HEIGHT_RANGES.huge },
];

export const LATITUDE_MIN = -90;
export const LATITUDE_MAX = 90;

export const LONGITUDE_MIN = -180;
export const LONGITUDE_MAX = 180;
export const ALTITUDE_MIN = 0;
export const ALTITUDE_MAX = 10000000;
export const ANGLE_MIN = -360;
export const ANGLE_MAX = 360;
export const DIRECTION_MIN = -360;
export const DIRECTION_MAX = 360;
export const ELEVATION_MIN = -45;
export const ELEVATION_MAX = 90;
export const EQUATOR_LENGTH_IN_METER = 40075016.686; // 2π ∙ 6378137
