import { useCallback, useEffect, useMemo, useState } from 'react';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import RadarIcon from '@mui/icons-material/Radar';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { DialogProps, useMediaQuery } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SettingsModalRoot,
  SettingsModalDialog,
  SettingsTabs,
  SettingsTab,
} from './settings-modal.styled';
import TabPanel from './settings-tab-panel';
import SettingsCommon from './settings-common';
import SettingsRadar from './settings-radar';
import translate from '../../../translations/translate';
import { useAppDispatch, useAppSelector } from '../../../app/store/store';
import { ConfirmModal } from '../../../app/components';
import {
  SettingsRadarProps,
  RadarDataField,
  setSettingsData,
  VisualizationTypes,
  selectRadarSettingsData,
  ScanRange,
} from '../../radar';
import { validationRadarSchema } from '../schema';
import toast from '../../../app/services/notifications-service';
import { theme } from '../../../app';
import { selectWebSocketData } from '../../socket/selectors';
import { SocketData, SocketDataFields } from '../../socket/types';
import { updateWebSocketData } from '../../socket/slice';
import SettingsVirazh from './settings-virazh';
import {
  VirazhData,
  VirazhDataField,
  selectVirazhData,
  setVirazhData,
  validationVirazhSchema,
} from '../../virazh';
import { CommonData, selectCommonData, setCommonData } from '../../common';

export default function SettingsModal({ onClose, ...otherProps }: DialogProps) {
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = useState(0);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { host, port, serial_number, web_socket } =
    useAppSelector(selectWebSocketData);
  const commonData = useAppSelector(selectCommonData);
  const {
    instrumental_range,
    grid_range,
    scan_type,
    track_length,
    visualization_type,
    elevation_scan_begin,
    elevation_scan_end,
    azimuth_scan_begin,
    azimuth_scan_end,
  } = useAppSelector(selectRadarSettingsData);

  const {
    host: virazhHost,
    port: virazhPort,
    sic: virazhSic,
    status: virazhStatus,
  } = useAppSelector(selectVirazhData);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
    register,
  } = useForm<CommonData>({
    mode: 'onSubmit',
    defaultValues: commonData,
  });

  const {
    register: radarRegister,
    handleSubmit: radarHandleSubmit,
    reset: radarReset,
    setValue: radarSetValue,
    formState: {
      errors: radarErrors,
      isDirty: radarIsDirty,
      isValid: radarIsValid,
    },
    control: radarControl,
  } = useForm<SettingsRadarProps & SocketData>({
    mode: 'onSubmit',
    defaultValues: {
      [SocketDataFields.HOST]: host,
      [SocketDataFields.PORT]: port,
      [SocketDataFields.SERIAL_NUMBER]: serial_number,
      [RadarDataField.ELEVATION_SCAN_BEGIN]: elevation_scan_begin,
      [RadarDataField.ELEVATION_SCAN_END]: elevation_scan_end,
      [RadarDataField.AZIMUTH_SCAN_BEGIN]: azimuth_scan_begin,
      [RadarDataField.AZIMUTH_SCAN_END]: azimuth_scan_end,
      [RadarDataField.TRACK_LENGTH]: track_length,
      [RadarDataField.VISUALIZATION_TYPE]: visualization_type,
      [RadarDataField.SCAN_TYPE]: scan_type,
    },
    resolver: yupResolver(validationRadarSchema),
  });

  const {
    register: virazhRegister,
    handleSubmit: virazhHandleSubmit,
    reset: virazhReset,
    formState: {
      errors: virazhErrors,
      isDirty: virazhIsDirty,
      isValid: virazhIsValid,
    },
    control: virazhControl,
  } = useForm<VirazhData>({
    mode: 'onSubmit',
    defaultValues: {
      [VirazhDataField.HOST]: virazhHost,
      [VirazhDataField.PORT]: virazhPort,
      [VirazhDataField.SIC]: virazhSic,
      [VirazhDataField.STATUS]: virazhStatus,
    },
    resolver: yupResolver(validationVirazhSchema),
  });

  useEffect(() => {
    radarSetValue(RadarDataField.ELEVATION_SCAN_BEGIN, elevation_scan_begin);
    radarSetValue(RadarDataField.ELEVATION_SCAN_END, elevation_scan_end);
    radarSetValue(RadarDataField.AZIMUTH_SCAN_BEGIN, azimuth_scan_begin);
    radarSetValue(RadarDataField.AZIMUTH_SCAN_END, azimuth_scan_end);
  }, [
    elevation_scan_begin,
    elevation_scan_end,
    azimuth_scan_begin,
    azimuth_scan_end,
    radarSetValue,
  ]);

  const handleCancellation = useCallback(() => {
    if (isDirty || radarIsDirty || virazhIsDirty) {
      setIsLeaveModalOpen(true);
    } else {
      onClose?.({}, 'backdropClick');
    }
  }, [isDirty, radarIsDirty, virazhIsDirty, onClose]);

  const onSubmit = useCallback(
    (data: CommonData) => {
      dispatch(setCommonData(data));

      toast({
        type: 'success',
        message: intl.formatMessage({
          id: 'toast.saved',
        }),
      });

      reset({}, { keepValues: true });
    },
    [reset, dispatch, intl],
  );

  const onRadarSubmit = useCallback(
    ({
      host: newHost,
      port: newPort,
      serial_number: newSerialNumber,
      ...data
    }: SettingsRadarProps & SocketData) => {
      const trackLength = parseFloat(String(data.track_length) || '0');
      const isInstrumental =
        data[RadarDataField.SCAN_TYPE] === ScanRange.INSTRUMENTAL ||
        grid_range > instrumental_range;

      dispatch(
        setSettingsData({
          ...data,
          [RadarDataField.TRACK_LENGTH]: trackLength,
          [RadarDataField.SCAN_RANGE]: isInstrumental
            ? instrumental_range
            : grid_range,
        }),
      );

      if (
        newHost !== host ||
        newPort !== port ||
        newSerialNumber !== serial_number
      ) {
        dispatch(
          updateWebSocketData({
            port: newPort,
            host: newHost,
            serial_number: newSerialNumber,
          }),
        );
      }

      web_socket?.send(
        JSON.stringify({
          serial_number,
          module: 'settings',
          method: 'visualization',
          data:
            data.visualization_type === VisualizationTypes.TRACKS
              ? {
                  track_length: trackLength,
                  visualization_type: data.visualization_type,
                }
              : {
                  visualization_type: data.visualization_type,
                },
        }),
      );

      web_socket?.send(
        JSON.stringify({
          serial_number: newSerialNumber,
          module: 'settings',
          method: 'radar',
          data: {
            azimuth_scan_begin: data.azimuth_scan_begin,
            azimuth_scan_end: data.azimuth_scan_end,
            elevation_scan_begin: data.elevation_scan_begin,
            elevation_scan_end: data.elevation_scan_end,
            scan_range:
              data[RadarDataField.SCAN_TYPE] === ScanRange.INSTRUMENTAL
                ? instrumental_range
                : grid_range,
          },
        }),
      );

      toast({
        type: 'success',
        message: intl.formatMessage({
          id: 'toast.saved',
        }),
      });

      radarReset({}, { keepValues: true });
    },
    [
      dispatch,
      radarReset,
      web_socket,
      intl,
      host,
      port,
      serial_number,
      instrumental_range,
      grid_range,
    ],
  );

  const onVirazhSubmit = useCallback(
    (data: VirazhData) => {
      dispatch(setVirazhData(data));

      web_socket?.send(
        JSON.stringify({
          serial_number,
          module: 'integrations',
          method: 'virazh',
          data: {
            ...data,
            status: data[VirazhDataField.STATUS] ? 'on' : 'off',
          },
        }),
      );

      toast({
        type: 'success',
        message: intl.formatMessage({
          id: 'toast.saved',
        }),
      });

      virazhReset({}, { keepValues: true });
    },
    [dispatch, virazhReset, intl, serial_number, web_socket],
  );

  const getStatus = (isFormDirty: boolean, isFormValid: boolean) => {
    if (isFormDirty) {
      return isFormValid;
    }
    return undefined;
  };

  const tabs = useMemo(
    () => [
      {
        label: translate('tabs.radar'),
        content: (
          <SettingsRadar
            control={radarControl}
            errors={radarErrors}
            handleSubmit={radarHandleSubmit}
            onClose={handleCancellation}
            onSubmit={onRadarSubmit}
            register={radarRegister}
          />
        ),
        icon: <RadarIcon />,
        status: getStatus(radarIsDirty, radarIsValid),
      },
      {
        label: translate('tabs.integrations'),
        content: (
          <SettingsVirazh
            control={virazhControl}
            errors={virazhErrors}
            handleSubmit={virazhHandleSubmit}
            onClose={handleCancellation}
            onSubmit={onVirazhSubmit}
            register={virazhRegister}
          />
        ),
        icon: <SwapHorizIcon />,
        status: getStatus(virazhIsDirty, virazhIsValid),
      },
      {
        label: translate('tabs.common'),
        content: (
          <SettingsCommon
            control={control}
            handleSubmit={handleSubmit}
            onClose={handleCancellation}
            onSubmit={onSubmit}
            register={register}
          />
        ),
        icon: <DisplaySettingsIcon />,
        status: getStatus(isDirty, isValid),
      },
    ],
    [
      control,
      radarControl,
      isDirty,
      radarIsDirty,
      radarRegister,
      radarErrors,
      handleSubmit,
      radarHandleSubmit,
      onSubmit,
      onRadarSubmit,
      handleCancellation,
      isValid,
      radarIsValid,
      onVirazhSubmit,
      virazhControl,
      virazhErrors,
      virazhHandleSubmit,
      virazhRegister,
      virazhIsDirty,
      virazhIsValid,
      register,
    ],
  );

  return (
    <SettingsModalDialog
      keepMounted
      onClose={handleCancellation}
      {...otherProps}
    >
      <SettingsModalRoot id="draggable-settings-modal">
        <SettingsTabs
          aria-label="vertical-tabs"
          onChange={handleChange}
          orientation={isDownMd ? 'horizontal' : 'vertical'}
          value={value}
        >
          {tabs.map(({ icon, label, status }, index) => (
            <SettingsTab
              key={index}
              aria-controls={`vertical-tabpanel-${index}`}
              icon={icon}
              iconPosition="start"
              id={`vertical-tab-${label}`}
              isValid={status}
              label={label}
            />
          ))}
        </SettingsTabs>
        {tabs.map(({ content }, index) => (
          <TabPanel key={index} index={index} value={value}>
            {content}
          </TabPanel>
        ))}
      </SettingsModalRoot>
      <ConfirmModal
        onClose={() => setIsLeaveModalOpen(false)}
        onConfirm={() => {
          setIsLeaveModalOpen(false);
          onClose?.({}, 'backdropClick');
        }}
        open={isLeaveModalOpen}
        text={intl.formatMessage({
          id: 'leave.question',
        })}
        title={intl.formatMessage({
          id: 'leave.title',
        })}
      />
    </SettingsModalDialog>
  );
}
