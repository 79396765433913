import useMediaQuery from '@mui/material/useMediaQuery';
import { Outlet } from 'react-router-dom';
import { MobileNavigation } from '../components';

import theme from '../theme/theme';
import { Root } from './dashboard-layout.styled';

export default function DashboardLayout() {
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Root>
      <Outlet />
      {isDownSm && <MobileNavigation />}
    </Root>
  );
}
