export enum SystemList {
  GPS = 'gps',
  GYROSCOPE = 'gyroscope',
  HORIZONTIZE = 'horizontize',
  NETWORK = 'network',
  POWER = 'power',
  RADAR_STATUS = 'radar_status',
  TEMPERATURE = 'temperature',
}

export enum VisualizationTypes {
  TRACKS = 'tracks',
  OBJECTS = 'objects',
}

export enum ScanRange {
  GRID = 'grid',
  INSTRUMENTAL = 'instrumental',
}

export enum RadarStatuses {
  ON = 'on',
  OFF = 'off',
  DISCONNECTED = 'disconnected',
}

export enum PositionFields {
  MANUAL = 'manual',
  GPS = 'gps',
}

export enum MapFields {
  ONLINE = 'online',
  OFFLINE = 'offline',
  OFF = 'off',
}

export interface Coords {
  x: number;
  y: number;
}

export enum RadarDataField {
  STATUS = 'status',
  GRID_RANGE = 'grid_range',
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
  GRID_ANGLE = 'grid_angle',
  ALTITUDE = 'altitude',
  DIRECTION = 'direction',
  INSTRUMENTAL_RANGE = 'instrumental_range',
  TRACK_LENGTH = 'track_length',
  VISUALIZATION_TYPE = 'visualization_type',
  MAP = 'map',
  ELEVATION = 'elevation',
  ELEVATION_SCAN_BEGIN = 'elevation_scan_begin',
  ELEVATION_SCAN_END = 'elevation_scan_end',
  AZIMUTH_SCAN_BEGIN = 'azimuth_scan_begin',
  AZIMUTH_SCAN_END = 'azimuth_scan_end',
  SCAN_STEP = 'scan_step',
  SCAN_RANGE = 'scan_range',
  SCAN_TYPE = 'scan_type',
  ELEVATION_SCAN_MAX = 'elevation_scan_max',
  ELEVATION_SCAN_MIN = 'elevation_scan_min',
  AZIMUTH_SCAN_MAX = 'azimuth_scan_max',
  AZIMUTH_SCAN_MIN = 'azimuth_scan_min',
  POSITION = 'position',
  GPS_ERROR = 'gps_error',
  GPS_AUTO_UPDATE = 'gps_auto_update',
  BLIND_AREA = 'blind_area',
}

export interface SettingsRadarProps {
  [RadarDataField.ELEVATION_SCAN_BEGIN]: number;
  [RadarDataField.ELEVATION_SCAN_END]: number;
  [RadarDataField.AZIMUTH_SCAN_BEGIN]: number;
  [RadarDataField.AZIMUTH_SCAN_END]: number;
  [RadarDataField.SCAN_STEP]: number;
  [RadarDataField.ELEVATION_SCAN_MAX]: number;
  [RadarDataField.ELEVATION_SCAN_MIN]: number;
  [RadarDataField.AZIMUTH_SCAN_MAX]: number;
  [RadarDataField.AZIMUTH_SCAN_MIN]: number;
  [RadarDataField.TRACK_LENGTH]: number;
  [RadarDataField.VISUALIZATION_TYPE]: VisualizationTypes;
  [RadarDataField.SCAN_TYPE]: ScanRange.INSTRUMENTAL;
  [RadarDataField.SCAN_RANGE]: number;
}

export interface SettingsDataProps {
  [RadarDataField.STATUS]: RadarStatuses;
  [RadarDataField.GRID_RANGE]: number;
  [RadarDataField.GRID_ANGLE]: number;
  [RadarDataField.LATITUDE]: number;
  [RadarDataField.LONGITUDE]: number;
  [RadarDataField.ELEVATION]: number;
  [RadarDataField.ALTITUDE]: number;
  [RadarDataField.DIRECTION]: number;
  [RadarDataField.INSTRUMENTAL_RANGE]: number;
  [RadarDataField.BLIND_AREA]: number;
  [RadarDataField.POSITION]: PositionFields;
  [RadarDataField.GPS_ERROR]: boolean;
  [RadarDataField.GPS_AUTO_UPDATE]: boolean;
  [RadarDataField.MAP]: MapFields;
}

export type RadarSettingsData = SettingsDataProps & SettingsRadarProps;

export interface RadarData {
  settings: RadarSettingsData;
  errors: Record<SystemList, boolean>;
  record: {
    status: boolean;
    file: string;
  };
}

export interface RadarSliceState {
  data: RadarData;
}

export enum RadarAsyncActions {
  GET_RADAR = 'getRadar',
}

export interface GetRadarQueryParams {
  search?: string;
}

export interface GetRadarThunkArgs {
  params?: GetRadarQueryParams;
}

export interface GetRadarRequest {
  radar: RadarData;
}
