import { generatePath } from 'react-router-dom';

export enum PathsMap {
  NOT_FOUND = '*',
  INTRO = '/',
}

export const getNotFoundPath = () => generatePath(PathsMap.NOT_FOUND);

export const getIntroPath = () => generatePath(PathsMap.INTRO);
