import {
  Autocomplete,
  Button,
  InputAdornment,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useIntl } from 'react-intl';
import { Control, Controller } from 'react-hook-form';
import { PositionFields, RadarDataField } from '../types';
import { radiusSteps } from '../utils';
import {
  ErrorText,
  FormControlLabelStyled,
  RadarSettingsFooter,
  RadarSettingsRow,
  RadarSettingsTitle,
  RadarSettingsForm,
} from './radar-settings-form.styled';
import { FormatTypes } from '../../../app/components/input-field';
import translate from '../../../translations/translate';
import { SettingsRadarForm } from '../schema';
import RadarInputNumber, {
  SharedRadarInputNumberProps,
} from './radar-number-input';
import {
  ALTITUDE_MAX,
  ALTITUDE_MIN,
  ANGLE_MAX,
  ANGLE_MIN,
  ELEVATION_MAX,
  ELEVATION_MIN,
  LATITUDE_MAX,
  LATITUDE_MIN,
  LONGITUDE_MAX,
  LONGITUDE_MIN,
  MAP_OPTIONS,
} from '../constants';
import { SelectArrowIcon } from '../../../app/icons';
import { Label } from '../../../app/components/input-field.styled';
import { capitalizeFirstLetter } from '../../../app';
import SettingsIconButton from '../../settings/components/settings-icon-button';

export interface RadarSettingsFrom extends SharedRadarInputNumberProps {
  onSubmit: () => void;
  positionStatus: PositionFields;
  gps_error: boolean;
  disabled: boolean;
  isAutoUpdate: boolean;
  control: Control<SettingsRadarForm, unknown>;
  handleCancellation: () => void;
  handleRefresh: () => void;
}

export default function RadarSettingsFrom({
  onSubmit,
  handleCancellation,
  handleRefresh,
  gps_error,
  isAutoUpdate,
  positionStatus,
  control,
  disabled,
  ...otherProps
}: RadarSettingsFrom) {
  const intl = useIntl();

  return (
    <RadarSettingsForm onSubmit={onSubmit}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mb={1}
      >
        <RadarSettingsTitle>
          {translate('settings.position.position')}
        </RadarSettingsTitle>
        <Controller
          control={control}
          name={RadarDataField.POSITION}
          render={({ field }) => (
            <FormControlLabelStyled
              color="#fff"
              control={
                <Switch
                  color="primary"
                  defaultChecked={otherProps.isGps}
                  disabled={disabled}
                />
              }
              label={translate('button.get_data')}
              {...field}
            />
          )}
        />
      </Stack>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap={{ xs: 1, sm: 7.5 }}
        justifyContent="space-between"
      >
        <RadarInputNumber
          disabled={disabled}
          errorOption={{
            max: LATITUDE_MAX,
            min: LATITUDE_MIN,
          }}
          fieldName={RadarDataField.LATITUDE}
          formatType={FormatTypes.LATITUDE}
          label="settings.position.label.latitude"
          max={LATITUDE_MAX}
          min={LATITUDE_MIN}
          {...otherProps}
        />

        <RadarInputNumber
          disabled={disabled}
          errorOption={{
            max: LONGITUDE_MAX,
            min: LONGITUDE_MIN,
          }}
          fieldName={RadarDataField.LONGITUDE}
          formatType={FormatTypes.LONGITUDE}
          label="settings.position.label.longitude"
          max={LONGITUDE_MAX}
          min={LONGITUDE_MIN}
          {...otherProps}
        />
      </Stack>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap={2}
        justifyContent="space-between"
        mb={2}
        mt={1.5}
      >
        <RadarInputNumber
          disabled={disabled}
          endAdornment={<InputAdornment position="end">°</InputAdornment>}
          errorOption={{
            start: ANGLE_MIN,
            end: ANGLE_MAX,
          }}
          fieldName={RadarDataField.DIRECTION}
          formatType={FormatTypes.NUMBER}
          label="settings.position.label.azimuth"
          max={ANGLE_MAX}
          min={ANGLE_MIN}
          {...otherProps}
        />
        <RadarInputNumber
          disabled={disabled}
          endAdornment={<InputAdornment position="end">°</InputAdornment>}
          errorOption={{
            start: ELEVATION_MIN,
            end: ELEVATION_MAX,
          }}
          fieldName={RadarDataField.ELEVATION}
          formatType={FormatTypes.NUMBER}
          label="settings.position.label.elevation"
          max={ELEVATION_MAX}
          min={ELEVATION_MIN}
          {...otherProps}
        />
      </Stack>
      <Stack
        alignItems={{ sm: 'flex-end' }}
        direction={{ xs: 'column', sm: 'row' }}
        gap={2}
        justifyContent="space-between"
        mb={2}
        mt={1.5}
      >
        <RadarInputNumber
          disabled={disabled}
          endAdornment={<InputAdornment position="end">м.</InputAdornment>}
          errorOption={{
            value: ALTITUDE_MIN,
          }}
          fieldName={RadarDataField.ALTITUDE}
          formatType={FormatTypes.NUMBER}
          label="settings.position.label.altitude"
          max={ALTITUDE_MAX}
          min={ALTITUDE_MIN}
          {...otherProps}
        />

        {otherProps.isGps && (
          <Stack flexGrow={1} maxWidth="calc(50% - 30px)">
            <Label>{translate('settings.position.refresh_data')}</Label>
            <Stack alignItems="center" direction="row" gap={2}>
              <SettingsIconButton
                color="primary"
                disabled={isAutoUpdate || disabled}
                onClick={handleRefresh}
                tooltipTitle={translate('settings.position.refresh')}
              >
                <RefreshIcon />
              </SettingsIconButton>
              <Controller
                control={control}
                name={RadarDataField.GPS_AUTO_UPDATE}
                render={({ field }) => (
                  <FormControlLabelStyled
                    color="#fff"
                    control={
                      <Switch
                        color="primary"
                        defaultChecked={isAutoUpdate}
                        disabled={disabled}
                      />
                    }
                    label={translate('settings.position.automatically')}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
      <ErrorText variant="body2">
        {positionStatus === PositionFields.GPS &&
          gps_error &&
          translate('settings.position.gps_error')}
      </ErrorText>
      <RadarSettingsTitle>
        {translate('settings.position.grid')}
      </RadarSettingsTitle>
      <RadarSettingsRow>
        <Controller
          control={control}
          name={RadarDataField.GRID_RANGE}
          render={({ field: { onChange, ...otherField } }) => (
            <Autocomplete
              disableClearable
              disabled={disabled}
              getOptionLabel={(option) => String(option)}
              onChange={(_, data) => {
                onChange(data);
              }}
              options={radiusSteps}
              {...otherField}
              popupIcon={<SelectArrowIcon color="primary" />}
              renderInput={(params) => (
                <TextField
                  label={intl.formatMessage({
                    id: 'settings.position.label.radius',
                  })}
                  variant="standard"
                  {...params}
                />
              )}
            />
          )}
        />
        <RadarInputNumber
          disabled={disabled}
          endAdornment={<InputAdornment position="end">°</InputAdornment>}
          errorOption={{
            start: ANGLE_MIN,
            end: ANGLE_MAX,
          }}
          fieldName={RadarDataField.GRID_ANGLE}
          formatType={FormatTypes.NUMBER}
          label="settings.position.label.angle"
          max={ANGLE_MAX}
          min={ANGLE_MIN}
          {...otherProps}
          isGps={false}
        />
      </RadarSettingsRow>
      <RadarSettingsRow>
        <Controller
          control={control}
          name={RadarDataField.MAP}
          render={({ field: { onChange, ...otherField } }) => (
            <Autocomplete
              disableClearable
              getOptionLabel={(option) => capitalizeFirstLetter(option)}
              onChange={(_, data) => {
                onChange(data);
              }}
              options={MAP_OPTIONS}
              {...otherField}
              popupIcon={<SelectArrowIcon color="primary" />}
              renderInput={(params) => (
                <TextField
                  label={intl.formatMessage({
                    id: 'settings.position.map',
                  })}
                  variant="standard"
                  {...params}
                />
              )}
            />
          )}
        />
      </RadarSettingsRow>

      <RadarSettingsFooter>
        <Button
          color="warning"
          onClick={handleCancellation}
          sx={{ marginRight: '10px' }}
          variant="outlined"
        >
          {translate('button.cancel')}
        </Button>
        <Button color="primary" type="submit" variant="contained">
          {translate('button.save')}
        </Button>
      </RadarSettingsFooter>
    </RadarSettingsForm>
  );
}
