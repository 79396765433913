import { SvgIcon, SvgIconProps } from '@mui/material';

export default function LoiteringMunitionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="currentColor"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
  M 26.39 53.63
  L 22.58 56.78
  A 0.96 0.96 0.0 0 1 21.01 56.04
  L 21.00 50.22
  Q 21.00 49.66 20.47 49.47
  L 11.55 46.25
  Q 11.04 46.06 10.56 46.30
  L 5.49 48.84
  A 0.97 0.95 44.3 0 1 4.62 48.84
  Q 4.01 48.52 4.01 47.93
  Q 3.98 43.99 4.02 40.18
  A 1.47 1.44 -22.9 0 1 4.45 39.15
  Q 13.94 29.75 23.40 20.21
  C 24.99 18.61 26.36 16.78 27.74 14.98
  A 1.70 1.69 60.9 0 0 28.07 13.81
  C 27.62 9.17 28.03 5.77 31.30 2.35
  A 0.98 0.98 0.0 0 1 32.72 2.35
  C 36.45 6.28 35.84 9.19 36.02 14.16
  Q 36.04 14.69 36.34 15.11
  Q 38.31 17.84 40.48 20.04
  Q 50.06 29.76 59.60 39.18
  Q 60.00 39.57 60.00 40.13
  L 60.00 48.02
  A 0.98 0.97 13.2 0 1 58.59 48.89
  L 53.43 46.29
  Q 52.94 46.05 52.43 46.23
  L 43.52 49.49
  Q 43.00 49.68 43.00 50.23
  L 42.99 55.95
  A 1.01 1.00 20.0 0 1 41.34 56.72
  L 37.63 53.60
  Q 37.05 53.12 37.02 53.87
  Q 36.94 56.01 37.03 58.27
  C 37.14 60.76 35.21 62.82 32.65 61.55
  Q 32.15 61.31 31.64 61.50
  L 30.67 61.84
  Q 29.93 62.11 29.20 61.85
  C 26.05 60.72 27.06 56.52 27.00 53.90
  Q 26.98 53.14 26.39 53.63
  Z"
        />
      </svg>
    </SvgIcon>
  );
}
