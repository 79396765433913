import { useMemo, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import translate from '../../../translations/translate';
import { SuccessIcon, WarningIcon } from '../../../app/icons';
import {
  MenuItemStyled,
  MenuItemVariant,
  MenuStyled,
} from './settings-system-list.styled';
import { useAppSelector } from '../../../app/store/store';
import {
  RadarStatuses,
  selectRadarSettingsData,
  selectSystemStatuses,
} from '../../radar';
import SettingsIconButton from './settings-icon-button';

export default function SettingsSystemList() {
  const systemStatuses = useAppSelector(selectSystemStatuses);
  const { status } = useAppSelector(selectRadarSettingsData);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isDisabled = status !== RadarStatuses.ON;

  const isAllSuccess = useMemo(
    () => Object.values(systemStatuses).every((systemStatus) => systemStatus),
    [systemStatuses],
  );

  return (
    <>
      <SettingsIconButton
        aria-controls={open ? 'settings-list--menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        color={isAllSuccess ? 'success' : 'error'}
        disabled={isDisabled}
        id="settings-list-button"
        onClick={handleClick}
        tooltipTitle={translate('tooltip.settings_notification')}
      >
        {isAllSuccess ? <NotificationsIcon /> : <NotificationImportantIcon />}
      </SettingsIconButton>

      <MenuStyled
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        elevation={0}
        id="settings-list-menu"
        MenuListProps={{
          'aria-labelledby': 'settings-list-button',
        }}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.entries(systemStatuses).map(([system, isSuccess]) => (
          <MenuItemStyled
            key={system}
            disableRipple
            variant={
              isSuccess ? MenuItemVariant.SUCCESS : MenuItemVariant.WARNING
            }
          >
            {isSuccess ? <SuccessIcon /> : <WarningIcon />}
            {translate(system)}
          </MenuItemStyled>
        ))}
      </MenuStyled>
    </>
  );
}
