import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useIntl } from 'react-intl';
import { Fab } from '@mui/material';
import { SettingsRadarForm } from '../schema';
import { RadarDataField } from '../types';
import {
  ChangeInputEnum,
  changeInputNumberValue,
  changeLatLongValue,
} from '../utils';
import { InputField } from '../../../app/components';
import { FormatTypes } from '../../../app/components/input-field';
import { RadarSettingsWrapper } from './radar-settings-form.styled';

export interface SharedRadarInputNumberProps {
  watch: UseFormWatch<SettingsRadarForm>;
  setValue: UseFormSetValue<SettingsRadarForm>;
  isGps: boolean;
  errors: FieldErrors<SettingsRadarForm>;
  register: UseFormRegister<SettingsRadarForm>;
}

export interface RadarInputNumberProps {
  min: number;
  max: number;
  fieldName: keyof SettingsRadarForm;
  errorOption?: Record<string, number>;
  label: string;
  formatType: FormatTypes;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
}

export default function RadarInputNumber({
  watch,
  setValue,
  isGps,
  errors,
  register,
  fieldName,
  max,
  min,
  errorOption,
  label,
  formatType,
  endAdornment,
  disabled,
}: SharedRadarInputNumberProps & RadarInputNumberProps) {
  const intl = useIntl();
  const setValueOptions = { shouldDirty: true };

  const changeNumberValue = (
    f: keyof SettingsRadarForm,
    minMax: number,
    type: ChangeInputEnum,
  ) => {
    if (f === RadarDataField.LATITUDE || f === RadarDataField.LONGITUDE) {
      const prevValue = watch(f);

      changeLatLongValue(
        prevValue,
        type,
        (newValue: number) => setValue(f, newValue, setValueOptions),
        minMax,
      );
      return;
    }

    const prevValue = watch(f) as number;
    changeInputNumberValue(
      prevValue,
      type,
      (newValue: number) => setValue(f, newValue, setValueOptions),
      minMax,
    );
  };

  const formatErrorMessage = (
    messageId?: string,
    options?: Record<string, number>,
  ) => intl.formatMessage({ id: messageId }, options);

  return (
    <RadarSettingsWrapper>
      <InputField
        disabled={isGps || disabled}
        endAdornment={endAdornment ?? null}
        error={Boolean(errors[fieldName])}
        errorText={
          errors[fieldName]?.message &&
          formatErrorMessage(errors[fieldName]?.message, errorOption)
        }
        formatType={formatType}
        label={intl.formatMessage({
          id: label,
        })}
        {...register(fieldName, {
          valueAsNumber: true,
        })}
      />
      <Fab
        disabled={isGps || disabled}
        onClick={() =>
          changeNumberValue(fieldName, max, ChangeInputEnum.INCREMENT)
        }
      >
        <AddIcon color="primary" />
      </Fab>
      <Fab
        disabled={isGps || disabled}
        onClick={() =>
          changeNumberValue(fieldName, min, ChangeInputEnum.DECREMENT)
        }
      >
        <RemoveIcon color="primary" />
      </Fab>
    </RadarSettingsWrapper>
  );
}
