import { Coords, radianToDegree } from '..';

/**
 * Calculates the angle between two points in a coordinate system.
 *
 * @param currentPoint - The current point in the coordinate system, represented by an object with `x` and `y` properties.
 * @param previousPoint - The previous point in the coordinate system, represented by an object with `x` and `y` properties.
 * @returns The angle between the two points in degrees.
 * @throws {Error} If either `currentPoint` or `previousPoint` is null or undefined.
 */
export default function getDirectionAngle(
  currentPoint: Coords,
  previousPoint: Coords,
) {
  if (currentPoint == null || previousPoint == null) {
    throw new Error(
      'Invalid input: currentPoint and previousPoint must not be null or undefined',
    );
  }

  const dx = currentPoint.x - previousPoint.x;
  const dy = currentPoint.y - previousPoint.y;
  const angle = Math.atan2(dy, dx);

  return radianToDegree(angle);
}
